import { ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { Job, Step } from '@badgermoleV2/api';

type JobPreviewProps = {
  job: Job;
};

export const JobPreview = ({ job }: JobPreviewProps) => {
  const [, i18n] = useTranslation();

  return (
    <ListItem key={`JobPreviewItem${job.summary['en'].replaceAll(' ', '')}`} divider>
      <ListItemText
        key={`JobPreviewItemText${job.summary['en'].replaceAll(' ', '')}`}
        primary={job.summary[i18n.language as keyof Step['stepDescription']]}
        data-testid={`jobPreview${job.summary['en'].replaceAll(' ', '')}`}
      />
    </ListItem>
  );
};
