import { Button, Box, Checkbox, Divider, ListItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TaskActionCheckboxProps = {
  readonly task: string;
  readonly checkedStatus: boolean;
  readonly failedStatus: boolean;
  readonly doneAction: (checked: boolean, failed: boolean) => void;
};

export const TaskActionCheckbox = ({
  task,
  checkedStatus,
  failedStatus,
  doneAction
}: TaskActionCheckboxProps) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(checkedStatus);
  const [failed, setFailed] = useState(failedStatus);

  // change checked when state changes from the outside, e.g. via an all done button
  useEffect(() => {
    setChecked(checkedStatus);
    setFailed(failedStatus);
  }, [checkedStatus, failedStatus]);

  return (
    <>
      <ListItem
        component={Box}
        key={`DriveBackInfromationCard${task}`}
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '.5rem',
          textTransform: 'none',
          color: 'black'
        }}
        onClick={() => {
          setChecked(!checked);
          doneAction(!checked, false);
        }}
      >
        <Box>
          {failed ? (
            <Typography sx={{ textDecoration: 'line-through' }}>
              {t(`service-tour:driveBackInformation.${task}`)}
            </Typography>
          ) : (
            <Typography>{t(`service-tour:driveBackInformation.${task}`)}</Typography>
          )}

          <Button
            sx={{ padding: 0, margin: 0 }}
            size={'small'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              doneAction(false, true);
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {t(`service-tour:driveBackInformation.notPossible`)}
          </Button>
        </Box>

        <Checkbox checked={checked} color={'secondary'} />
      </ListItem>
      <Divider />
    </>
  );
};
