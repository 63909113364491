import { MUTATION_KEYS } from '@/config/MutationKeys';
import { QUERY_KEYS } from '@/config/QueryKeys';
import { useUserProfile } from '@/hooks/Authentication/useUserProfile';
import { useMissions } from '@/hooks/Mission/useMissions';
import { useTourInteraction } from '@/hooks/Tour/General/useTourInteraction';
import { useOpenTour } from '@/hooks/Tour/useOpenTour';
import { useUserLocation } from '@/hooks/Utility/useUserLocation';
import { MapUtils } from '@/utils/MapUtils';
import type {
  CompiledTour,
  ServiceOverview,
  ServiceVehiclesFilterSet,
  TourParametersBody,
  Vehicle
} from '@badgermoleV2/api';
import { AssignmentType, MissionType, StepState } from '@badgermoleV2/api';
import { fetchVehiclesByMissionType } from '@badgermoleV2/missions';
import { modifyTour } from '@badgermoleV2/tours';
import { disableVehicleAlarm, enableVehicleAlarm } from '@badgermoleV2/vehicles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

export function useRelocateTour() {
  const { openTour: relocateTour } = useOpenTour(MissionType.Relocate);
  const { location } = useUserLocation('watch');
  const { user } = useUserProfile();
  const { relocateMission, isLoadingMissions } = useMissions({
    disableService: true,
    disablePickup: true,
    disableCustom: true,
    disableOpenTours: true
  });
  const { startTour, isStartTourPending, updateCurrentTour } = useTourInteraction();
  const queryClient = useQueryClient();
  const [vehiclesOnTour, setVehiclesOnTour] = useState<Vehicle[]>([]);

  const locationId = relocateMission?.offeredTo.serviceLocation.locationId;

  const activeVehicles =
    (relocateTour?.tour?.jobs ?? [])
      .filter((job) => !job.atSteps![0].isDoneOrFailed)
      .sort((l, r) => (l.jobId! > r.jobId! ? -1 : 1)) ?? [];

  const failedVehicles = useMemo(
    () =>
      relocateTour?.tour?.jobs?.filter((job) => job.atSteps![0].state === StepState.Failed) ?? [],
    [relocateTour]
  );
  const completedVehicles = useMemo(
    () =>
      relocateTour?.tour?.jobs?.filter((job) => job.atSteps![0].state === StepState.Completed) ??
      [],
    [relocateTour]
  );

  const finishedVehicles = useMemo(
    () => [...failedVehicles, ...completedVehicles],
    [failedVehicles, completedVehicles]
  );

  const { data: availableVehicles = [], isFetching: isFetchingRelocateVehicles } = useQuery({
    queryKey: [QUERY_KEYS.relocate.vehicles, locationId],
    queryFn: async ({ signal }) =>
      await fetchVehiclesByMissionType(
        {
          locationId: locationId!,
          missionType: MissionType.Relocate
        } satisfies ServiceVehiclesFilterSet,
        { signal }
      ),
    select: useCallback(
      (data: ServiceOverview) => {
        const alreadyInTour = finishedVehicles
          .map((job) => job.atSteps![0].vehicle)
          .filter((v): v is Vehicle => !!v);
        const vehicles = data.availableVehicles?.filter(
          (v) => !alreadyInTour.some((v2) => v.vehicleId === v2.vehicleId)
        );
        return MapUtils.sortByDistance(vehicles ?? [], location!);
      },
      [finishedVehicles, location]
    ),
    enabled: locationId !== undefined && location !== undefined
  });

  const { mutate: adaptTour, isPending: relocateActionLoading } = useMutation({
    mutationKey: [MUTATION_KEYS.tour.modify],
    mutationFn: async (vehicles: Vehicle[]) => {
      setVehiclesOnTour((prev) => [...prev, ...vehicles]);
      return await modifyTour(relocateMission!.missionId!, relocateTour!.tour.tourId!, {
        removedJobs: [],
        injectedScooterBasedJobs: vehicles.map((vehicle) => {
          return {
            scooter: vehicle,
            jobType: AssignmentType.Relocate,
            positionInCompiledJobList: 0
          };
        }),
        remainingJobs: []
      });
    },
    onSuccess: useCallback(
      (updatedTour: CompiledTour | undefined) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.relocate.vehicles] });
        updateCurrentTour(updatedTour);
      },
      [updateCurrentTour, queryClient]
    )
  });

  const { mutate: disableAlarm } = useMutation({
    mutationKey: ['alarm-off'],
    mutationFn: async () => vehiclesOnTour.forEach((v) => disableVehicleAlarm(v.vin!))
  });

  const { mutate: enableAlarm } = useMutation({
    mutationKey: ['alarm-on'],
    mutationFn: async () => vehiclesOnTour.forEach((v) => enableVehicleAlarm(v.vin!))
  });

  const removeVehicleFromTour = useCallback((vehicle: Vehicle) => {
    setVehiclesOnTour((prev) => prev.filter((v) => v.vehicleId !== vehicle.vehicleId));
  }, []);

  useEffect(() => {
    if (!location || isStartTourPending || relocateTour || !user) return;
    startTour({
      assignees: [user.username],
      serviceVehicleId: 1,
      phoneId: 1,
      startLocation: {
        lat: location.coords.latitude,
        lon: location.coords.longitude
      },
      endHubId: 1,
      capacity: 1,
      vehicleTypeIds: undefined,
      missionType: MissionType.Relocate
    } satisfies TourParametersBody);
  }, [relocateTour, location, startTour, isStartTourPending, user]);

  return {
    relocateTour,
    isLoadingMissions,
    activeVehicles,
    failedVehicles,
    finishedVehicles,
    completedVehicles,
    availableVehicles,
    relocateActionLoading,
    adaptTour,
    isFetchingRelocateVehicles,
    enableAlarm,
    removeVehicleFromTour,
    disableAlarm
  };
}
