import { AbortReason, TourUtils } from '@/utils/TourUtils';
import type { Tour } from '@badgermoleV2/api';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ResolveOption = {
  key: string;
  value: string;
};

type CancelTourDialogProps = {
  readonly tour: Tour;
  readonly open: boolean;
  readonly handleApprove: (_: string) => void;
  readonly handleCancel: () => void;
};

export const CancelTourDialog = ({
  tour,
  handleApprove,
  handleCancel,
  open
}: CancelTourDialogProps) => {
  const [t] = useTranslation();

  const [comment, setComment] = useState('');
  const [selectedReason, setSelectedReason] = useState<AbortReason>(
    TourUtils.getMostCommonAbortReasons(tour.tourType)[0]
  );

  const isDefaultReason = selectedReason !== AbortReason.OTHER;
  const disableOnNoReasonGiven = !isDefaultReason && comment.length === 0;

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleCancel();
        setComment('');
      }}
      onBackdropClick={handleCancel}
    >
      <DialogTitle>{t('pickup-tour:cancelDialog:title')}</DialogTitle>
      <DialogContent>
        <Box sx={{ marginTop: '.5rem' }} />
        <FormControl fullWidth>
          <InputLabel>{t('pickup-tour:reason')}</InputLabel>
          <Select
            data-testid={'cancelReasonSelect'}
            value={selectedReason}
            label="Reason"
            onChange={(e) => {
              setSelectedReason(e.target.value as AbortReason);
            }}
          >
            {TourUtils.getMostCommonAbortReasons(tour.tourType).map((reason) => (
              <MenuItem
                key={`SelectReasonItem ${reason}`}
                value={reason}
                data-testid={`SelectReasonItem${reason}`}
              >
                {t(`pickup-tour:cancelDialog.Reason${reason}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!isDefaultReason && (
          <TextField
            fullWidth
            sx={{ marginTop: '8px' }}
            label={t('pickup-tour:cancelDialog.ReasonTitle')}
            data-testid={'cancelDialogTestField'}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            helperText={t('pickup-tour:cancelDialog.ReasonTitle')}
            required={true}
            multiline={true}
            rows={8}
            variant="outlined"
          />
        )}
        <br />
        <br />
        <Typography variant="h1">{t('pickup-tour:cancelDialog:confirmMessage')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary" data-testid={'cancelDecline'}>
          {t('pickup-tour:cancelDialog:no')}
        </Button>
        <Button
          onClick={() =>
            handleApprove(
              isDefaultReason ? t(`pickup-tour:cancelDialog.Reason${selectedReason}`) : comment
            )
          }
          data-testid={'cancelConfirm'}
          color="primary"
          disabled={disableOnNoReasonGiven}
        >
          {t('pickup-tour:cancelDialog:yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
