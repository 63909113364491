import { ListItem, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '@/utils/i18n';

export const LanguageSelection = () => {
  const { t } = useTranslation();
  return (
    <ListItem>
      <Select
        value={i18n.language}
        variant="standard"
        label="Language"
        data-testid="language"
        onChange={(event) => i18n.changeLanguage(event.target.value as string)}
      >
        <MenuItem data-testid="de" value={'de'} key={'de'}>
          {t('common:language.german')}
        </MenuItem>
        <MenuItem data-testid="en" value={'en'} key={'en'}>
          {t('common:language.english')}
        </MenuItem>
      </Select>
    </ListItem>
  );
};
