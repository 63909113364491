import { ROUTES } from '@/App/routes/routes';
import { DataLoadingDialog } from '@/components/Dialogs/DataLoadingDialog';
import { TourFooter, TourHeader } from '@/components/Tour';
import { CurrentStep } from '@/components/Tour/Step';
import { usePostSteps } from '@/hooks/Tour/General/usePostSteps';
import { useStepInteraction } from '@/hooks/Tour/General/useStepInteraction';
import { useTourInteraction } from '@/hooks/Tour/General/useTourInteraction';
import { useOpenTour } from '@/hooks/Tour/useOpenTour';
import { TourUtils } from '@/utils/TourUtils';
import type { CompiledTour, Step, TransitionActionField } from '@badgermoleV2/api';
import { MissionType } from '@badgermoleV2/api';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export type StepWithSelectedCompleteAction = {
  step: Step;
  selectedCompleteAction?: TransitionActionField;
};

type PostStepProps = {
  compiledTour: CompiledTour;
  step: Step;
  availableTransitions: TransitionActionField[];
  disableButtons: boolean;
  setDisableButtons: React.Dispatch<React.SetStateAction<boolean>>;
};

const PostStep = ({
  compiledTour,
  step,
  availableTransitions,
  disableButtons,
  setDisableButtons
}: PostStepProps) => {
  const { completeStepAsync, failStep, isCompleteStepPending, isFailStepPending } =
    useStepInteraction();
  const navigate = useNavigate();
  const { updateCurrentTourFromResponse } = useTourInteraction();
  const [selectedTransitionActionField, setSelectedTransitionActionField] = useState<
    TransitionActionField | undefined
  >(availableTransitions.at(0));

  return (
    <CurrentStep
      step={step}
      tour={compiledTour.tour}
      completeActionOptions={availableTransitions}
      selectedCompleteAction={selectedTransitionActionField}
      onCompleteActionSelect={setSelectedTransitionActionField}
      doneAction={async () => {
        setDisableButtons(true);
        await completeStepAsync(
          {
            tour: compiledTour,
            step,
            transition: selectedTransitionActionField
          },
          {
            onSuccess: (data) => {
              updateCurrentTourFromResponse(data);
              if (TourUtils.noOpenStepsLeft(data.updatedTour)) {
                navigate(`${ROUTES.PICKUP.ROOT}${ROUTES.TOUR.FEEDBACK}`);
              }
            }
          }
        );
        // TODO: this throws an cannot update component error when last step is completed/failed
        // may need to rework the general routing into the screen component.
        setDisableButtons(false);
      }}
      failedAction={(step, cancelAction, reason) => {
        failStep({ tour: compiledTour, step, cancelAction, failReason: reason });
      }}
      disableAllButtons={disableButtons || isCompleteStepPending || isFailStepPending}
    />
  );
};

export const RunningPickupPostStepsScreen = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const { openTour: compiledTour } = useOpenTour(MissionType.Pickup);
  const { reloadTour } = useTourInteraction();
  const [allButtonsDisabled, setAllButtonsDisabled] = useState(false);

  const {
    postSteps,
    stepsWithCompleteActions,
    startSteps,
    isStartStepsPending,
    isStartStepsSuccess,
    isCompleteActionsLoading
  } = usePostSteps(MissionType.Pickup);

  useEffect(() => {
    if (isStartStepsSuccess) return;
    startSteps();
  }, [startSteps, isStartStepsSuccess]);

  if (compiledTour && TourUtils.noOpenStepsLeft(compiledTour)) {
    navigate(`${ROUTES.PICKUP.ROOT}${ROUTES.TOUR.FEEDBACK}`);
  }

  if (!compiledTour) return <DataLoadingDialog open={true} message={t('common:loading:general')} />;

  const loading = isStartStepsPending || isCompleteActionsLoading;

  return (
    <>
      <Box sx={{ marginBottom: 10 }}>
        <TourHeader tour={compiledTour} isTourRunning />
        {(loading || allButtonsDisabled) && (
          <DataLoadingDialog open={true} message={t('common:loading:general')} />
        )}
        {stepsWithCompleteActions &&
          stepsWithCompleteActions.length > 0 &&
          postSteps.map((step) => (
            <Box key={`PostStep${step.stepId}`} sx={{ marginBottom: 2 }}>
              <PostStep
                availableTransitions={
                  stepsWithCompleteActions.find((s) => s.stepId === step.stepId)
                    ?.availableCompleteActionOptions ?? []
                }
                step={step}
                compiledTour={compiledTour}
                disableButtons={loading || allButtonsDisabled}
                setDisableButtons={setAllButtonsDisabled}
              />
            </Box>
          ))}
      </Box>
      <TourFooter reloadAction={() => reloadTour(MissionType.Pickup)} />
    </>
  );
};
