import { useStore } from '@/store';
import { useNavigate } from 'react-router';
import { ROUTES } from './routes';
import { useQueryClient } from '@tanstack/react-query';
import { clearToken, isLoggedIn } from '@badgermoleV2/client';
import { useEffect } from 'react';

type ProtectedRouteProps = {
  component: React.ReactNode;
};

export const ProtectedRoute = ({ component }: ProtectedRouteProps) => {
  const logout = useStore((s) => s.logout);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    const isUserLoggedIn = isLoggedIn();
    if (!isUserLoggedIn) {
      logout();
      clearToken();
      queryClient.clear();
      navigate(ROUTES.DEFAULT);
    }
  }, [logout, navigate, queryClient]);

  return component;
};
