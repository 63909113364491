import { QUERY_KEYS } from '@/config/QueryKeys';
import { getServiceTaskComments } from '@badgermoleV2/service';
import { useQuery } from '@tanstack/react-query';

export const useTaskComments = (taskId?: number) => {
  const { data = [] } = useQuery({
    queryKey: [QUERY_KEYS.tour.taskComments, taskId],
    queryFn: async ({ signal }) => getServiceTaskComments(taskId!, { signal }),
    enabled: taskId !== undefined && taskId !== null,
    staleTime: 1000 * 60 * 30
  });

  return { taskComments: data };
};
