import { QUERY_KEYS } from '@/config/QueryKeys';
import { useUserLocation } from '@/hooks/Utility/useUserLocation';
import { useStore } from '@/store';
import type { MissionType } from '@badgermoleV2/api';
import { getTourParameterOptions } from '@badgermoleV2/missions';
import { useQuery } from '@tanstack/react-query';

export function useTourParameterOptionsBundle(tourType: MissionType) {
  const OCXXEnabled = useStore((s) => s.OCXXEnabled);
  const { location } = useUserLocation('current');

  const { data: bundle, isFetching: isBundleFetching } = useQuery({
    queryKey: [QUERY_KEYS.tour.parameterOptionsBundle, tourType, location, OCXXEnabled],
    queryFn: async ({ signal }) =>
      await getTourParameterOptions(
        location!.coords.latitude,
        location!.coords.longitude,
        tourType,
        OCXXEnabled,
        { signal }
      ),
    enabled: location !== undefined
  });

  return { bundle, isBundleFetching };
}
