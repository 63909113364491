import type { Step } from '@badgermoleV2/api';
import { Box, Divider } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ReplaceHelmetTaskProps = {
  step: Step;
  onStepComplete: (_: Step, resolution: string | undefined) => void;
};

export const ReplaceHelmetTask = ({ step, onStepComplete }: ReplaceHelmetTaskProps) => {
  const { t } = useTranslation();

  const [resolution, setResolution] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (step.completedAt && step.resolution) {
      setResolution(step.resolution);
    }
  }, [step]);

  const handleResolution = (
    event: React.MouseEvent<HTMLElement>,
    newResolution: string | undefined
  ) => {
    if (!resolution) {
      setResolution(newResolution);
      onStepComplete(step, newResolution);
    } else {
      if (newResolution) {
        if (newResolution !== resolution) {
          setResolution(newResolution);
          onStepComplete(step, newResolution);
        }
      }
    }
  };

  return (
    <Box>
      <Divider sx={{ fontSize: '1rem', fontWeight: 'bold', marginBottom: 1 }}>
        {t('service-tour:replacedHelmets')}
      </Divider>
      <ToggleButtonGroup
        sx={{ width: '100%' }}
        value={resolution}
        exclusive
        onChange={handleResolution}
        aria-label="text alignment"
      >
        <ToggleButton
          sx={{ width: '100%' }}
          value="0"
          aria-label="left aligned"
          color={'success'}
          data-testid={'select0Helmets'}
        >
          0
        </ToggleButton>
        <ToggleButton
          sx={{ width: '100%' }}
          value="1"
          aria-label="centered"
          color={'success'}
          data-testid={'select1Helmets'}
        >
          1
        </ToggleButton>
        <ToggleButton
          sx={{ width: '100%' }}
          value="2"
          aria-label="right aligned"
          color={'success'}
          data-testid={'select2Helmets'}
        >
          2
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
