import type { GpsPosition, Step } from '@badgermoleV2/api';

export class StepUtils {
  static getGpsLocation = (step: Step): GpsPosition | undefined => {
    if (step.gpsPosition) {
      return step.gpsPosition as GpsPosition;
    } else if (step.vehicle) {
      return {
        lat: step.vehicle!.lat,
        lon: step.vehicle!.lon
      } as GpsPosition;
    } else {
      return undefined;
    }
  };
}
