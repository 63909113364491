import type React from 'react';

import {
  Box,
  Chip,
  CircularProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper
} from '@mui/material';

type OverviewSelectionItemProps = {
  readonly title: string;
  readonly description: string;
  readonly onClick: () => void;
  readonly hint?: string;
  readonly dataTestId?: string;
  readonly disabled?: boolean;
  readonly disabledHint?: string;
  readonly loading?: boolean;
  readonly icon?: React.ReactNode;
};

export const OverviewSelectionItem = ({
  title,
  description,
  onClick,
  hint,
  dataTestId,
  disabled,
  disabledHint,
  loading,
  icon
}: OverviewSelectionItemProps) => {
  return (
    <Paper sx={{ width: '100%', marginBottom: '1rem' }}>
      <ListItemButton disabled={disabled || loading} onClick={onClick} data-testid={dataTestId}>
        {<ListItemIcon>{loading ? <CircularProgress size="2rem" /> : icon}</ListItemIcon>}
        <Box>
          <ListItemText primary={title} secondary={description} />
          {hint && (
            <Chip label={hint} color={'primary'} size={'small'} data-testid={dataTestId + 'Hint'} />
          )}
          {disabledHint && <Chip label={disabledHint} size={'small'} />}
        </Box>
      </ListItemButton>
    </Paper>
  );
};
