import { RelocateVehicle } from '@/screens/TourScreens/Relocate/RelocateVehicle';
import type { MarkerState } from '@/utils/IconUtils';
import { IconUtils } from '@/utils/IconUtils';
import type { CompiledTour, Job, Vehicle } from '@badgermoleV2/api';
import type React from 'react';
import { LayerGroup, Marker, Popup } from 'react-leaflet';

type DisplayActiveVehiclesProps = {
  compiledTour: CompiledTour;
  state: MarkerState;
  jobs: Job[];
  children?: React.ReactNode | React.ReactNode[];
  readonly removeVehicleFromTour: (vehicle: Vehicle) => void;
};

export const DisplayActiveVehicles = ({
  jobs,
  state,
  compiledTour,
  removeVehicleFromTour
}: DisplayActiveVehiclesProps) => {
  return (
    <LayerGroup>
      {jobs?.map((job) => {
        const vehicle = job.atSteps![0].vehicle!;
        return (
          <Marker
            key={`RelocatedVehiclesFor${job.jobId}`}
            icon={IconUtils.buildIconForRelocate(state)}
            position={[vehicle.lat, vehicle.lon]}
          >
            <Popup>
              <RelocateVehicle
                compiledTour={compiledTour}
                job={job}
                vehicle={vehicle}
                step={job.atSteps![0]}
                removeVehicleFromTour={removeVehicleFromTour}
              />
            </Popup>
          </Marker>
        );
      })}
    </LayerGroup>
  );
};
