import Compress from 'compress.js';
import type { Base64Image } from '@badgermoleV2/api';
import { VehicleReportBodyIncidentsEnum } from '@badgermoleV2/api';

export type ExtendedBase64Image = {
  identifier: string;
  image: Base64Image;
  sizeInMb: number;
};

export type IncidentType = {
  key: string;
  value: string;
};

export class ReportUtils {
  static incidentTypes = [
    { key: 'BadlyParked', value: 'report-vehicle:incidentType.badlyParked' },
    { key: 'BatteryStolen', value: 'report-vehicle:incidentType.batteryStolen' },
    { key: 'Damage', value: 'report-vehicle:incidentType.damage' },
    { key: 'PersonalItemsFound', value: 'report-vehicle:incidentType.personalItemsFound' },
    { key: 'MissingHelmet', value: 'report-vehicle:incidentType.missingHelmet' },
    { key: 'WorkshopDocumentation', value: 'report-vehicle:incidentType.workshopDocumentation' }
  ] as IncidentType[];

  static incidents: VehicleReportBodyIncidentsEnum[] = [
    VehicleReportBodyIncidentsEnum.BadlyParked,
    VehicleReportBodyIncidentsEnum.BatteryStolen,
    VehicleReportBodyIncidentsEnum.Damage,
    VehicleReportBodyIncidentsEnum.PersonalItemsFound,
    VehicleReportBodyIncidentsEnum.MissingHelmet,
    VehicleReportBodyIncidentsEnum.WorkshopDocumentation
  ];

  static maximumImageSizeMb = 8;

  static addImagesAndCompress = async (
    files: FileList,
    images: ExtendedBase64Image[],
    setImages: (_: ExtendedBase64Image[]) => void,
    loading: (_: boolean) => void,
    errorToManyImages: (_: boolean) => void
  ) => {
    loading(true);
    const maxImageLength = images.length + files.length <= 12;
    if (files && maxImageLength) {
      const fileArray = Array.from(files!) as File[];
      const compress = new Compress();

      const compressedImages = await compress.compress(fileArray, {
        size: 4,
        quality: 0.75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true
      });

      const filesAndCompressedImages = compressedImages.map(function (e, i) {
        return {
          compressResult: e,
          file: fileArray[i]
        };
      });

      const extendedBase64Images = await Promise.all(
        filesAndCompressedImages.map(async function (tuple) {
          const identifier = URL.createObjectURL(tuple.file);
          const imageType = tuple.compressResult.ext.split('/')[1];
          const base64Data = tuple.compressResult.data;

          const image = {
            imageType: imageType,
            base64EncodedImage: base64Data
          } as Base64Image;

          return {
            identifier: identifier,
            image: image,
            sizeInMb: tuple.compressResult.endSizeInMb
          } as ExtendedBase64Image;
        })
      );

      setImages(images.concat(extendedBase64Images));
    } else {
      errorToManyImages(true);
    }
    loading(false);
  };

  static removeImage = (
    url: string,
    images: ExtendedBase64Image[],
    setImages: (_: ExtendedBase64Image[]) => void
  ) => {
    const newImages = images.filter((image) => image.identifier !== url);
    setImages(newImages);
  };
}
