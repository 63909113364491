export const QUERY_KEYS = {
  oidc: {
    getToken: 'get-oidc-token',
    refreshToken: 'refresh-oidc-token',
    userProfile: 'get-user-profile'
  },
  mission: {
    getNextMission: 'get-next-mission'
  },
  tour: {
    parameterOptionsBundle: 'get-tour-parameter-options-bundle',
    estimatedDrivingTime: 'get-estimated-driving-time',
    openTours: 'get-open-tours',
    openTour: 'get-open-tour',
    taskComments: 'get-comments-for-task'
  },
  step: {
    completeActions: 'get-complete-actions'
  },
  onstreet: {
    getTour: 'get-onstreet-tour',
    getTourOverview: 'get-onstreet-tour-overview',
    getTasks: 'get-await-onstreet-tasks'
  },
  relocate: {
    vehicles: 'get-relocate-vehicles'
  },
  locations: {
    getBusinessLocation: 'get-business-locations'
  },
  cache: {
    activeVehicleTypes: 'get-pricing-level-entries'
  },
  vehicle: {
    byLicencePlate: 'get-vehicle-by-licenceplate',
    nearby: 'get-vehicle-nearby',
    latestReports: 'get-latest-reports',
    assetInfo: 'get-asset-info',
    states: 'get-vehicle-states',
    liveData: 'get-vehicle-live-data',
    assetComments: 'get-asset-comments'
  },
  workshop: {
    repairTickets: 'get-repair-tickets',
    repairTicket: 'get-repair-ticket',
    problemList: 'get-problem-list',
    liveData: 'get-vehicle-live-data',
    getWorkshops: 'get-workshops'
  },
  repair: {
    getTask: 'get-repair-task',
    getTasks: 'get-repair-tasks',
    getRepairTransitions: 'get-repair-transitions',
    getSearchModelProblems: 'get-search-model-problems',
    modelProblemCategories: 'model-problem-categories',
    getLogs: 'get-repair-task-logs'
  },
  basic: {
    employees: 'get-employees'
  },
  service: {
    serviceTaskWithInfo: 'serviceTaskWithInfo',
    taskLogs: 'task-logs',
    tasks: 'tasks',
    taskAssetInfos: 'task-asset-infos',
    taskOverview: 'task-overview'
  }
} as const;
