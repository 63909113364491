import { localStoragePersister } from '@/App/App';
import { useStore } from '@/store';
import { LOGOUT_URL, clearToken, getToken } from '@badgermoleV2/client';
import { useEffect } from 'react';

export const LogoutRedirectScreen = () => {
  const logout = useStore((s) => s.logout);
  const token = getToken();

  useEffect(() => {
    const params = new URLSearchParams({
      redirect_uri: import.meta.env.VITE_HOME_URL,
      id_token_hint: token.idToken
    });
    clearToken();
    localStoragePersister.removeClient();
    logout();
    window.location.href = LOGOUT_URL + '?' + params;
  }, [logout, token.idToken]);

  return <div>logging out...</div>;
};
