import { ROUTES } from '@/App/routes/routes';
import { CancelTourDialog } from '@/components/Dialogs/CancelTourDialog';
import { DataLoadingDialog } from '@/components/Dialogs/DataLoadingDialog';
import { TourFooter, TourHeader } from '@/components/Tour';
import { JobPreviewList } from '@/components/Tour/Job/JobPreviewList/JobPreviewList';
import { CurrentStep } from '@/components/Tour/Step';
import { useStepInteraction } from '@/hooks/Tour/General/useStepInteraction';
import { useTourInteraction } from '@/hooks/Tour/General/useTourInteraction';
import { useOpenTour } from '@/hooks/Tour/useOpenTour';
import { useTaskComments } from '@/hooks/Tour/useTaskComments';
import { useTitle } from '@/hooks/Utility/useTitle';
import { useStore } from '@/store';
import { TourUtils } from '@/utils/TourUtils';
import type { Step, TransitionAction } from '@badgermoleV2/api';
import {
  AssignmentState,
  AssignmentType,
  MissionType,
  StepState,
  StepType,
  TourState
} from '@badgermoleV2/api';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const RunningPickupScreen = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const tourExtensionDeclined = useStore((s) => s.tourExtensionDeclined);
  const [currentStep, setCurrentStep] = useState<Step>();
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const { openTour: tour } = useOpenTour(MissionType.Pickup);
  const {
    startNextConsideredStepAsync,
    completeStepAsync,
    failStepAsync,
    reloadStepPosition,
    isStartStepPending,
    isReloadStepPositionPending,
    isFailStepPending,
    isCompleteStepPending
  } = useStepInteraction();
  const { cancelTour, reloadTour, isCancelTourPending, isReloadTourPending } = useTourInteraction();

  const { taskComments } = useTaskComments(
    tour?.tour.jobs?.find((job) =>
      [...job.preSteps!, ...job.atSteps!].some((step) => step.stepId === currentStep?.stepId)
    )?.createdByServiceTask
  );

  useTitle({
    title: 'pickup-tour:title',
    suffix: tour ? '#' + tour.tour.tourId : '',
    separator: ' '
  });

  const openJobs =
    tour?.tour.jobs!.filter(
      (j) => j.state === AssignmentState.Considered && j.jobType !== AssignmentType.DriveBack
    ) ?? [];

  const completeStepAndStartNext = async (step: Step) => {
    if (!tour) return;
    const completeResponse = await completeStepAsync({ step, tour });
    if (completeResponse.success) await startNextConsideredStepAsync(completeResponse.updatedTour);
  };

  const failStepAndStartNext = async (
    step: Step,
    cancelAction: TransitionAction,
    reason: string
  ) => {
    if (!tour) return;
    const failResponse = await failStepAsync({ tour, step, cancelAction, failReason: reason });
    if (failResponse.success) await startNextConsideredStepAsync(failResponse.updatedTour);
  };

  useEffect(() => {
    // if no tour is available return to prepare tour screen
    if (!tour) {
      navigate(ROUTES.PICKUP.ROOT + ROUTES.TOUR.PREPARE);
      return;
    }

    // If tour has already been completed, redirect to overview
    if (tour && tour.tour.state !== TourState.InProgress) {
      window.scrollTo(0, 0);
      navigate(ROUTES.OVERVIEW);
      return;
    }

    const stepInProgress = (tour.compiledSteps ?? []).find(
      (step) => step.state === StepState.InProgress || step.state === StepState.Considered
    );

    setCurrentStep(stepInProgress);
    if (!stepInProgress) {
      if (!tourExtensionDeclined) {
        window.scrollTo(0, 0);
        navigate(`${ROUTES.PICKUP.ROOT}${ROUTES.TOUR.EXTEND}`);
      } else {
        window.scrollTo(0, 0);
        navigate(`${ROUTES.PICKUP.ROOT}${ROUTES.TOUR.FEEDBACK}`);
      }
    }

    if (stepInProgress) {
      // Before starting the DriveBackJob, ask user if tour should be extended
      const currentJob = TourUtils.getJobForStep(tour!.tour, stepInProgress!);
      if (currentJob?.jobType === AssignmentType.DriveBack && !tourExtensionDeclined) {
        window.scrollTo(0, 0);
        navigate(`${ROUTES.PICKUP.ROOT}${ROUTES.TOUR.EXTEND}`);
        return;
      }

      // If only post-steps are left, they are handled by RunningPickupPostStepsScreen
      if (stepInProgress!.stepType === StepType.PostStep) {
        window.scrollTo(0, 0);
        navigate(`${ROUTES.PICKUP.ROOT}${ROUTES.TOUR.POST_STEPS}`);
        return;
      }
    }
  }, [navigate, tour, tourExtensionDeclined]);

  const loading =
    isStartStepPending ||
    isReloadStepPositionPending ||
    isFailStepPending ||
    isCompleteStepPending ||
    isCancelTourPending;

  if (!tour || !currentStep)
    return <DataLoadingDialog open={true} message={t('common:loading:general')} />;

  return (
    <Box sx={{ marginBottom: 10 }}>
      {(loading || isReloadTourPending) && (
        <DataLoadingDialog open={true} message={t('common:loading:general')} />
      )}
      <Box>
        <CancelTourDialog
          open={cancelDialogOpen}
          tour={tour.tour}
          handleApprove={(reason) => {
            setCancelDialogOpen(false);
            cancelTour({ compiledTour: tour, reason });
          }}
          handleCancel={() => {
            setCancelDialogOpen(false);
          }}
        />

        <TourHeader
          tour={tour}
          isTourRunning
          cancelAction={() => {
            setCancelDialogOpen(true);
          }}
        />
        <CurrentStep
          step={currentStep}
          tour={tour.tour}
          doneAction={completeStepAndStartNext}
          failedAction={failStepAndStartNext}
          refreshAction={(step) => {
            reloadStepPosition({ tour, step });
          }}
          disableAllButtons={loading}
          comments={taskComments}
        />
        <JobPreviewList jobs={openJobs} />
      </Box>
      <TourFooter
        reloadAction={() => {
          reloadTour(MissionType.Pickup);
        }}
      />
    </Box>
  );
};
