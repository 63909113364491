import type { Vehicle } from '@badgermoleV2/api';
import { Box, List, ListItemButton, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

type VehicleSelectionProps = {
  readonly vehicleList: Vehicle[];
  readonly setVehicle: React.Dispatch<React.SetStateAction<Vehicle | undefined>>;
};

export const VehicleSelection = ({ vehicleList, setVehicle }: VehicleSelectionProps) => {
  const { t } = useTranslation();

  if (vehicleList.length === 0)
    return <Box data-testid="searchResultList">{t('report-vehicle:search.noneFound')}</Box>;

  if (vehicleList.length > 100)
    return <Box data-testid="searchResultList">{t('report-vehicle:search.specifySearch')}</Box>;

  return (
    <List data-testid="searchResultList">
      {vehicleList.map((vehicle) => (
        <ListItemButton divider key={vehicle.vehicleId} onClick={() => setVehicle(vehicle)}>
          <ListItemText primary={vehicle.licencePlate} />
        </ListItemButton>
      ))}
    </List>
  );
};
