import { TourUtils, type DriveBackInformationStatus } from '@/utils/TourUtils';
import type { LatLngExpression } from 'leaflet';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type AppStateProps = {
  readonly title: string;
  readonly setTitle: (_: string) => void;
  readonly cityPolygons: LatLngExpression[][];
  readonly cityNoParkingAreas: LatLngExpression[][];
  readonly setCityPolygons: (_: LatLngExpression[][]) => void;
  readonly setCityNoParkingAreas: (_: LatLngExpression[][]) => void;

  readonly ongoingTourId: number | null;
  readonly setOngoingTourId: (_: number) => void;

  readonly ongoingOnStreetTourId: number | null;
  readonly setOngoingOnStreetTourId: (_: number | null) => void;

  // Tour
  readonly logout: () => void;
  readonly tourExtensionDeclined: boolean;
  readonly setTourExtensionDeclined: (_: boolean) => void;
  readonly PROXIMITY_CHECK_ENABLED: boolean;
  readonly PROXIMITY_THRESHOLD: number;
  readonly driveBackInformationStatusList: DriveBackInformationStatus[];
  readonly setDriveBackInformationStatus: (_: DriveBackInformationStatus) => void;
  readonly setDriveBackInformationStatusList: (_: DriveBackInformationStatus[]) => void;

  readonly relocateMapViewEnabled: boolean;
  readonly setRelocateMapViewEnabled: (_: boolean) => void;

  // For testing in Jira OCXX in production, only enabled when user is part of some ocxx_ group
  readonly OCXXSwitchSettingAllowed: boolean;
  readonly OCXXSwitchAllowedByUserGroup: boolean;
  readonly OCXXEnabled: boolean;
  readonly VEHICLE_REPORT_SEARCH_DELAY: number;
  readonly setOCXXSwitchAllowedByUserGroup: (_: boolean) => void;
  readonly setOCXXEnabled: (_: boolean) => void;

  readonly completedStepsForJob: string[]; // Checkbox list in Swap/Custom Tour
  readonly setCompletedStepsForJob: (_: string[]) => void;

  // OIDC AUTHd
  readonly oidcCode: string | null;
  readonly setOidcCode: (_: string) => void;
};

const initialState = {
  title: 'emmy Service',
  cityPolygons: [] as LatLngExpression[][],
  cityNoParkingAreas: [] as LatLngExpression[][],
  tourExtensionDeclined: false,
  relocateMapViewEnabled: false,
  PROXIMITY_CHECK_ENABLED: import.meta.env.VITE_PROXIMITY_CHECK_ENABLED! === 'true',
  PROXIMITY_THRESHOLD: +import.meta.env.VITE_PROXIMITY_THRESHOLD!,
  driveBackInformationStatusList: TourUtils.driveBackInformation,
  ongoingTourId: null,
  ongoingOnStreetTourId: null,

  // For testing in Jira OCXX in production, only enabled when user is part of some ocxx_ group
  OCXXSwitchSettingAllowed: import.meta.env.VITE_OCXX_OPTION_ALLOWED! === 'true',
  OCXXSwitchAllowedByUserGroup: false,
  OCXXEnabled: false,
  VEHICLE_REPORT_SEARCH_DELAY: +import.meta.env.VITE_VEHICLE_REPORT_SEARCH_DELAY!,
  completedStepsForJob: [],
  oidcCode: null
};

/* The `persist` function is a middleware that allows to persist the state of the store. */
export const useStore = create<AppStateProps>()(
  persist(
    //get is necessary to retrieve the values, but eslint complains that it is unused
    // eslint-disable-next-line
    (set, get) => ({
      ...initialState,
      setCityPolygons: (cityPolygons) => set({ cityPolygons }),
      setCityNoParkingAreas: (cityNoParkingAreas) => set({ cityNoParkingAreas }),
      setTitle: (title) => set({ title }),
      setDriveBackInformationStatus: (driveBackInfromationStatus: DriveBackInformationStatus) =>
        set((state) => ({
          driveBackInformationStatusList: state.driveBackInformationStatusList.map((info) =>
            info.id === driveBackInfromationStatus.id ? driveBackInfromationStatus : info
          )
        })),
      setOngoingOnStreetTourId: (ongoingOnStreetTourId) => set({ ongoingOnStreetTourId }),
      setOngoingTourId: (ongoingTourId) => set({ ongoingTourId }),
      setDriveBackInformationStatusList: (driveBackInformationStatusList) =>
        set({ driveBackInformationStatusList }),
      logout: () => set({ ...initialState }),
      setTourExtensionDeclined: (declined) => set({ tourExtensionDeclined: declined }),
      setOCXXSwitchAllowedByUserGroup: (allowed) => set({ OCXXSwitchAllowedByUserGroup: allowed }),
      setOCXXEnabled: (enabled) => set({ OCXXEnabled: enabled }),
      setCompletedStepsForJob: (steps: string[]) => set({ completedStepsForJob: steps }),
      setRelocateMapViewEnabled: (relocateMapViewEnabled: boolean) =>
        set({ relocateMapViewEnabled }),
      setOidcCode: (oidcCode) => set({ oidcCode })
    }),

    {
      name: 'app-store'
    }
  )
);
