import { Box } from '@mui/material';
import { useState } from 'react';

type ShortenedChipProps = {
  readonly label: string;
  readonly size?: 'small' | 'medium';
  readonly color?: string;
};

export const ShortenedChip = ({ label, color }: ShortenedChipProps) => {
  const [shortenedLabel] = useState(() =>
    label.length > 12 ? label.substring(0, 12) + '...' : label
  );
  const [toggle, setToggle] = useState(true);
  return (
    <Box
      onClick={() => setToggle((prev) => !prev)}
      sx={{
        fontSize: '14px',
        padding: '.4em',
        borderRadius: '.8em',
        overflowWrap: 'break-word',
        backgroundColor: color,
        whiteSpace: 'break-spaces'
      }}
    >
      {toggle ? shortenedLabel : label}
    </Box>
  );
};
