import { ROUTES } from '@/App/routes/routes';
import { useOidcAuthorization } from '@/hooks/Authentication/token/useOidcAuthorization';
import { useTitle } from '@/hooks/Utility/useTitle';
import { LOGIN_URL, clearToken, isLoggedIn } from '@badgermoleV2/client';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const LoginScreen = () => {
  useTitle({ title: 'emmy Service' });
  const { createOidcCode } = useOidcAuthorization();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoggedIn()) clearToken();
  }, []);

  return (
    <Box sx={{ marginTop: '5rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2>{t('common:auth.heading')}</h2>
      <Box
        sx={{
          margin: 5,
          padding: 5,
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          border: '1px solid grey'
        }}
      >
        <Button
          variant="contained"
          sx={{ maxWidth: '11rem' }}
          onClick={() => {
            const code = createOidcCode();
            const params = new URLSearchParams({
              redirect_url: import.meta.env.VITE_HOME_URL + ROUTES.LOGIN_RETURN,
              state: '',
              nonce: code
            });
            window.location.href = LOGIN_URL + '?' + params;
          }}
        >
          {t('common:auth.keycloak.login')}
        </Button>
        <Button
          variant="contained"
          sx={{ maxWidth: '11rem' }}
          disabled={!isLoggedIn()}
          onClick={() => {
            navigate(ROUTES.LOGOUT_REDIRECT);
          }}
        >
          {t('common:auth.keycloak.logout')}
        </Button>
      </Box>
    </Box>
  );
};
