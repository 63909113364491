import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JobPreview } from '@/components/Tour/Job/JobPreviewList/JobPreview';
import { Button, Box } from '@mui/material';
import type { Job } from '@badgermoleV2/api';

type JobPreviewListProps = {
  jobs: Job[];
};

export const JobPreviewList = ({ jobs }: JobPreviewListProps) => {
  const [t] = useTranslation();
  const [showNextJobs, setShowNextJobs] = useState(false);
  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Button
        data-testid={'jobsLeftInTour'}
        color={'info'}
        variant={'text'}
        onClick={() => {
          setShowNextJobs(!showNextJobs);
        }}
      >
        {t('pickup-tour:jobsLeftInTour')} ({jobs.length})
      </Button>
      {showNextJobs &&
        jobs.map((job) => (
          <JobPreview key={`JobPreview${job.summary['en'].replaceAll(' ', '')}`} job={job} />
        ))}
    </Box>
  );
};
