import type { Vehicle, VehicleTypeEntry } from '@badgermoleV2/api';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type VehicleListProps = {
  readonly vehicles: Vehicle[];
  readonly turnedOffVehicles: string[];
  readonly vehicleTypes: VehicleTypeEntry[];
  readonly setTurnedOffVehicles: React.Dispatch<React.SetStateAction<string[]>>;
  readonly onEnable: (_: string) => Promise<void>;
  readonly onDisable: (_: string) => Promise<void>;
};

export const VehicleList = ({
  vehicles,
  vehicleTypes,
  turnedOffVehicles,
  setTurnedOffVehicles,
  onEnable,
  onDisable
}: VehicleListProps) => {
  const [loading, setLoading] = useState(false);
  const [lastClicked, setLastClicked] = useState('');
  const [buttonTypeClicked, setButtonTypeClicked] = useState<'off' | 'on'>('off');
  const [t] = useTranslation();

  const onAlarmOn = (vin: string) => {
    if (loading) return;
    setLoading(true);
    setLastClicked(vin);
    setButtonTypeClicked('on');
    onEnable(vin)
      .then(() => {
        setTurnedOffVehicles((prev) => prev.filter((v) => v !== vin));
      })
      .finally(() => {
        setLastClicked('');
        setLoading(false);
      });
  };

  const onAlarmOff = (vin: string) => {
    if (loading) return;
    setButtonTypeClicked('off');
    setLoading(true);
    setLastClicked(vin);
    onDisable(vin)
      .then(() => {
        if (!turnedOffVehicles.includes(vin)) {
          setTurnedOffVehicles((prev) => [...prev, vin]);
        }
      })
      .finally(() => {
        setLastClicked('');
        setLoading(false);
      });
  };

  return (
    <List>
      {vehicles
        .filter((vehicle) => vehicle.vin)
        .map((vehicle) => (
          <ListItem
            data-testid="scooterListed"
            sx={{ display: 'flex' }}
            divider={true}
            key={vehicle.vehicleId}
          >
            <ListItemText
              primary={vehicle.licencePlate}
              secondary={
                vehicleTypes.find((vt) => vt.vehicleTypeId === vehicle.vehicleTypeId)?.model
              }
            />
            {turnedOffVehicles.includes(vehicle.vin!) && (
              <ListItemIcon data-testid="listIcon">
                <VolumeOffIcon data-testid="volumeOffIcon" />
              </ListItemIcon>
            )}
            <Box position="relative">
              <Button
                sx={{ fontSize: '.75rem' }}
                id={vehicle.vehicleId.toString() + '-off'}
                variant="contained"
                disabled={loading && vehicle.vin! === lastClicked}
                onClick={() => onAlarmOff(vehicle.vin!)}
              >
                {t('common:buttons.off')}
              </Button>
              {loading && buttonTypeClicked === 'off' && vehicle.vin! === lastClicked && (
                <CircularProgress size={24} />
              )}
            </Box>
            <Box position="relative">
              <Button
                sx={{ fontSize: '.75rem' }}
                id={vehicle.vehicleId.toString() + '-on'}
                variant="contained"
                color="primary"
                disabled={loading && vehicle.vin! === lastClicked}
                onClick={() => onAlarmOn(vehicle.vin!)}
              >
                {t('common:buttons.on')}
              </Button>
              {loading && buttonTypeClicked === 'on' && vehicle.vin! === lastClicked && (
                <CircularProgress size={24} />
              )}
            </Box>
          </ListItem>
        ))}
    </List>
  );
};
