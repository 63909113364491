import { useUserLocation } from '@/hooks/Utility/useUserLocation';
import { useTranslation } from 'react-i18next';
import { CircleMarker, LayerGroup, Popup } from 'react-leaflet';

export const DisplayUserPosition = () => {
  const { t } = useTranslation();
  const { location } = useUserLocation('watch');

  return (
    <LayerGroup>
      {location && (
        <CircleMarker
          center={[location.coords.latitude, location.coords.longitude]}
          color={'#2062b1'}
          fillColor={'#2062b1'}
          fillOpacity={1}
          radius={6}
        >
          <Popup>{t('common:geolocation.currentLocation')}</Popup>
        </CircleMarker>
      )}
    </LayerGroup>
  );
};
