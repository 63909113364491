import { QUERY_KEYS } from '@/config/QueryKeys';
import { useUserLocation } from '@/hooks/Utility/useUserLocation';
import { useStore } from '@/store';
import { MissionType, type CompiledTour } from '@badgermoleV2/api';
import { getAllOpenTours, getMissionWithHighestPriorty } from '@badgermoleV2/missions';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

type UseMissionOptions = {
  isOnlyExternal?: boolean;
  isDrawerOpen?: boolean;
  disablePickup?: boolean;
  disableService?: boolean;
  disableCustom?: boolean;
  disableRelocate?: boolean;
  disableOpenTours?: boolean;
};

export function useMissions({
  isOnlyExternal = false,
  isDrawerOpen = true,
  disablePickup = false,
  disableService = false,
  disableCustom = false,
  disableRelocate = false,
  disableOpenTours = false
}: UseMissionOptions) {
  const queryClient = useQueryClient();

  const { location } = useUserLocation('current');
  const userLat = location?.coords.latitude;
  const userLon = location?.coords.longitude;
  const staleTime = 1000 * 60 * 5;

  const OCXXEnabled = useStore((s) => s.OCXXEnabled);
  const enabled = userLat !== undefined && userLon !== undefined && !isOnlyExternal && isDrawerOpen;

  const getNextMissionByType = async (
    missionType: MissionType,
    lat: number,
    lon: number,
    signal: AbortSignal
  ) => {
    return await getMissionWithHighestPriorty(lat, lon, missionType, OCXXEnabled, { signal });
  };

  const { data: pickupMission, isFetching: isFetchingPickup } = useQuery({
    queryKey: [QUERY_KEYS.mission.getNextMission, MissionType.Pickup, userLat, userLon],
    queryFn: async ({ signal }) =>
      getNextMissionByType(MissionType.Pickup, userLat!, userLon!, signal),
    staleTime,
    enabled: enabled && !disablePickup
  });

  const { data: serviceMission, isFetching: isFetchingService } = useQuery({
    queryKey: [QUERY_KEYS.mission.getNextMission, MissionType.Swap, userLat, userLon],
    queryFn: async ({ signal }) =>
      getNextMissionByType(MissionType.Swap, userLat!, userLon!, signal),
    staleTime,
    enabled: enabled && !disableService
  });

  const { data: customMission, isFetching: isFetchingCustom } = useQuery({
    queryKey: [QUERY_KEYS.mission.getNextMission, MissionType.Custom, userLat, userLon],
    queryFn: async ({ signal }) =>
      getNextMissionByType(MissionType.Custom, userLat!, userLon!, signal),
    staleTime,
    enabled: enabled && !disableCustom
  });

  const { data: relocateMission, isFetching: isFetchingRelocate } = useQuery({
    queryKey: [QUERY_KEYS.mission.getNextMission, MissionType.Relocate, userLat, userLon],
    queryFn: async ({ signal }) =>
      getNextMissionByType(MissionType.Relocate, userLat!, userLon!, signal),
    staleTime,
    enabled: enabled && !disableRelocate
  });

  const { data: openTours = [], isFetching: isFetchingOpenTours } = useQuery({
    queryKey: [QUERY_KEYS.tour.openTours],
    queryFn: async ({ signal }) => getAllOpenTours({ signal }),
    select: useCallback(
      (data: CompiledTour[]) => {
        queryClient.setQueryData(
          [QUERY_KEYS.tour.openTour, MissionType.Pickup],
          data.find((tour) => tour.tour.tourType === MissionType.Pickup)
        );
        queryClient.setQueryData(
          [QUERY_KEYS.tour.openTour, MissionType.Swap],
          data.find((tour) => tour.tour.tourType === MissionType.Swap)
        );
        queryClient.setQueryData(
          [QUERY_KEYS.tour.openTour, MissionType.Custom],
          data.find((tour) => tour.tour.tourType === MissionType.Custom)
        );
        queryClient.setQueryData(
          [QUERY_KEYS.tour.openTour, MissionType.Relocate],
          data.find((tour) => tour.tour.tourType === MissionType.Relocate)
        );
        return data;
      },
      [queryClient]
    ),
    enabled: !isOnlyExternal && !disableOpenTours && isDrawerOpen
  });

  return {
    openTours,
    pickupMission,
    serviceMission,
    customMission,
    relocateMission,
    isLoadingOpenTours: isFetchingOpenTours,
    serviceMissionLoading: isFetchingService,
    pickupMissionLoading: isFetchingPickup,
    customMissionLoading: isFetchingCustom,
    relocateMissionLoading: isFetchingRelocate,
    isLoadingMissions:
      !isOnlyExternal &&
      (isFetchingService || isFetchingPickup || isFetchingCustom || isFetchingRelocate || !location)
  };
}
