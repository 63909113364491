import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

export function useUserLocation(mode: 'current' | 'watch') {
  const [permission, setPermission] = useState(true);
  const [location, setLocation] = useState<GeolocationPosition>();
  const [unpreciseLocation, setUnpreciseLocation] = useState<{ lat: number; lon: number }>();
  const [error, setError] = useState<GeolocationPositionError>();

  const updateCurrentPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        setPermission(true);
        setLocation(location);
        setUnpreciseLocation({
          lat: +location.coords.latitude.toFixed(4),
          lon: +location.coords.longitude.toFixed(4)
        });
      },
      (e: GeolocationPositionError) => {
        setPermission(false);
        toast.error(e.message);
      },
      {
        maximumAge: 0,
        enableHighAccuracy: true
      }
    );
  }, []);

  useEffect(() => {
    if (mode !== 'watch') return;
    const watchId = navigator.geolocation.watchPosition(
      (location) => {
        setLocation(location);
        setPermission(true);
        setUnpreciseLocation({
          lat: +location.coords.latitude.toFixed(4),
          lon: +location.coords.longitude.toFixed(4)
        });
      },
      (e: GeolocationPositionError) => {
        toast.error(e.message);
        setPermission(false);
        setError(e);
      },
      {
        maximumAge: 0,
        enableHighAccuracy: true
      }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, [mode]);

  useEffect(() => {
    if (mode !== 'current') return;
    updateCurrentPosition();
  }, [mode, updateCurrentPosition]);

  return {
    location,
    locationError: error,
    locationPermission: permission,
    updateCurrentPosition,
    unpreciseLocation
  };
}
