import type { Locale } from 'date-fns/locale';
import { de } from 'date-fns/locale/de';
import { enUS } from 'date-fns/locale/en-US';

export class LocalizationUtils {
  static dateTimeLocale = new Map<string, Locale>([
    ['de', de],
    ['en', enUS]
  ]);
}
