import { useVehicleTypes } from '@/hooks/Cache/useVehicleTypes';
import { useTitle } from '@/hooks/Utility/useTitle';
import { VehicleList } from '@/screens/UmparkenScreen/VehicleList';
import { Refresh, Search } from '@mui/icons-material';
import { Alert, Box, CircularProgress, Fab, IconButton, Snackbar, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUmparken } from 'src/hooks/useUmparken';

export const UmparkenScreen = () => {
  useTitle({ title: 'umparken:title' });
  const {
    vehicles,
    isFetching,
    enableAlarm,
    disableAlarm,
    locationPermission,
    updateCurrentPosition
  } = useUmparken();
  const [search, setSearch] = useState('');
  const [turnedOffVehicles, setTurnedOffVehicles] = useState<string[]>([]);
  const [t] = useTranslation();

  const { vehicleTypes } = useVehicleTypes();

  const filteredVehicles = vehicles.filter((vehicle) =>
    vehicle.licencePlate.toString().toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box sx={{ width: '100%', height: '100vh' }}>
      <TextField
        fullWidth
        label={t('umparken:vehicleList.search')}
        placeholder={t('umparken:vehicleList.search')}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <IconButton type="submit">
              <Search />
            </IconButton>
          )
        }}
        sx={{ marginTop: '1rem' }}
      />

      {!isFetching ? (
        <VehicleList
          vehicles={filteredVehicles}
          vehicleTypes={vehicleTypes}
          turnedOffVehicles={turnedOffVehicles}
          setTurnedOffVehicles={setTurnedOffVehicles}
          onEnable={async (vin) => enableAlarm(vin)}
          onDisable={async (vin) => disableAlarm(vin)}
        />
      ) : (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}
        >
          <CircularProgress />
        </Box>
      )}
      <Snackbar open={!locationPermission} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="info">{t('umparken:geolocation.permission')}</Alert>
      </Snackbar>
      <Fab
        sx={{ position: 'fixed', right: '1rem', bottom: '1rem' }}
        color="secondary"
        onClick={() => updateCurrentPosition()}
      >
        <Refresh />
      </Fab>
    </Box>
  );
};
