/* tslint:disable */
/* eslint-disable */
/**
 * Badgermole (staging)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 8caff256
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// Some imports not used depending on template conditions
// @ts-ignore
// @ts-ignore

/**
 * @type Action
 * @export
 */
export type Action = object;

/**
 *
 * @export
 * @interface ActionNotAllowed
 */
export interface ActionNotAllowed {
  /**
   *
   * @type {string}
   * @memberof ActionNotAllowed
   */
  message: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ActionResult = {
  Success: 'Success',
  Error: 'Error',
  NothingToDo: 'NothingToDo'
} as const;

export type ActionResult = (typeof ActionResult)[keyof typeof ActionResult];

/**
 *
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  vin: string;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof Asset
   */
  businessLocation: BusinessLocation1;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof Asset
   */
  isDeleted: boolean;
  /**
   * @type {Date}
   * @memberof Asset
   */
  acquisitionDate: Date;
  /**
   * @type {Date}
   * @memberof Asset
   */
  deliveryDate?: Date;
  /**
   * @type {Date}
   * @memberof Asset
   */
  warrantyEndDate?: Date;
}
/**
 *
 * @export
 * @interface InputAsset
 */
export interface InputAsset {
  /**
   *
   * @type {string}
   * @memberof InputAsset
   */
  vin: string;
  /**
   *
   * @type {number}
   * @memberof InputAsset
   */
  assetTypeId: number;
  /**
   *
   * @type {number}
   * @memberof InputAsset
   */
  businessLocationId: number;
  /**
   *
   * @type {boolean}
   * @memberof InputAsset
   */
  isDeleted: boolean;
  /**
   * @type {Date}
   * @memberof InputAsset
   */
  acquisitionDate: Date;
  /**
   * @type {Date}
   * @memberof InputAsset
   */
  deliveryDate?: Date;
  /**
   * @type {Date}
   * @memberof InputAsset
   */
  warrantyEndDate?: Date;
}

/**
 *
 * @export
 * @interface AssetFilters
 */
export interface AssetFilters {
  /**
   *
   * @type {number}
   * @memberof AssetFilters
   */
  businessLocationId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof AssetFilters
   */
  assetStateIds?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof AssetFilters
   */
  ignoreAssetsWithOpenTasks?: boolean;
  /**
   *
   * @type {string}
   * @memberof AssetFilters
   */
  licencePlate?: string;
}
/**
 *
 * @export
 * @interface AssetInfo
 */
export interface AssetInfo {
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  assetStateId?: number;
  /**
   *
   * @type {string}
   * @memberof AssetInfo
   */
  licencePlate?: string;
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  wunderfleetVehicleId?: number;
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  wunderfleetVehicleTypeId?: number;
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  wunderfleetVehicleStateId?: number;
  /**
   *
   * @type {boolean}
   * @memberof AssetInfo
   */
  isReserved?: boolean;
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  mileage?: number;
  /**
   *
   * @type {string}
   * @memberof AssetInfo
   */
  iotId?: string;
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  batteryPercent?: number;
  /**
   *
   * @type {GpsPosition}
   * @memberof AssetInfo
   */
  location?: GpsPosition;
  /**
   *
   * @type {Asset}
   * @memberof AssetInfo
   */
  asset: Asset;
  /**
   *
   * @type {Array<WunderVehicleTag>}
   * @memberof AssetInfo
   */
  tags?: Array<WunderVehicleTag>;
  /**
   *
   * @type {Array<Comment>}
   * @memberof AssetInfo
   */
  comments?: Array<Comment>;
}
/**
 *
 * @export
 * @interface AssetType
 */
export interface AssetType {
  /**
   *
   * @type {number}
   * @memberof AssetType
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  pricingLevel: AssetTypePricingLevelEnum;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof AssetType
   */
  isActive: boolean;
}

export const AssetTypePricingLevelEnum = {
  Default: 'default',
  Discounted: 'discounted',
  Overpriced: 'overpriced',
  Special: 'special',
  Unknown: 'unknown'
} as const;

export type AssetTypePricingLevelEnum =
  (typeof AssetTypePricingLevelEnum)[keyof typeof AssetTypePricingLevelEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const AssignmentState = {
  Unassigned: 'Unassigned',
  Considered: 'Considered',
  InProgress: 'InProgress',
  PreStepsCompleted: 'PreStepsCompleted',
  AtStepsCompleted: 'AtStepsCompleted',
  PostStepsCompleted: 'PostStepsCompleted',
  Completed: 'Completed',
  Failed: 'Failed',
  Canceled: 'Canceled',
  Skipped: 'Skipped'
} as const;

export type AssignmentState = (typeof AssignmentState)[keyof typeof AssignmentState];

/**
 *
 * @export
 * @enum {string}
 */

export const AssignmentType = {
  Pickup: 'Pickup',
  Swap: 'Swap',
  Custom: 'Custom',
  DriveBack: 'DriveBack',
  Relocate: 'Relocate'
} as const;

export type AssignmentType = (typeof AssignmentType)[keyof typeof AssignmentType];

/**
 *
 * @export
 * @interface AvailableProblem
 */
export interface AvailableProblem {
  /**
   *
   * @type {string}
   * @memberof AvailableProblem
   */
  name: string;
}
/**
 *
 * @export
 * @interface Base64Image
 */
export interface Base64Image {
  /**
   *
   * @type {string}
   * @memberof Base64Image
   */
  imageType: Base64ImageImageTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Base64Image
   */
  base64EncodedImage: string;
}

export const Base64ImageImageTypeEnum = {
  Png: 'png',
  Jpeg: 'jpeg'
} as const;

export type Base64ImageImageTypeEnum =
  (typeof Base64ImageImageTypeEnum)[keyof typeof Base64ImageImageTypeEnum];

/**
 *
 * @export
 * @interface BaseTerritory
 */
export interface BaseTerritory {
  /**
   *
   * @type {number}
   * @memberof BaseTerritory
   */
  territoryId: number;
  /**
   *
   * @type {Array<Polygon>}
   * @memberof BaseTerritory
   */
  polygons?: Array<Polygon>;
  /**
   *
   * @type {string}
   * @memberof BaseTerritory
   */
  marker?: string;
}
/**
 *
 * @export
 * @interface BulkCreateAssetsFileValidation
 */
export interface BulkCreateAssetsFileValidation {
  /**
   *
   * @type {HeaderValidation}
   * @memberof BulkCreateAssetsFileValidation
   */
  headerValidation: HeaderValidation;
  /**
   *
   * @type {Array<BulkCreateRow>}
   * @memberof BulkCreateAssetsFileValidation
   */
  assetsToBeCreated?: Array<BulkCreateRow>;
  /**
   *
   * @type {string}
   * @memberof BulkCreateAssetsFileValidation
   */
  error?: string;
}
/**
 *
 * @export
 * @interface BulkCreateRow
 */
export interface BulkCreateRow {
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  deliveryDate: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  endWarranty: string;
}
/**
 *
 * @export
 * @interface BulkCreateRowResult
 */
export interface BulkCreateRowResult {
  /**
   *
   * @type {string}
   * @memberof BulkCreateRowResult
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRowResult
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRowResult
   */
  assetUrl?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkCreateRowResult
   */
  errors?: Array<string>;
}
/**
 *
 * @export
 * @interface BulkCreateStatus
 */
export interface BulkCreateStatus {
  /**
   *
   * @type {string}
   * @memberof BulkCreateStatus
   */
  startedAt: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateStatus
   */
  startedBy: string;
  /**
   *
   * @type {BulkCreateStatusType}
   * @memberof BulkCreateStatus
   */
  status: BulkCreateStatusType;
  /**
   *
   * @type {number}
   * @memberof BulkCreateStatus
   */
  progress: number;
  /**
   *
   * @type {Array<BulkCreateRowResult>}
   * @memberof BulkCreateStatus
   */
  results?: Array<BulkCreateRowResult>;
}
/**
 * @type BulkCreateStatusType
 * @export
 */
export type BulkCreateStatusType = object;

/**
 *
 * @export
 * @interface BulkEditAssetsFileValidation
 */
export interface BulkEditAssetsFileValidation {
  /**
   *
   * @type {HeaderValidation}
   * @memberof BulkEditAssetsFileValidation
   */
  headerValidation: HeaderValidation;
  /**
   *
   * @type {Array<BulkEditRow>}
   * @memberof BulkEditAssetsFileValidation
   */
  vehiclesToBeEdited?: Array<BulkEditRow>;
  /**
   *
   * @type {string}
   * @memberof BulkEditAssetsFileValidation
   */
  error?: string;
}
/**
 *
 * @export
 * @interface BulkEditRow
 */
export interface BulkEditRow {
  /**
   *
   * @type {number}
   * @memberof BulkEditRow
   */
  vehicleId: number;
}
/**
 *
 * @export
 * @interface BulkEditRowResult
 */
export interface BulkEditRowResult {
  /**
   *
   * @type {number}
   * @memberof BulkEditRowResult
   */
  vehicleId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkEditRowResult
   */
  errors?: Array<string>;
}
/**
 *
 * @export
 * @interface BulkEditStartBody
 */
export interface BulkEditStartBody {
  /**
   *
   * @type {BulkEditAssetsFileValidation}
   * @memberof BulkEditStartBody
   */
  validation: BulkEditAssetsFileValidation;
  /**
   *
   * @type {string}
   * @memberof BulkEditStartBody
   */
  updateBodyJson: string;
}
/**
 *
 * @export
 * @interface BusinessLocation
 */
export interface BusinessLocation {
  /**
   *
   * @type {number}
   * @memberof BusinessLocation
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation
   */
  label: string;
  /**
   *
   * @type {BusinessLocationCenter}
   * @memberof BusinessLocation
   */
  cityCenter: BusinessLocationCenter;
  /**
   *
   * @type {BaseTerritory}
   * @memberof BusinessLocation
   */
  businessTerritory: BaseTerritory;
  /**
   *
   * @type {Array<BaseTerritory>}
   * @memberof BusinessLocation
   */
  noParkingTerritories?: Array<BaseTerritory>;
  /**
   *
   * @type {Array<BaseTerritory>}
   * @memberof BusinessLocation
   */
  incentiveTerritories?: Array<BaseTerritory>;
  /**
   *
   * @type {Array<MobilityHub>}
   * @memberof BusinessLocation
   */
  mobilityHubs?: Array<MobilityHub>;
}
/**
 *
 * @export
 * @interface BusinessLocation1
 */
export interface BusinessLocation1 {
  /**
   *
   * @type {number}
   * @memberof BusinessLocation1
   */
  locationId: number;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation1
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation1
   */
  abbreviation?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation1
   */
  projectKey: string;
  /**
   *
   * @type {GpsPosition}
   * @memberof BusinessLocation1
   */
  center: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof BusinessLocation1
   */
  widthInMeters: number;
  /**
   *
   * @type {number}
   * @memberof BusinessLocation1
   */
  heightInMeters: number;
  /**
   *
   * @type {number}
   * @memberof BusinessLocation1
   */
  tileSizeInMeters: number;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation1
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation1
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof BusinessLocation1
   */
  isDeleted: boolean;
}
/**
 *
 * @export
 * @interface BusinessLocationCenter
 */
export interface BusinessLocationCenter {
  /**
   *
   * @type {number}
   * @memberof BusinessLocationCenter
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof BusinessLocationCenter
   */
  lon: number;
}
/**
 *
 * @export
 * @interface ClientCredentials
 */
export interface ClientCredentials {
  /**
   *
   * @type {string}
   * @memberof ClientCredentials
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof ClientCredentials
   */
  password: string;
}
/**
 *
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  authorUsername: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  message: string;
}
/**
 * @type CommonIdType
 * @export
 */
export type CommonIdType = object;

/**
 * @type CommonModelType
 * @export
 */
export type CommonModelType = object;

/**
 *
 * @export
 * @interface CommonSearchResult
 */
export interface CommonSearchResult {
  /**
   *
   * @type {CommonModelType}
   * @memberof CommonSearchResult
   */
  modelType: CommonModelType;
  /**
   *
   * @type {CommonIdType}
   * @memberof CommonSearchResult
   */
  idType: CommonIdType;
  /**
   *
   * @type {string}
   * @memberof CommonSearchResult
   */
  idValue: string;
  /**
   *
   * @type {string}
   * @memberof CommonSearchResult
   */
  description: string;
}
/**
 *
 * @export
 * @interface CompiledTour
 */
export interface CompiledTour {
  /**
   *
   * @type {Tour}
   * @memberof CompiledTour
   */
  tour: Tour;
  /**
   *
   * @type {Array<Step>}
   * @memberof CompiledTour
   */
  compiledSteps?: Array<Step>;
}
/**
 *
 * @export
 * @interface CompleteChecksResponse
 */
export interface CompleteChecksResponse {
  /**
   *
   * @type {boolean}
   * @memberof CompleteChecksResponse
   */
  success: boolean;
  /**
   *
   * @type {Array<LocalizedDescription>}
   * @memberof CompleteChecksResponse
   */
  errors?: Array<LocalizedDescription>;
}
/**
 *
 * @export
 * @interface CompleteTourBody
 */
export interface CompleteTourBody {
  /**
   *
   * @type {number}
   * @memberof CompleteTourBody
   */
  serviceVehicleMileage: number;
}
/**
 *
 * @export
 * @interface Coordinate
 */
export interface Coordinate {
  /**
   *
   * @type {number}
   * @memberof Coordinate
   */
  index: number;
  /**
   *
   * @type {number}
   * @memberof Coordinate
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof Coordinate
   */
  lon: number;
}
/**
 *
 * @export
 * @interface CorridorPolygon
 */
export interface CorridorPolygon {
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  topLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  topRight: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  bottomLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  bottomRight: GpsPosition;
}
/**
 *
 * @export
 * @interface EmptyBusinessLocation
 */
export interface EmptyBusinessLocation {
  /**
   *
   * @type {string}
   * @memberof EmptyBusinessLocation
   */
  message: string;
}
/**
 *
 * @export
 * @interface EndTourBody
 */
export interface EndTourBody {
  /**
   *
   * @type {string}
   * @memberof EndTourBody
   */
  reason: string;
  /**
   *
   * @type {boolean}
   * @memberof EndTourBody
   */
  keepDriveBackOpen: boolean;
}
/**
 *
 * @export
 * @interface EstimatedDrivingTime
 */
export interface EstimatedDrivingTime {
  /**
   *
   * @type {GpsPosition}
   * @memberof EstimatedDrivingTime
   */
  start: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof EstimatedDrivingTime
   */
  end: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof EstimatedDrivingTime
   */
  estimatedDrivingSeconds: number;
}
/**
 * @type ExperienceLevel
 * @export
 */
export type ExperienceLevel = object;

/**
 *
 * @export
 * @interface ExtendByOneBody
 */
export interface ExtendByOneBody {
  /**
   *
   * @type {number}
   * @memberof ExtendByOneBody
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof ExtendByOneBody
   */
  lon: number;
}
/**
 *
 * @export
 * @interface FailStepBody
 */
export interface FailStepBody {
  /**
   *
   * @type {TransitionAction}
   * @memberof FailStepBody
   */
  cancelAction: TransitionAction;
  /**
   *
   * @type {string}
   * @memberof FailStepBody
   */
  reason: string;
}

/**
 *
 * @export
 * @interface FilterSet
 */
export interface FilterSet {
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  filterSetId?: number;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof FilterSet
   */
  serviceLocation: BusinessLocation1;
  /**
   *
   * @type {ServiceHub}
   * @memberof FilterSet
   */
  startHub?: ServiceHub;
  /**
   *
   * @type {Array<number>}
   * @memberof FilterSet
   */
  vehicleTypes?: Array<number>;
  /**
   *
   * @type {Array<SteeringTile>}
   * @memberof FilterSet
   */
  mustGoTiles?: Array<SteeringTile>;
  /**
   *
   * @type {Array<SteeringTile>}
   * @memberof FilterSet
   */
  noGoTiles?: Array<SteeringTile>;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  requiredLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  optionalLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  distanceWeight?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  densityWeight?: number;
}
/**
 *
 * @export
 * @interface FilterSetBody
 */
export interface FilterSetBody {
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  filterSetId?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  serviceLocationId: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  startHubId?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof FilterSetBody
   */
  vehicleTypes?: Array<number>;
  /**
   *
   * @type {Array<SteeringTileBody>}
   * @memberof FilterSetBody
   */
  mustGoTiles?: Array<SteeringTileBody>;
  /**
   *
   * @type {Array<SteeringTileBody>}
   * @memberof FilterSetBody
   */
  noGoTiles?: Array<SteeringTileBody>;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  requiredLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  optionalLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  distanceWeight?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  densityWeight?: number;
}
/**
 *
 * @export
 * @interface Forbidden
 */
export interface Forbidden {
  /**
   *
   * @type {string}
   * @memberof Forbidden
   */
  message: string;
}
/**
 *
 * @export
 * @interface GenericReservation
 */
export interface GenericReservation {
  /**
   *
   * @type {number}
   * @memberof GenericReservation
   */
  vehicleStateId?: number;
  /**
   *
   * @type {string}
   * @memberof GenericReservation
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof GenericReservation
   */
  endTime?: string;
  /**
   *
   * @type {ReservationLocation}
   * @memberof GenericReservation
   */
  startAddress?: ReservationLocation;
  /**
   *
   * @type {ReservationLocation}
   * @memberof GenericReservation
   */
  endAddress?: ReservationLocation;
  /**
   *
   * @type {number}
   * @memberof GenericReservation
   */
  startBatteryLevel?: number;
  /**
   *
   * @type {number}
   * @memberof GenericReservation
   */
  endBatteryLevel?: number;
}
/**
 *
 * @export
 * @interface GenericTourEvent
 */
export interface GenericTourEvent {
  /**
   *
   * @type {number}
   * @memberof GenericTourEvent
   */
  tourId: number;
  /**
   *
   * @type {number}
   * @memberof GenericTourEvent
   */
  jobId?: number;
  /**
   *
   * @type {number}
   * @memberof GenericTourEvent
   */
  stepId?: number;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventTimestamp: string;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof GenericTourEvent
   */
  description: LocalizedDescription;
  /**
   *
   * @type {GenericTourEventType}
   * @memberof GenericTourEvent
   */
  eventType: GenericTourEventType;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventSubType: string;
  /**
   *
   * @type {ActionResult}
   * @memberof GenericTourEvent
   */
  eventOutcome: ActionResult;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventError?: string;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventPayload?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const GenericTourEventType = {
  TourEvent: 'TourEvent',
  TransitionEvent: 'TransitionEvent',
  TriggerEvent: 'TriggerEvent'
} as const;

export type GenericTourEventType = (typeof GenericTourEventType)[keyof typeof GenericTourEventType];

/**
 *
 * @export
 * @interface GenericVehicleNote
 */
export interface GenericVehicleNote {
  /**
   *
   * @type {number}
   * @memberof GenericVehicleNote
   */
  noteId?: number;
  /**
   *
   * @type {string}
   * @memberof GenericVehicleNote
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof GenericVehicleNote
   */
  vehicleId?: number;
  /**
   *
   * @type {number}
   * @memberof GenericVehicleNote
   */
  createdBy?: number;
  /**
   *
   * @type {string}
   * @memberof GenericVehicleNote
   */
  createdAt?: string;
}
/**
 *
 * @export
 * @interface GpsPosition
 */
export interface GpsPosition {
  /**
   *
   * @type {number}
   * @memberof GpsPosition
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof GpsPosition
   */
  lon: number;
}
/**
 *
 * @export
 * @interface GpsPosition1
 */
export interface GpsPosition1 {
  /**
   *
   * @type {number}
   * @memberof GpsPosition1
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof GpsPosition1
   */
  lon: number;
}
/**
 *
 * @export
 * @interface Grid
 */
export interface Grid {
  /**
   *
   * @type {number}
   * @memberof Grid
   */
  locationId: number;
  /**
   *
   * @type {string}
   * @memberof Grid
   */
  createdAt: string;
  /**
   *
   * @type {Array<Tile>}
   * @memberof Grid
   */
  tiles?: Array<Tile>;
  /**
   *
   * @type {Array<Line>}
   * @memberof Grid
   */
  lines?: Array<Line>;
}
/**
 *
 * @export
 * @interface HeaderValidation
 */
export interface HeaderValidation {
  /**
   *
   * @type {boolean}
   * @memberof HeaderValidation
   */
  validated: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof HeaderValidation
   */
  expectedHeaders?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof HeaderValidation
   */
  foundHeaders?: Array<string>;
}
/**
 *
 * @export
 * @interface HelmetBox
 */
export interface HelmetBox {
  /**
   *
   * @type {boolean}
   * @memberof HelmetBox
   */
  locked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HelmetBox
   */
  closed?: boolean;
}
/**
 *
 * @export
 * @interface InjectedTaskBasedJob
 */
export interface InjectedTaskBasedJob {
  /**
   *
   * @type {number}
   * @memberof InjectedTaskBasedJob
   */
  taskId: number;
  /**
   *
   * @type {number}
   * @memberof InjectedTaskBasedJob
   */
  positionInCompiledJobList: number;
}
/**
 *
 * @export
 * @interface InputComment
 */
export interface InputComment {
  /**
   *
   * @type {string}
   * @memberof InputComment
   */
  message: string;
}
/**
 *
 * @export
 * @interface InputServiceTask
 */
export interface InputServiceTask {
  /**
   *
   * @type {LocalizedDescription}
   * @memberof InputServiceTask
   */
  title: LocalizedDescription;
  /**
   *
   * @type {AssignmentType}
   * @memberof InputServiceTask
   */
  taskType: AssignmentType;
  /**
   *
   * @type {AssignmentState}
   * @memberof InputServiceTask
   */
  state: AssignmentState;
  /**
   *
   * @type {number}
   * @memberof InputServiceTask
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof InputServiceTask
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof InputServiceTask
   */
  cancelReason?: string;
  /**
   *
   * @type {ServiceSourceType}
   * @memberof InputServiceTask
   */
  sourceType?: ServiceSourceType;
  /**
   *
   * @type {number}
   * @memberof InputServiceTask
   */
  businessLocationId: number;
  /**
   *
   * @type {string}
   * @memberof InputServiceTask
   */
  serviceAssetVin: string;
}

/**
 *
 * @export
 * @interface InputSubTask
 */
export interface InputSubTask {
  /**
   *
   * @type {LocalizedDescription}
   * @memberof InputSubTask
   */
  title: LocalizedDescription;
  /**
   *
   * @type {string}
   * @memberof InputSubTask
   */
  assetVin: string;
  /**
   *
   * @type {AssignmentState}
   * @memberof InputSubTask
   */
  state: AssignmentState;
  /**
   *
   * @type {string}
   * @memberof InputSubTask
   */
  failReason?: string;
  /**
   *
   * @type {StepCategory}
   * @memberof InputSubTask
   */
  category?: StepCategory;
  /**
   *
   * @type {number}
   * @memberof InputSubTask
   */
  usedByTaskId?: number;
}

/**
 *
 * @export
 * @interface InputWorkshop
 */
export interface InputWorkshop {
  /**
   *
   * @type {string}
   * @memberof InputWorkshop
   */
  shortName: string;
  /**
   *
   * @type {string}
   * @memberof InputWorkshop
   */
  name: string;
  /**
   *
   * @type {Array<number>}
   * @memberof InputWorkshop
   */
  serviceableBusinessLocationIds?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof InputWorkshop
   */
  businessLocationId: number;
  /**
   *
   * @type {number}
   * @memberof InputWorkshop
   */
  physicalLocationId: number;
  /**
   *
   * @type {string}
   * @memberof InputWorkshop
   */
  basicAccessRole: string;
  /**
   *
   * @type {string}
   * @memberof InputWorkshop
   */
  privilegedAccessRole: string;
  /**
   *
   * @type {boolean}
   * @memberof InputWorkshop
   */
  isExternal: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InputWorkshop
   */
  deprecated: boolean;
}
/**
 *
 * @export
 * @interface JiraBulkCreateSubtaskBody
 */
export interface JiraBulkCreateSubtaskBody {
  /**
   *
   * @type {Array<string>}
   * @memberof JiraBulkCreateSubtaskBody
   */
  vins?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof JiraBulkCreateSubtaskBody
   */
  subtaskDescription: string;
  /**
   *
   * @type {string}
   * @memberof JiraBulkCreateSubtaskBody
   */
  confirmationMailTo: string;
}
/**
 *
 * @export
 * @interface JiraIssue
 */
export interface JiraIssue {
  /**
   *
   * @type {number}
   * @memberof JiraIssue
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  summary: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  issueType: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  statusName: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  resolution: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  issueKey: string;
  /**
   *
   * @type {number}
   * @memberof JiraIssue
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  updated?: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  workshop?: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  reasonForRepair?: string;
}
/**
 * @type JiraPriorities
 * @export
 */
export type JiraPriorities = object;

/**
 *
 * @export
 * @interface JiraPriorityBody
 */
export interface JiraPriorityBody {
  /**
   *
   * @type {JiraPriorities}
   * @memberof JiraPriorityBody
   */
  priority: JiraPriorities;
}
/**
 *
 * @export
 * @interface Job
 */
export interface Job {
  /**
   *
   * @type {number}
   * @memberof Job
   */
  jobId?: number;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof Job
   */
  summary: LocalizedDescription;
  /**
   *
   * @type {AssignmentType}
   * @memberof Job
   */
  jobType: AssignmentType;
  /**
   *
   * @type {AssignmentState}
   * @memberof Job
   */
  state: AssignmentState;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  inheritedState?: string;
  /**
   *
   * @type {Array<Step>}
   * @memberof Job
   */
  preSteps?: Array<Step>;
  /**
   *
   * @type {Array<Step>}
   * @memberof Job
   */
  atSteps?: Array<Step>;
  /**
   *
   * @type {Array<Step>}
   * @memberof Job
   */
  postSteps?: Array<Step>;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  lastUpdatedAt?: string;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  scooterId?: number;
  /**
   *
   * @type {GpsPosition}
   * @memberof Job
   */
  location?: GpsPosition;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  jiraIssueKey?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  jiraIssueLink?: string;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  createdByServiceTask?: number;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  cancelReason?: string;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  positionInCompiledList?: number;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  priority?: number;
  /**
   *
   * @type {ServiceSourceType}
   * @memberof Job
   */
  serviceSourceType?: ServiceSourceType;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  replacedByJobId?: number;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  preEnergyLevel?: number;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  postEnergyLevel?: number;
}

/**
 *
 * @export
 * @interface JobExistingWithoutTask
 */
export interface JobExistingWithoutTask {
  /**
   *
   * @type {string}
   * @memberof JobExistingWithoutTask
   */
  message: string;
}
/**
 *
 * @export
 * @interface JobOrdering
 */
export interface JobOrdering {
  /**
   *
   * @type {number}
   * @memberof JobOrdering
   */
  jobId: number;
  /**
   *
   * @type {number}
   * @memberof JobOrdering
   */
  position: number;
}
/**
 *
 * @export
 * @interface LicencePlateMissing
 */
export interface LicencePlateMissing {
  /**
   *
   * @type {string}
   * @memberof LicencePlateMissing
   */
  message: string;
}
/**
 *
 * @export
 * @interface Line
 */
export interface Line {
  /**
   *
   * @type {GpsPosition}
   * @memberof Line
   */
  left: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Line
   */
  right: GpsPosition;
}
/**
 *
 * @export
 * @interface LiveData
 */
export interface LiveData {
  /**
   *
   * @type {number}
   * @memberof LiveData
   */
  battery_level: number;
  /**
   *
   * @type {number}
   * @memberof LiveData
   */
  board_voltage: number;
  /**
   *
   * @type {number}
   * @memberof LiveData
   */
  mileage: number;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  locked: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  ignition_off: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  immobilized: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  centerstand_out?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  keyfob_in?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  saddle_closed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  alarm_active?: boolean;
  /**
   *
   * @type {HelmetBox}
   * @memberof LiveData
   */
  helmetBox?: HelmetBox;
  /**
   *
   * @type {TelematicsPosition}
   * @memberof LiveData
   */
  location?: TelematicsPosition;
}
/**
 *
 * @export
 * @interface LocalizedDescription
 */
export interface LocalizedDescription {
  /**
   *
   * @type {string}
   * @memberof LocalizedDescription
   */
  de: string;
  /**
   *
   * @type {string}
   * @memberof LocalizedDescription
   */
  en: string;
}
/**
 *
 * @export
 * @interface LocalizedDescriptionEmpty
 */
export interface LocalizedDescriptionEmpty {
  /**
   *
   * @type {string}
   * @memberof LocalizedDescriptionEmpty
   */
  message: string;
}
/**
 * @type LogTaskChangeType
 * @export
 */
export type LogTaskChangeType = object;

/**
 *
 * @export
 * @interface MismatchingJiraToken
 */
export interface MismatchingJiraToken {
  /**
   *
   * @type {string}
   * @memberof MismatchingJiraToken
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof MismatchingJiraToken
   */
  code: string;
}
/**
 *
 * @export
 * @interface MissingField
 */
export interface MissingField {
  /**
   *
   * @type {string}
   * @memberof MissingField
   */
  message: string;
}
/**
 *
 * @export
 * @interface MissingPermission
 */
export interface MissingPermission {
  /**
   *
   * @type {string}
   * @memberof MissingPermission
   */
  message: string;
}
/**
 *
 * @export
 * @interface Mission
 */
export interface Mission {
  /**
   *
   * @type {ServiceEmployee}
   * @memberof Mission
   */
  createdBy: ServiceEmployee;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  createdAt: string;
  /**
   *
   * @type {MissionType}
   * @memberof Mission
   */
  missionType: MissionType;
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  priority: number;
  /**
   *
   * @type {Timespan}
   * @memberof Mission
   */
  validTimespan: Timespan;
  /**
   *
   * @type {UserTargetGroup}
   * @memberof Mission
   */
  offeredTo: UserTargetGroup;
  /**
   *
   * @type {FilterSet}
   * @memberof Mission
   */
  filterSet?: FilterSet;
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  maxNumberOfTours: number;
  /**
   *
   * @type {boolean}
   * @memberof Mission
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  missionId?: number;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  deletedAt?: string;
}

/**
 *
 * @export
 * @interface MissionBody
 */
export interface MissionBody {
  /**
   *
   * @type {number}
   * @memberof MissionBody
   */
  missionId?: number;
  /**
   *
   * @type {MissionType}
   * @memberof MissionBody
   */
  missionType: MissionType;
  /**
   *
   * @type {number}
   * @memberof MissionBody
   */
  priority: number;
  /**
   *
   * @type {UserTargetGroupBody}
   * @memberof MissionBody
   */
  offerTo: UserTargetGroupBody;
  /**
   *
   * @type {FilterSetBody}
   * @memberof MissionBody
   */
  filterSet?: FilterSetBody;
  /**
   *
   * @type {Timespan}
   * @memberof MissionBody
   */
  validTimespan: Timespan;
  /**
   *
   * @type {number}
   * @memberof MissionBody
   */
  maxNumberOfTours: number;
  /**
   *
   * @type {boolean}
   * @memberof MissionBody
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof MissionBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MissionBody
   */
  deletedAt?: string;
}

/**
 *
 * @export
 * @interface MissionOverview
 */
export interface MissionOverview {
  /**
   *
   * @type {Array<MissionWithTourStats>}
   * @memberof MissionOverview
   */
  missions?: Array<MissionWithTourStats>;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  totalMissions: number;
}
/**
 *
 * @export
 * @interface MissionTourStats
 */
export interface MissionTourStats {
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  missionId: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfTours: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfCompletedTours: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfFailedTours: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfOpenTours: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const MissionType = {
  Pickup: 'Pickup',
  Swap: 'Swap',
  Custom: 'Custom',
  Relocate: 'Relocate'
} as const;

export type MissionType = (typeof MissionType)[keyof typeof MissionType];

/**
 *
 * @export
 * @interface MissionWithTourStats
 */
export interface MissionWithTourStats {
  /**
   *
   * @type {Mission}
   * @memberof MissionWithTourStats
   */
  mission: Mission;
  /**
   *
   * @type {MissionTourStats}
   * @memberof MissionWithTourStats
   */
  missionTourStats: MissionTourStats;
}
/**
 *
 * @export
 * @interface MobilityHub
 */
export interface MobilityHub {
  /**
   *
   * @type {string}
   * @memberof MobilityHub
   */
  provider: string;
  /**
   *
   * @type {number}
   * @memberof MobilityHub
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof MobilityHub
   */
  lon: number;
}
/**
 *
 * @export
 * @interface ModelProblem
 */
export interface ModelProblem {
  /**
   *
   * @type {number}
   * @memberof ModelProblem
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ModelProblem
   */
  model: string;
  /**
   *
   * @type {boolean}
   * @memberof ModelProblem
   */
  deprecated: boolean;
  /**
   *
   * @type {ModelProblemCategory}
   * @memberof ModelProblem
   */
  category: ModelProblemCategory;
  /**
   *
   * @type {string}
   * @memberof ModelProblem
   */
  defectPart?: string;
  /**
   *
   * @type {string}
   * @memberof ModelProblem
   */
  defectState?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ModelProblemCategory = {
  Electronics: 'Electronics',
  Battery: 'Battery',
  Check: 'Check',
  HelmetBox: 'HelmetBox',
  Operations: 'Operations',
  Transmission: 'Transmission',
  Stickers: 'Stickers',
  PlatesAndStickers: 'PlatesAndStickers',
  Cloudboxx: 'Cloudboxx',
  LicencePlate: 'LicencePlate',
  Brakes: 'Brakes',
  Frame: 'Frame',
  FrontTrain: 'FrontTrain',
  Telematics: 'Telematics',
  General: 'General',
  Handlebar: 'Handlebar',
  Inspection: 'Inspection',
  BodyParts: 'BodyParts',
  Other: 'Other',
  Wheels: 'Wheels'
} as const;

export type ModelProblemCategory = (typeof ModelProblemCategory)[keyof typeof ModelProblemCategory];

/**
 *
 * @export
 * @interface ModelProblemCategoryApi
 */
export interface ModelProblemCategoryApi {
  /**
   *
   * @type {ModelProblemCategory}
   * @memberof ModelProblemCategoryApi
   */
  modelProblemCategory: ModelProblemCategory;
  /**
   *
   * @type {string}
   * @memberof ModelProblemCategoryApi
   */
  displayString: string;
}

/**
 *
 * @export
 * @interface NoJobsAvailable
 */
export interface NoJobsAvailable {
  /**
   *
   * @type {string}
   * @memberof NoJobsAvailable
   */
  message: string;
}
/**
 *
 * @export
 * @interface NotFound
 */
export interface NotFound {
  /**
   *
   * @type {string}
   * @memberof NotFound
   */
  message: string;
}
/**
 *
 * @export
 * @interface OIDCMachineToken
 */
export interface OIDCMachineToken {
  /**
   *
   * @type {string}
   * @memberof OIDCMachineToken
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof OIDCMachineToken
   */
  tokenType: string;
  /**
   *
   * @type {string}
   * @memberof OIDCMachineToken
   */
  scope: string;
  /**
   *
   * @type {number}
   * @memberof OIDCMachineToken
   */
  expiresIn: number;
  /**
   *
   * @type {number}
   * @memberof OIDCMachineToken
   */
  refreshExpiresIn: number;
  /**
   *
   * @type {number}
   * @memberof OIDCMachineToken
   */
  notBeforePolicy: number;
}
/**
 *
 * @export
 * @interface OIDCToken
 */
export interface OIDCToken {
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  refreshToken: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  idToken: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  tokenType: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  scope: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  sessionState: string;
  /**
   *
   * @type {number}
   * @memberof OIDCToken
   */
  expiresIn: number;
  /**
   *
   * @type {number}
   * @memberof OIDCToken
   */
  refreshExpiresIn: number;
  /**
   *
   * @type {number}
   * @memberof OIDCToken
   */
  notBeforePolicy: number;
}
/**
 *
 * @export
 * @interface OpenRepairTaskExists
 */
export interface OpenRepairTaskExists {
  /**
   *
   * @type {string}
   * @memberof OpenRepairTaskExists
   */
  message: string;
}
/**
 *
 * @export
 * @interface OpenServiceTaskExists
 */
export interface OpenServiceTaskExists {
  /**
   *
   * @type {string}
   * @memberof OpenServiceTaskExists
   */
  message: string;
}
/**
 *
 * @export
 * @interface OpenTourFound
 */
export interface OpenTourFound {
  /**
   *
   * @type {string}
   * @memberof OpenTourFound
   */
  message: string;
}

export const TransitionActionType = {
  Cancel: 'Cancel',
  Destructive: 'Destructive',
  Primary: 'Primary',
  Secondary: 'Secondary'
} as const;

export type TransitionActionType = (typeof TransitionActionType)[keyof typeof TransitionActionType];

/**
 *
 * @export
 * @interface OutputRepairTaskTransition
 */
export interface OutputRepairTaskTransition {
  /**
   *
   * @type {string}
   * @memberof OutputRepairTaskTransition
   */
  label: string;
  /**
   *
   * @type {RepairTaskState}
   * @memberof OutputRepairTaskTransition
   */
  targetState: RepairTaskState;
  /**
   *
   * @type {string}
   * @memberof OutputRepairTaskTransition
   */
  requiredUserRole: string;
  /**
   *
   * @type {boolean}
   * @memberof OutputRepairTaskTransition
   */
  isAllowed: boolean;
  /**
   *
   * @type {Array<LocalizedDescription>}
   * @memberof OutputRepairTaskTransition
   */
  notAllowedBecause: Array<LocalizedDescription>;
  /**
   *
   * @type {TransitionActionType}
   * @memberof OutputRepairTaskTransition
   */
  actionType: TransitionActionType;
}

export interface RepairTransitionEdge {
  source: RepairTaskState;
  target: RepairTaskState;
  label: string;
}

export interface RepairTransitionGraph {
  nodes: Array<RepairTaskState>;
  edges: Array<RepairTransitionEdge>;
}

/**
 *
 * @export
 * @interface PaginatedResultAssetInfo
 */
export interface PaginatedResultAssetInfo {
  /**
   *
   * @type {Array<AssetInfo>}
   * @memberof PaginatedResultAssetInfo
   */
  items?: Array<AssetInfo>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultAssetInfo
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultAssetInfo
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultAssetInfo
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultAssetInfo
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultPersistedVehicleReport
 */
export interface PaginatedResultPersistedVehicleReport {
  /**
   *
   * @type {Array<PersistedVehicleReport>}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  items?: Array<PersistedVehicleReport>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultRepairTask
 */
export interface PaginatedResultRepairTask {
  /**
   *
   * @type {Array<RepairTask>}
   * @memberof PaginatedResultRepairTask
   */
  items?: Array<RepairTask>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultRepairTask
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultRepairTask
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultRepairTask
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultRepairTask
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultServiceTask
 */
export interface PaginatedResultServiceTask {
  /**
   *
   * @type {Array<ServiceTask>}
   * @memberof PaginatedResultServiceTask
   */
  items?: Array<ServiceTask>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultServiceTask
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultServiceTask
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultServiceTask
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultServiceTask
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultSubTask
 */
export interface PaginatedResultSubTask {
  /**
   *
   * @type {Array<SubTask>}
   * @memberof PaginatedResultSubTask
   */
  items?: Array<SubTask>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultSubTask
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultSubTask
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultSubTask
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultSubTask
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultTaskAssetInfo
 */
export interface PaginatedResultTaskAssetInfo {
  /**
   *
   * @type {Array<TaskAssetInfo>}
   * @memberof PaginatedResultTaskAssetInfo
   */
  items?: Array<TaskAssetInfo>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTaskAssetInfo
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTaskAssetInfo
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTaskAssetInfo
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTaskAssetInfo
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultTour
 */
export interface PaginatedResultTour {
  /**
   *
   * @type {Array<Tour>}
   * @memberof PaginatedResultTour
   */
  items?: Array<Tour>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultWorkshopEvent
 */
export interface PaginatedResultWorkshopEvent {
  /**
   *
   * @type {Array<WorkshopEvent>}
   * @memberof PaginatedResultWorkshopEvent
   */
  items?: Array<WorkshopEvent>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedTourFilterBody
 */
export interface PaginatedTourFilterBody {
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  locationId: number;
  /**
   *
   * @type {MissionType}
   * @memberof PaginatedTourFilterBody
   */
  tourType: MissionType;
  /**
   *
   * @type {boolean}
   * @memberof PaginatedTourFilterBody
   */
  includeFinished: boolean;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  tourId?: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  batteryLevel?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  assignee?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  vehicleLicencePlate?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  fromDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  toDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  serviceVehicleLicencePlate?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof PaginatedTourFilterBody
   */
  vehicleTypeIds?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  missionId?: number;
}

/**
 *
 * @export
 * @interface ParameterNotAllowed
 */
export interface ParameterNotAllowed {
  /**
   *
   * @type {string}
   * @memberof ParameterNotAllowed
   */
  message: string;
}
/**
 *
 * @export
 * @interface PersistedVehicleReport
 */
export interface PersistedVehicleReport {
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  vehicleReportId: number;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  reporter: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  vin?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  licencePlate?: string;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  vehicleId?: number;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  reportedTime: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  resolvedBy?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  resolvedAt?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  resolvedThrough?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PersistedVehicleReport
   */
  incidents?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PersistedVehicleReport
   */
  imageUrls?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  lon: number;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  locationId: number;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  reportedDuringTourId?: number;
}
/**
 *
 * @export
 * @interface PickupForRepairTaskOverview
 */
export interface PickupForRepairTaskOverview {
  /**
   *
   * @type {Array<TaskAssetInfo>}
   * @memberof PickupForRepairTaskOverview
   */
  pickupTasksForWaitingRepairTask?: Array<TaskAssetInfo>;
  /**
   *
   * @type {Array<TaskAssetInfo>}
   * @memberof PickupForRepairTaskOverview
   */
  pickupTasksWithoutWaitingRepairTask?: Array<TaskAssetInfo>;
  /**
   *
   * @type {Array<AssetInfo>}
   * @memberof PickupForRepairTaskOverview
   */
  noPickupTaskForWaitingRepairTask?: Array<AssetInfo>;
}
/**
 *
 * @export
 * @interface Polygon
 */
export interface Polygon {
  /**
   *
   * @type {number}
   * @memberof Polygon
   */
  polygonId: number;
  /**
   *
   * @type {boolean}
   * @memberof Polygon
   */
  isCutout: boolean;
  /**
   *
   * @type {Array<Coordinate>}
   * @memberof Polygon
   */
  coordinates?: Array<Coordinate>;
}
/**
 *
 * @export
 * @interface PreconditionCheckResult
 */
export interface PreconditionCheckResult {
  /**
   *
   * @type {boolean}
   * @memberof PreconditionCheckResult
   */
  success: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PreconditionCheckResult
   */
  reasons?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PreconditionCheckResult
   */
  issuesToBeCanceled?: Array<string>;
}
/**
 *
 * @export
 * @interface PreconditionFailed
 */
export interface PreconditionFailed {
  /**
   *
   * @type {string}
   * @memberof PreconditionFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface ProblemListTodo
 */
export interface ProblemListTodo {
  /**
   *
   * @type {number}
   * @memberof ProblemListTodo
   */
  todoId: number;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodo
   */
  content: string;
  /**
   *
   * @type {TodoStatusName}
   * @memberof ProblemListTodo
   */
  status: TodoStatusName;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodo
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface ProblemListTodoBody
 */
export interface ProblemListTodoBody {
  /**
   *
   * @type {TodoStatusName}
   * @memberof ProblemListTodoBody
   */
  status: TodoStatusName;
  /**
   *
   * @type {AvailableProblem}
   * @memberof ProblemListTodoBody
   */
  problem: AvailableProblem;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodoBody
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface ProblemListTodoResolveBody
 */
export interface ProblemListTodoResolveBody {
  /**
   *
   * @type {number}
   * @memberof ProblemListTodoResolveBody
   */
  todoId: number;
  /**
   *
   * @type {TodoStatusName}
   * @memberof ProblemListTodoResolveBody
   */
  status: TodoStatusName;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodoResolveBody
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface Project
 */
export interface Project {
  /**
   *
   * @type {string}
   * @memberof Project
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  name: string;
}
/**
 *
 * @export
 * @interface PublicUserProfile
 */
export interface PublicUserProfile {
  /**
   *
   * @type {string}
   * @memberof PublicUserProfile
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof PublicUserProfile
   */
  email?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PublicUserProfile
   */
  groups?: Array<string>;
}
/**
 *
 * @export
 * @interface ReasonBody
 */
export interface ReasonBody {
  /**
   *
   * @type {string}
   * @memberof ReasonBody
   */
  reason: string;
}
/**
 * @export
 * @enum {string}
 */
export const ReasonForRepair = {
  SituativeRepair: 'SituativeRepair',
  Accident: 'Accident',
  OEMWarrantyCase: 'OEMWarrantyCase',
  ForceMajeure: 'ForceMajeure',
  Inspection: 'Inspection',
  Vandalism: 'Vandalism',
  Thievery: 'Thievery',
  DesignFaultFixing: 'DesignFaultFixing',
  FollowUpRepair: 'FollowUpRepair'
} as const;
export type ReasonForRepair = (typeof ReasonForRepair)[keyof typeof ReasonForRepair];

/**
 *
 * @export
 * @interface RepairTask
 */
export interface RepairTask {
  /**
   *
   * @type {number}
   * @memberof RepairTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  title: string;
  /**
   *
   * @type {RepairTaskState}
   * @memberof RepairTask
   */
  state: RepairTaskState;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof RepairTask
   */
  displayState: LocalizedDescription;
  /**
   *
   * @type {number}
   * @memberof RepairTask
   */
  priority: number;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  description?: string;
  /**
   *
   * @type {ReasonForRepair}
   * @memberof RepairTask
   */
  reasonForRepair: ReasonForRepair;
  /**
   *
   * @type {number}
   * @memberof RepairTask
   */
  mileage: number;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  vehicleVin: string;
  /**
   *
   * @type {Workshop}
   * @memberof RepairTask
   */
  workshop: Workshop;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof RepairTask
   */
  businessLocation: BusinessLocation1;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  assignee?: string;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  reporter: string;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  updatedAt: string;
  /**
   *
   * @type {Array<Comment>}
   * @memberof RepairTask
   */
  comments?: Array<Comment>;
  /**
   *
   * @type {Array<RepairTaskProblem>}
   * @memberof RepairTask
   */
  problems?: Array<RepairTaskProblem>;
  /**
   *
   * @type {number}
   * @memberof RepairTask
   */
  vehicleReportId: number;
}

/**
 *
 * @export
 * @interface RepairTaskProblem
 */
export interface RepairTaskProblem {
  /**
   *
   * @type {number}
   * @memberof RepairTaskProblem
   */
  problemId: number;
  /**
   *
   * @type {number}
   * @memberof RepairTaskProblem
   */
  repairTaskId: number;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  labelWhenAdded: string;
  /**
   *
   * @type {RepairTaskProblemState}
   * @memberof RepairTaskProblem
   */
  state: RepairTaskProblemState;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  updatedBy: string;
}
/**
 * @export
 * @emum {string}
 */
export const RepairTaskProblemState = {
  Open: 'Open',
  WaitingForSparePart: 'WaitingForSparePart',
  OEMRepair: 'OEMRepair',
  Failed: 'Failed',
  Done: 'Done',
  CannotReproduce: 'CannotReproduce'
} as const;
export type RepairTaskProblemState =
  (typeof RepairTaskProblemState)[keyof typeof RepairTaskProblemState];

/**
 *
 * @export
 * @enum {string}
 */

export const RepairTaskState = {
  AwaitingFirstPrequalification: 'AwaitingFirstPrequalification',
  AwaitingSecondPrequalification: 'AwaitingSecondPrequalification',
  WaitingForSpareParts: 'WaitingForSpareParts',
  WaitingForScooter: 'WaitingForScooter',
  AwaitingRepair: 'AwaitingRepair',
  RepairInOperation: 'RepairInOperation',
  InCheckup: 'InCheckup',
  Closed: 'Closed',
  WaitingForExternalWorkshop: 'WaitingForExternalWorkshop',
  RepairPaused: 'RepairPaused',
  PendingOEMRepair: 'PendingOEMRepair',
  OEMRepairInProgress: 'OEMRepairInProgress',
  FinishedOEMRepair: 'FinishedOEMRepair',
  WaitingForHOO: 'WaitingForHOO',
  InExternalWorkshopTransferQueue: 'InExternalWorkshopTransferQueue',
  AwaitingOnStreetRepair: 'AwaitingOnStreetRepair',
  OnStreetRepairInOperation: 'OnStreetRepairInOperation'
} as const;

export type RepairTaskState = (typeof RepairTaskState)[keyof typeof RepairTaskState];

export type RepairTaskAssetInfo = {
  asset: AssetInfo;
  task: RepairTask;
};

export type ModelProblemSummaryItem = {
  amount: number;
  problem: ModelProblem;
};

export type VehicleTypeSummaryItem = {
  amount: number;
  assetType: AssetType;
};

export type OnSteetTourOverview = {
  repairTourId: number;
  vehicleTypeSummary: VehicleTypeSummaryItem[];
  problemSummary: ModelProblemSummaryItem[];
};

export const OnStreetRepairTourState = {
  Created: 'Created',
  Ongoing: 'Ongoing',
  Canceled: 'Canceled',
  Done: 'Done'
} as const;

export type OnStreetRepairTourState =
  (typeof OnStreetRepairTourState)[keyof typeof OnStreetRepairTourState];

export type OnStreetRepairTour = {
  id: number;
  user: string;
  workshop: Workshop;
  assetsAndTasks: RepairTaskAssetInfo[];
  state: OnStreetRepairTourState;
  createdAt: Date;
  updatedAt: Date;
};

export type PaginatedResultOnStreetRepairTour = {
  /**
   *
   * @type {Array<OnStreetRepairTour>}
   * @memberof PaginatedResultOnStreetRepairTour
   */
  items: Array<OnStreetRepairTour>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultOnStreetRepairTour
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultOnStreetRepairTour
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultOnStreetRepairTour
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultOnStreetRepairTour
   */
  totalPages: number;
};

/**
 *
 * @export
 * @interface RepairTaskTransitionNotAllowed
 */
export interface RepairTaskTransitionNotAllowed {
  /**
   *
   * @type {string}
   * @memberof RepairTaskTransitionNotAllowed
   */
  message: string;
}
/**
 *
 * @export
 * @interface RepairTicketFailBody
 */
export interface RepairTicketFailBody {
  /**
   *
   * @type {string}
   * @memberof RepairTicketFailBody
   */
  reason: string;
}
/**
 *
 * @export
 * @interface RequestBlocked
 */
export interface RequestBlocked {
  /**
   *
   * @type {string}
   * @memberof RequestBlocked
   */
  message: string;
}
/**
 *
 * @export
 * @interface ReservationLocation
 */
export interface ReservationLocation {
  /**
   *
   * @type {number}
   * @memberof ReservationLocation
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof ReservationLocation
   */
  lng?: number;
  /**
   *
   * @type {string}
   * @memberof ReservationLocation
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationLocation
   */
  houseNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationLocation
   */
  zipCode?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationLocation
   */
  city?: string;
  /**
   *
   * @type {number}
   * @memberof ReservationLocation
   */
  county?: number;
}
/**
 *
 * @export
 * @interface RouteOptimizationActivity
 */
export interface RouteOptimizationActivity {
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationActivity
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationActivity
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationActivity
   */
  location_id: string;
  /**
   *
   * @type {RouteOptimizationAddress}
   * @memberof RouteOptimizationActivity
   */
  address: RouteOptimizationAddress;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationActivity
   */
  distance: number;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationActivity
   */
  driving_time: number;
}
/**
 *
 * @export
 * @interface RouteOptimizationAddress
 */
export interface RouteOptimizationAddress {
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationAddress
   */
  location_id: string;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationAddress
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationAddress
   */
  lon: number;
}
/**
 *
 * @export
 * @interface RouteOptimizationPoint
 */
export interface RouteOptimizationPoint {
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationPoint
   */
  type: string;
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof RouteOptimizationPoint
   */
  coordinates?: Array<Array<number>>;
}
/**
 *
 * @export
 * @interface RouteOptimizationResult
 */
export interface RouteOptimizationResult {
  /**
   *
   * @type {RouteOptimizationSolution}
   * @memberof RouteOptimizationResult
   */
  solution: RouteOptimizationSolution;
}
/**
 *
 * @export
 * @interface RouteOptimizationRoute
 */
export interface RouteOptimizationRoute {
  /**
   *
   * @type {Array<RouteOptimizationActivity>}
   * @memberof RouteOptimizationRoute
   */
  activities?: Array<RouteOptimizationActivity>;
  /**
   *
   * @type {Array<RouteOptimizationPoint>}
   * @memberof RouteOptimizationRoute
   */
  points?: Array<RouteOptimizationPoint>;
}
/**
 *
 * @export
 * @interface RouteOptimizationSolution
 */
export interface RouteOptimizationSolution {
  /**
   *
   * @type {Array<RouteOptimizationRoute>}
   * @memberof RouteOptimizationSolution
   */
  routes?: Array<RouteOptimizationRoute>;
}
/**
 *
 * @export
 * @interface ServiceEmployee
 */
export interface ServiceEmployee {
  /**
   *
   * @type {string}
   * @memberof ServiceEmployee
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmployee
   */
  email: string;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof ServiceEmployee
   */
  experienceLevel: ExperienceLevel;
}
/**
 *
 * @export
 * @interface ServiceHub
 */
export interface ServiceHub {
  /**
   *
   * @type {number}
   * @memberof ServiceHub
   */
  hubId?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceHub
   */
  serviceLocationId: number;
  /**
   *
   * @type {string}
   * @memberof ServiceHub
   */
  name: string;
  /**
   *
   * @type {GpsPosition}
   * @memberof ServiceHub
   */
  gpsPosition: GpsPosition;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isActive: boolean;
  /**
   *
   * @type {string}
   * @memberof ServiceHub
   */
  deletedAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isServicePoint: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isWorkshop: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isExternalWorkshop: boolean;
  /**
   *
   * @type {string}
   * @memberof ServiceHub
   */
  jiraGroupInCharge?: string;
}
/**
 *
 * @export
 * @interface ServiceMapTaskOverview
 */
export interface ServiceMapTaskOverview {
  /**
   *
   * @type {Array<TaskAssetInfo>}
   * @memberof ServiceMapTaskOverview
   */
  tasksInSync?: Array<TaskAssetInfo>;
  /**
   *
   * @type {Array<TaskAssetInfo>}
   * @memberof ServiceMapTaskOverview
   */
  tasksOutOfSync?: Array<TaskAssetInfo>;
  /**
   *
   * @type {Array<AssetInfo>}
   * @memberof ServiceMapTaskOverview
   */
  assetsWithoutTasks?: Array<AssetInfo>;
  /**
   *
   * @type {Grid}
   * @memberof ServiceMapTaskOverview
   */
  grid?: Grid;
}
/**
 *
 * @export
 * @interface ServiceOverview
 */
export interface ServiceOverview {
  /**
   *
   * @type {number}
   * @memberof ServiceOverview
   */
  locationId: number;
  /**
   *
   * @type {MissionType}
   * @memberof ServiceOverview
   */
  missionType: MissionType;
  /**
   *
   * @type {Array<Vehicle>}
   * @memberof ServiceOverview
   */
  availableVehicles?: Array<Vehicle>;
  /**
   *
   * @type {Grid}
   * @memberof ServiceOverview
   */
  grid: Grid;
}

/**
 *
 * @export
 * @interface ServiceParkingZone
 */
export interface ServiceParkingZone {
  /**
   *
   * @type {number}
   * @memberof ServiceParkingZone
   */
  parkingZoneId?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceParkingZone
   */
  hubId: number;
  /**
   *
   * @type {string}
   * @memberof ServiceParkingZone
   */
  label: string;
  /**
   *
   * @type {GpsPosition}
   * @memberof ServiceParkingZone
   */
  gpsPosition: GpsPosition;
  /**
   *
   * @type {boolean}
   * @memberof ServiceParkingZone
   */
  isActive: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ServiceParkingZone
   */
  isDropoff: boolean;
  /**
   *
   * @type {string}
   * @memberof ServiceParkingZone
   */
  deletedAt?: string;
}
/**
 *
 * @export
 * @interface ServicePhone
 */
export interface ServicePhone {
  /**
   *
   * @type {number}
   * @memberof ServicePhone
   */
  servicePhoneId?: number;
  /**
   *
   * @type {number}
   * @memberof ServicePhone
   */
  serviceLocationId: number;
  /**
   *
   * @type {string}
   * @memberof ServicePhone
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof ServicePhone
   */
  phoneNumber: string;
  /**
   *
   * @type {boolean}
   * @memberof ServicePhone
   */
  enabled: boolean;
  /**
   *
   * @type {number}
   * @memberof ServicePhone
   */
  inUseByTourId?: number;
  /**
   *
   * @type {string}
   * @memberof ServicePhone
   */
  deletedAt?: string;
}
/**
 * @export
 * @enum {string}
 */
export const ServiceSourceType = { Required: 'Required', Optional: 'Optional' } as const;
export type ServiceSourceType = (typeof ServiceSourceType)[keyof typeof ServiceSourceType];
/**
 *
 * @export
 * @interface ServiceTask
 */
export interface ServiceTask {
  /**
   *
   * @type {number}
   * @memberof ServiceTask
   */
  taskId: number;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof ServiceTask
   */
  title: LocalizedDescription;
  /**
   *
   * @type {AssignmentType}
   * @memberof ServiceTask
   */
  taskType: AssignmentType;
  /**
   *
   * @type {AssignmentState}
   * @memberof ServiceTask
   */
  state: AssignmentState;
  /**
   *
   * @type {number}
   * @memberof ServiceTask
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  cancelReason?: string;
  /**
   *
   * @type {ServiceSourceType}
   * @memberof ServiceTask
   */
  sourceType?: ServiceSourceType;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  updatedBy: string;
  /**
   *
   * @type {Array<Comment>}
   * @memberof ServiceTask
   */
  comments?: Array<Comment>;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof ServiceTask
   */
  businessLocation: BusinessLocation1;
  /**
   *
   * @type {Asset}
   * @memberof ServiceTask
   */
  serviceAsset: Asset;
  /**
   *
   * @type {Array<SubTask>}
   * @memberof ServiceTask
   */
  subTasks?: Array<SubTask>;
}

/**
 *
 * @export
 * @interface ServiceVehicle
 */
export interface ServiceVehicle {
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  serviceVehicleId?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  serviceLocationId: number;
  /**
   *
   * @type {string}
   * @memberof ServiceVehicle
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof ServiceVehicle
   */
  licencePlate: string;
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  mileage: number;
  /**
   *
   * @type {boolean}
   * @memberof ServiceVehicle
   */
  enabled: boolean;
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  inUseByTourId?: number;
  /**
   *
   * @type {string}
   * @memberof ServiceVehicle
   */
  deletedAt?: string;
}
/**
 *
 * @export
 * @interface ServiceVehiclesFilterSet
 */
export interface ServiceVehiclesFilterSet {
  /**
   *
   * @type {MissionType}
   * @memberof ServiceVehiclesFilterSet
   */
  missionType: MissionType;
  /**
   *
   * @type {number}
   * @memberof ServiceVehiclesFilterSet
   */
  locationId: number;
  /**
   *
   * @type {number}
   * @memberof ServiceVehiclesFilterSet
   */
  requiredLevel?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceVehiclesFilterSet
   */
  optionalLevel?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof ServiceVehiclesFilterSet
   */
  vehicleTypeIds?: Array<number>;
}

/**
 *
 * @export
 * @interface SourceTypeNotSet
 */
export interface SourceTypeNotSet {
  /**
   *
   * @type {string}
   * @memberof SourceTypeNotSet
   */
  message: string;
}
/**
 *
 * @export
 * @interface StatusInfo
 */
export interface StatusInfo {
  /**
   *
   * @type {number}
   * @memberof StatusInfo
   */
  statusId: number;
  /**
   *
   * @type {VehicleStatus}
   * @memberof StatusInfo
   */
  statusName: VehicleStatus;
  /**
   *
   * @type {number}
   * @memberof StatusInfo
   */
  percent: number;
  /**
   *
   * @type {number}
   * @memberof StatusInfo
   */
  amount: number;
}
/**
 *
 * @export
 * @interface SteeringTile
 */
export interface SteeringTile {
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  steeringTileId?: number;
  /**
   *
   * @type {string}
   * @memberof SteeringTile
   */
  tileId: string;
  /**
   *
   * @type {SteeringTileType}
   * @memberof SteeringTile
   */
  steeringType: SteeringTileType;
  /**
   *
   * @type {GpsPosition}
   * @memberof SteeringTile
   */
  topLeftCorner: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  size: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  index: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  usedByTourId?: number;
}

/**
 *
 * @export
 * @interface SteeringTileBody
 */
export interface SteeringTileBody {
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  steeringTileId?: number;
  /**
   *
   * @type {SteeringTileType}
   * @memberof SteeringTileBody
   */
  steeringType: SteeringTileType;
  /**
   *
   * @type {string}
   * @memberof SteeringTileBody
   */
  tileId: string;
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  topLeftCornerLat: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  topLeftCornerLon: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  index: number;
}

/**
 *
 * @export
 * @enum {string}
 */

export const SteeringTileType = {
  NoGo: 'NoGo',
  MustGo: 'MustGo'
} as const;

export type SteeringTileType = (typeof SteeringTileType)[keyof typeof SteeringTileType];

/**
 *
 * @export
 * @interface Step
 */
export interface Step {
  /**
   *
   * @type {number}
   * @memberof Step
   */
  stepId?: number;
  /**
   *
   * @type {StepType}
   * @memberof Step
   */
  stepType: StepType;
  /**
   *
   * @type {StepCategory}
   * @memberof Step
   */
  stepCategory: StepCategory;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof Step
   */
  stepDescription: LocalizedDescription;
  /**
   *
   * @type {boolean}
   * @memberof Step
   */
  isDoneOrFailed: boolean;
  /**
   *
   * @type {StepState}
   * @memberof Step
   */
  state: StepState;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  jiraIssueKey?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  jiraIssueLink?: string;
  /**
   *
   * @type {TransitionAction}
   * @memberof Step
   */
  startAction: TransitionAction;
  /**
   *
   * @type {Array<TriggerAction>}
   * @memberof Step
   */
  autoTriggerActionsOnStart?: Array<TriggerAction>;
  /**
   *
   * @type {TransitionAction}
   * @memberof Step
   */
  completeAction: TransitionAction;
  /**
   *
   * @type {Array<TriggerAction>}
   * @memberof Step
   */
  autoTriggerActionsOnComplete?: Array<TriggerAction>;
  /**
   *
   * @type {Array<TriggerAction>}
   * @memberof Step
   */
  triggerActions?: Array<TriggerAction>;
  /**
   *
   * @type {Array<TransitionAction>}
   * @memberof Step
   */
  cancelActions?: Array<TransitionAction>;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  startedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  completedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  failedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  canceledAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  cancelReason?: string;
  /**
   *
   * @type {Vehicle}
   * @memberof Step
   */
  vehicle?: Vehicle;
  /**
   *
   * @type {GpsPosition}
   * @memberof Step
   */
  gpsPosition?: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof Step
   */
  positionInCompiledList?: number;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  resolution?: string;
  /**
   *
   * @type {number}
   * @memberof Step
   */
  createdBySubTaskId?: number;
}

/**
 * @export
 * @enum {string}
 */
export const StepCategory = {
  DoSomething: 'DoSomething',
  BatterySwap: 'BatterySwap',
  ReplaceHelmets: 'ReplaceHelmets',
  DoSomethingWithSelection: 'DoSomethingWithSelection',
  DriveToScooter: 'DriveToScooter'
} as const;
export type StepCategory = (typeof StepCategory)[keyof typeof StepCategory];

/**
 *
 * @export
 * @enum {string}
 */

export const StepState = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  Failed: 'Failed',
  Canceled: 'Canceled',
  Considered: 'Considered'
} as const;

export type StepState = (typeof StepState)[keyof typeof StepState];

/**
 *
 * @export
 * @enum {string}
 */

export const StepType = {
  PreStep: 'PreStep',
  AtStep: 'AtStep',
  PostStep: 'PostStep'
} as const;

export type StepType = (typeof StepType)[keyof typeof StepType];

/**
 *
 * @export
 * @interface SubTask
 */
export interface SubTask {
  /**
   *
   * @type {number}
   * @memberof SubTask
   */
  subTaskId: number;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof SubTask
   */
  title: LocalizedDescription;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  assetVin: string;
  /**
   *
   * @type {AssignmentState}
   * @memberof SubTask
   */
  state: AssignmentState;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  updatedBy: string;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  cancelReason?: string;
  /**
   *
   * @type {number}
   * @memberof SubTask
   */
  usedByTaskId?: number;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  licencePlate?: string;
  /**
   *
   * @type {StepCategory}
   * @memberof SubTask
   */
  category: StepCategory;
}

/**
 *
 * @export
 * @interface TaskAssetInfo
 */
export interface TaskAssetInfo {
  /**
   *
   * @type {ServiceTask}
   * @memberof TaskAssetInfo
   */
  task: ServiceTask;
  /**
   *
   * @type {AssetInfo}
   * @memberof TaskAssetInfo
   */
  assetInfo: AssetInfo;
}
/**
 *
 * @export
 * @interface TaskLog
 */
export interface TaskLog {
  /**
   *
   * @type {number}
   * @memberof TaskLog
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof TaskLog
   */
  taskId: number;
  /**
   *
   * @type {LogTaskChangeType}
   * @memberof TaskLog
   */
  taskChange: LogTaskChangeType;
  /**
   *
   * @type {Action}
   * @memberof TaskLog
   */
  loggedAction: Action;
  /**
   *
   * @type {string}
   * @memberof TaskLog
   */
  at: string;
  /**
   *
   * @type {string}
   * @memberof TaskLog
   */
  by: string;
  /**
   *
   * @type {string}
   * @memberof TaskLog
   */
  summary: string;
}
/**
 *
 * @export
 * @interface TaskStatistics
 */
export interface TaskStatistics {
  /**
   *
   * @type {Array<TaskStatisticsEntry>}
   * @memberof TaskStatistics
   */
  pickup?: Array<TaskStatisticsEntry>;
  /**
   *
   * @type {Array<TaskStatisticsEntry>}
   * @memberof TaskStatistics
   */
  swap?: Array<TaskStatisticsEntry>;
  /**
   *
   * @type {Array<TaskStatisticsEntry>}
   * @memberof TaskStatistics
   */
  custom?: Array<TaskStatisticsEntry>;
  /**
   *
   * @type {Array<TaskStatisticsEntry>}
   * @memberof TaskStatistics
   */
  relocate?: Array<TaskStatisticsEntry>;
  /**
   *
   * @type {Array<TaskStatisticsEntry>}
   * @memberof TaskStatistics
   */
  subTasks?: Array<TaskStatisticsEntry>;
}
/**
 *
 * @export
 * @interface TaskStatisticsEntry
 */
export interface TaskStatisticsEntry {
  /**
   *
   * @type {TaskStatisticsState}
   * @memberof TaskStatisticsEntry
   */
  state: TaskStatisticsState;
  /**
   *
   * @type {number}
   * @memberof TaskStatisticsEntry
   */
  percentage: number;
  /**
   *
   * @type {number}
   * @memberof TaskStatisticsEntry
   */
  total: number;
}

export const TaskStatisticsState = {
  Failed: 'Failed',
  Ongoing: 'Ongoing',
  Pending: 'Pending',
  Total: 'Total'
} as const;

/**
 * @type TaskStatisticsState
 * @export
 */
export type TaskStatisticsState = (typeof TaskStatisticsState)[keyof typeof TaskStatisticsState];

export interface RepairTaskStatisticsEntry {
  state: RepairTaskState;
  number: number;
  percentage: number;
  total: number;
}

/**
 *
 * @export
 * @interface TelematicsPosition
 */
export interface TelematicsPosition {
  /**
   *
   * @type {number}
   * @memberof TelematicsPosition
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof TelematicsPosition
   */
  lon: number;
}
/**
 *
 * @export
 * @interface TextBody
 */
export interface TextBody {
  /**
   *
   * @type {string}
   * @memberof TextBody
   */
  body: string;
}
/**
 *
 * @export
 * @interface Tile
 */
export interface Tile {
  /**
   *
   * @type {string}
   * @memberof Tile
   */
  tileId: string;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  x: number;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  y: number;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  topLeftCorner: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  topRightCorner: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  bottomLeftCorner: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  bottomRightCorner: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  numberOfVehicles: number;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  normalizedDensity: number;
}
/**
 *
 * @export
 * @interface Timespan
 */
export interface Timespan {
  /**
   *
   * @type {string}
   * @memberof Timespan
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof Timespan
   */
  to?: string;
}
/**
 * @type TodoStatusName
 * @export
 */
export type TodoStatusName = object;

/**
 *
 * @export
 * @interface Tour
 */
export interface Tour {
  /**
   *
   * @type {number}
   * @memberof Tour
   */
  missionId?: number;
  /**
   *
   * @type {MissionType}
   * @memberof Tour
   */
  tourType: MissionType;
  /**
   *
   * @type {TourParameters}
   * @memberof Tour
   */
  tourParameters: TourParameters;
  /**
   *
   * @type {Array<Job>}
   * @memberof Tour
   */
  jobs?: Array<Job>;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  requestedAt: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  startedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  completedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  failedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  failReason?: string;
  /**
   *
   * @type {number}
   * @memberof Tour
   */
  tourId?: number;
  /**
   *
   * @type {Array<SteeringTile>}
   * @memberof Tour
   */
  usedSteeringTiles?: Array<SteeringTile>;
  /**
   *
   * @type {RouteOptimizationResult}
   * @memberof Tour
   */
  routeOptimizationResult?: RouteOptimizationResult;
  /**
   *
   * @type {TourState}
   * @memberof Tour
   */
  state: TourState;
}

/**
 *
 * @export
 * @interface TourAdaptionBodyWithTasks
 */
export interface TourAdaptionBodyWithTasks {
  /**
   *
   * @type {Array<number>}
   * @memberof TourAdaptionBodyWithTasks
   */
  removeJobIds?: Array<number>;
  /**
   *
   * @type {Array<InjectedTaskBasedJob>}
   * @memberof TourAdaptionBodyWithTasks
   */
  injectedTaskBasedJobs?: Array<InjectedTaskBasedJob>;
  /**
   *
   * @type {Array<JobOrdering>}
   * @memberof TourAdaptionBodyWithTasks
   */
  remainingJobsOrdering?: Array<JobOrdering>;
  /**
   *
   * @type {ServiceEmployee}
   * @memberof TourAdaptionBodyWithTasks
   */
  newAssignee?: ServiceEmployee;
}
/**
 *
 * @export
 * @interface TourCorridor
 */
export interface TourCorridor {
  /**
   *
   * @type {Array<TourCorridorPolygon>}
   * @memberof TourCorridor
   */
  polygons?: Array<TourCorridorPolygon>;
  /**
   *
   * @type {Array<TourCorridorCircle>}
   * @memberof TourCorridor
   */
  circles?: Array<TourCorridorCircle>;
  /**
   *
   * @type {CorridorPolygon}
   * @memberof TourCorridor
   */
  boundingBox: CorridorPolygon;
}
/**
 *
 * @export
 * @interface TourCorridorAnchor
 */
export interface TourCorridorAnchor {
  /**
   *
   * @type {Job}
   * @memberof TourCorridorAnchor
   */
  job?: Job;
  /**
   *
   * @type {ServiceHub}
   * @memberof TourCorridorAnchor
   */
  hub?: ServiceHub;
}
/**
 *
 * @export
 * @interface TourCorridorCircle
 */
export interface TourCorridorCircle {
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorCircle
   */
  center: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof TourCorridorCircle
   */
  radius: number;
  /**
   *
   * @type {TourCorridorAnchor}
   * @memberof TourCorridorCircle
   */
  anchor: TourCorridorAnchor;
}
/**
 *
 * @export
 * @interface TourCorridorPolygon
 */
export interface TourCorridorPolygon {
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  topLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  topRight: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  bottomLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  bottomRight: GpsPosition;
  /**
   *
   * @type {TourCorridorAnchor}
   * @memberof TourCorridorPolygon
   */
  leftAnchor: TourCorridorAnchor;
  /**
   *
   * @type {TourCorridorAnchor}
   * @memberof TourCorridorPolygon
   */
  rightAnchor: TourCorridorAnchor;
}
/**
 *
 * @export
 * @interface TourInteractionNotAllowed
 */
export interface TourInteractionNotAllowed {
  /**
   *
   * @type {string}
   * @memberof TourInteractionNotAllowed
   */
  message: string;
}
/**
 *
 * @export
 * @interface TourParameterOptionsBundle
 */
export interface TourParameterOptionsBundle {
  /**
   *
   * @type {BusinessLocation1}
   * @memberof TourParameterOptionsBundle
   */
  location: BusinessLocation1;
  /**
   *
   * @type {Array<ServiceEmployee>}
   * @memberof TourParameterOptionsBundle
   */
  serviceEmployees?: Array<ServiceEmployee>;
  /**
   *
   * @type {Array<ServicePhone>}
   * @memberof TourParameterOptionsBundle
   */
  servicePhones?: Array<ServicePhone>;
  /**
   *
   * @type {Array<ServiceVehicle>}
   * @memberof TourParameterOptionsBundle
   */
  serviceVehicle?: Array<ServiceVehicle>;
  /**
   *
   * @type {Array<ServiceHub>}
   * @memberof TourParameterOptionsBundle
   */
  serviceHubs?: Array<ServiceHub>;
}
/**
 *
 * @export
 * @interface TourParameters
 */
export interface TourParameters {
  /**
   *
   * @type {number}
   * @memberof TourParameters
   */
  tourId?: number;
  /**
   *
   * @type {number}
   * @memberof TourParameters
   */
  tourParameterId?: number;
  /**
   *
   * @type {Array<ServiceEmployee>}
   * @memberof TourParameters
   */
  assignees?: Array<ServiceEmployee>;
  /**
   *
   * @type {ServiceVehicle}
   * @memberof TourParameters
   */
  serviceVehicle: ServiceVehicle;
  /**
   *
   * @type {number}
   * @memberof TourParameters
   */
  capacity: number;
  /**
   *
   * @type {ServicePhone}
   * @memberof TourParameters
   */
  phone: ServicePhone;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourParameters
   */
  startLocation: GpsPosition;
  /**
   *
   * @type {ServiceHub}
   * @memberof TourParameters
   */
  startHub?: ServiceHub;
  /**
   *
   * @type {ServiceHub}
   * @memberof TourParameters
   */
  endHub: ServiceHub;
  /**
   *
   * @type {Array<VehicleType>}
   * @memberof TourParameters
   */
  vehicleTypes?: Array<VehicleType>;
}
/**
 *
 * @export
 * @interface TourParametersBody
 */
export interface TourParametersBody {
  /**
   *
   * @type {Array<string>}
   * @memberof TourParametersBody
   */
  assignees?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  serviceVehicleId: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  phoneId: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  endHubId: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  startHubId?: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  capacity: number;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourParametersBody
   */
  startLocation: GpsPosition;
  /**
   *
   * @type {Array<number>}
   * @memberof TourParametersBody
   */
  vehicleTypeIds?: Array<number>;
  /**
   *
   * @type {MissionType}
   * @memberof TourParametersBody
   */
  missionType: MissionType;
  /**
   *
   * @type {boolean}
   * @memberof TourParametersBody
   */
  debug?: boolean;
}

/**
 *
 * @export
 * @interface TourResponse
 */
export interface TourResponse {
  /**
   *
   * @type {boolean}
   * @memberof TourResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof TourResponse
   */
  error?: string;
  /**
   *
   * @type {CompiledTour}
   * @memberof TourResponse
   */
  updatedTour: CompiledTour;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TourState = {
  Requested: 'Requested',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Failed: 'Failed'
} as const;

export type TourState = (typeof TourState)[keyof typeof TourState];

/**
 *
 * @export
 * @interface TourStats
 */
export interface TourStats {
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  tourStatsId?: number;
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  tourId: number;
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  mileageBefore: number;
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  mileageAfter: number;
}
/**
 *
 * @export
 * @interface TourTaskLogs
 */
export interface TourTaskLogs {
  /**
   *
   * @type {number}
   * @memberof TourTaskLogs
   */
  tourId: number;
  /**
   *
   * @type {number}
   * @memberof TourTaskLogs
   */
  taskId: number;
  /**
   *
   * @type {Array<TaskLog>}
   * @memberof TourTaskLogs
   */
  taskLogs?: Array<TaskLog>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TransitionAction = {
  JiraTransitionActivatePickupTour: 'JiraTransitionActivatePickupTour',
  JiraTransitionApproachingScooter: 'JiraTransitionApproachingScooter',
  JiraTransitionScooterFound: 'JiraTransitionScooterFound',
  JiraTransitionPickedUp: 'JiraTransitionPickedUp',
  JiraTranistionFailedButDroppedOff: 'JiraTranistionFailedButDroppedOff',
  JiraTransitionDroppedOff: 'JiraTransitionDroppedOff',
  JiraTransitionScooterInaccessible: 'JiraTransitionScooterInaccessible',
  JiraTransitionScooterNotFound: 'JiraTransitionScooterNotFound',
  JiraTransitionCancelTour: 'JiraTransitionCancelTour',
  JiraTransitionSkipScooter: 'JiraTransitionSkipScooter',
  JiraTransitionPickupFailed: 'JiraTransitionPickupFailed',
  JiraTransitionClearingDropOff: 'JiraTransitionClearingDropOff',
  ServiceOnStreetTaskListRequestTour: 'ServiceOnStreetTaskListRequestTour',
  ServiceOnStreetTaskListActivateTour: 'ServiceOnStreetTaskListActivateTour',
  ServiceOnStreetTaskListCancelTour: 'ServiceOnStreetTaskListCancelTour',
  ServiceOnStreetTaskListCancelTourRequest: 'ServiceOnStreetTaskListCancelTourRequest',
  ServiceOnStreetTaskListApproachingOptional: 'ServiceOnStreetTaskListApproachingOptional',
  ServiceOnStreetTaskListCancelApproachingOptional:
    'ServiceOnStreetTaskListCancelApproachingOptional',
  ServiceOnStreetTaskListApproachingScooter: 'ServiceOnStreetTaskListApproachingScooter',
  ServiceOnStreetTaskListPerformingService: 'ServiceOnStreetTaskListPerformingService',
  ServiceOnStreetTaskListScooterNotFound: 'ServiceOnStreetTaskListScooterNotFound',
  ServiceOnStreetTaskListScooterInaccessible: 'ServiceOnStreetTaskListScooterInaccessible',
  ServiceOnStreetTaskListServiceSucceeded: 'ServiceOnStreetTaskListServiceSucceeded',
  ServiceOnStreetTaskListServiceFailed: 'ServiceOnStreetTaskListServiceFailed',
  ServiceTaskStartProgress: 'ServiceTaskStartProgress',
  ServiceTaskClose: 'ServiceTaskClose',
  ServiceTaskFail: 'ServiceTaskFail',
  ServiceTaskCancel: 'ServiceTaskCancel',
  JiraTransitionRequestOnsitePrequalification: 'JiraTransitionRequestOnsitePrequalification',
  BatterySwapTaskStartProgress: 'BatterySwapTaskStartProgress',
  BatterySwapTaskClose: 'BatterySwapTaskClose',
  BatterySwapTaskFail: 'BatterySwapTaskFail',
  BatterySwapTaskCancel: 'BatterySwapTaskCancel',
  RelocateActivate: 'RelocateActivate',
  RepairModeActivate: 'RepairModeActivate',
  Nothing: 'Nothing'
} as const;

export type TransitionAction = (typeof TransitionAction)[keyof typeof TransitionAction];

/**
 *
 * @export
 * @interface TransitionActionBody
 */
export interface TransitionActionBody {
  /**
   *
   * @type {Array<TransitionActionField>}
   * @memberof TransitionActionBody
   */
  fields?: Array<TransitionActionField>;
  /**
   *
   * @type {string}
   * @memberof TransitionActionBody
   */
  resolution?: string;
}
/**
 *
 * @export
 * @interface TransitionActionField
 */
export interface TransitionActionField {
  /**
   *
   * @type {string}
   * @memberof TransitionActionField
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TransitionActionField
   */
  value: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TransitionActionField
   */
  encodedValue?: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TriggerAction = {
  DisableAlarm: 'DisableAlarm',
  EnableAlarm: 'EnableAlarm',
  Lock: 'Lock',
  Unlock: 'Unlock',
  UnlockSaddle: 'UnlockSaddle',
  Nothing: 'Nothing'
} as const;

export type TriggerAction = (typeof TriggerAction)[keyof typeof TriggerAction];

/**
 *
 * @export
 * @interface TriggerActionBody
 */
export interface TriggerActionBody {
  /**
   *
   * @type {TriggerAction}
   * @memberof TriggerActionBody
   */
  action: TriggerAction;
}

/**
 *
 * @export
 * @interface TriggerActionBody1
 */
export interface TriggerActionBody1 {
  /**
   *
   * @type {TriggerAction}
   * @memberof TriggerActionBody1
   */
  requestedTriggerAction: TriggerAction;
}

/**
 *
 * @export
 * @interface TriggerActionNotAllowed
 */
export interface TriggerActionNotAllowed {
  /**
   *
   * @type {string}
   * @memberof TriggerActionNotAllowed
   */
  message: string;
}
/**
 *
 * @export
 * @interface TriggerEvent
 */
export interface TriggerEvent {
  /**
   *
   * @type {number}
   * @memberof TriggerEvent
   */
  tourId: number;
  /**
   *
   * @type {number}
   * @memberof TriggerEvent
   */
  stepId: number;
  /**
   *
   * @type {string}
   * @memberof TriggerEvent
   */
  username: string;
  /**
   *
   * @type {TriggerAction}
   * @memberof TriggerEvent
   */
  triggerAction: TriggerAction;
  /**
   *
   * @type {string}
   * @memberof TriggerEvent
   */
  triggeredAt: string;
  /**
   *
   * @type {ActionResult}
   * @memberof TriggerEvent
   */
  triggerResult: ActionResult;
  /**
   *
   * @type {string}
   * @memberof TriggerEvent
   */
  error?: string;
}

/**
 *
 * @export
 * @interface Unauthorized
 */
export interface Unauthorized {
  /**
   *
   * @type {string}
   * @memberof Unauthorized
   */
  message: string;
}
/**
 *
 * @export
 * @interface Unknown
 */
export interface Unknown {
  /**
   *
   * @type {string}
   * @memberof Unknown
   */
  message: string;
}
/**
 *
 * @export
 * @interface UpdateSubTask
 */
export interface UpdateSubTask {
  /**
   *
   * @type {number}
   * @memberof UpdateSubTask
   */
  subTaskId: number;
  /**
   *
   * @type {AssignmentState}
   * @memberof UpdateSubTask
   */
  state: AssignmentState;
  /**
   *
   * @type {string}
   * @memberof UpdateSubTask
   */
  cancelReason?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateSubTask
   */
  reopen?: boolean;
}

/**
 *
 * @export
 * @interface UpdateSubTasks
 */
export interface UpdateSubTasks {
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateSubTasks
   */
  subTaskIds?: Array<number>;
  /**
   *
   * @type {AssignmentState}
   * @memberof UpdateSubTasks
   */
  state: AssignmentState;
  /**
   *
   * @type {string}
   * @memberof UpdateSubTasks
   */
  cancelReason?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateSubTasks
   */
  reopen?: boolean;
}

/**
 *
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserProfile
   */
  emailVerified: boolean;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  givenName: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserProfile
   */
  groups?: Array<string>;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof UserProfile
   */
  experienceLevel: ExperienceLevel;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  accessToken: string;
}
/**
 *
 * @export
 * @interface UserTargetGroup
 */
export interface UserTargetGroup {
  /**
   *
   * @type {number}
   * @memberof UserTargetGroup
   */
  targetGroupId?: number;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof UserTargetGroup
   */
  serviceLocation: BusinessLocation1;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof UserTargetGroup
   */
  experienceLevel?: ExperienceLevel;
  /**
   *
   * @type {Array<ServiceEmployee>}
   * @memberof UserTargetGroup
   */
  employees?: Array<ServiceEmployee>;
}
/**
 *
 * @export
 * @interface UserTargetGroupBody
 */
export interface UserTargetGroupBody {
  /**
   *
   * @type {number}
   * @memberof UserTargetGroupBody
   */
  targetGroupId?: number;
  /**
   *
   * @type {number}
   * @memberof UserTargetGroupBody
   */
  serviceLocationId: number;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof UserTargetGroupBody
   */
  experienceLevel?: ExperienceLevel;
  /**
   *
   * @type {Array<string>}
   * @memberof UserTargetGroupBody
   */
  employees?: Array<string>;
}
/**
 *
 * @export
 * @interface ValidationResponse
 */
export interface ValidationResponse {
  /**
   *
   * @type {Array<AssetInfo>}
   * @memberof ValidationResponse
   */
  matched?: Array<AssetInfo>;
  /**
   *
   * @type {Array<string>}
   * @memberof ValidationResponse
   */
  unmatched?: Array<string>;
}
/**
 *
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  vehicleId: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  vehicleTypeId: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  vehicleStateId: number;
  /**
   *
   * @type {boolean}
   * @memberof Vehicle
   */
  isReserved: boolean;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  licencePlate: string;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  lon: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  batteryLevel: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  locationId: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  mileage?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Vehicle
   */
  visibleDamages?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  vin?: string;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  repairTicket?: JiraIssue;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  serviceTicket?: JiraIssue;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  pickupTicket?: JiraIssue;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  relocateTicket?: JiraIssue;
  /**
   *
   * @type {ServiceTask}
   * @memberof Vehicle
   */
  pickupTask?: ServiceTask;
  /**
   *
   * @type {ServiceTask}
   * @memberof Vehicle
   */
  relocateTask?: ServiceTask;
  /**
   *
   * @type {ServiceTask}
   * @memberof Vehicle
   */
  swapTask?: ServiceTask;
  /**
   *
   * @type {ServiceTask}
   * @memberof Vehicle
   */
  customTask?: ServiceTask;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  assetUrl?: string;
  /**
   *
   * @type {ServiceSourceType}
   * @memberof Vehicle
   */
  serviceSourceType?: ServiceSourceType;
}
/**
 *
 * @export
 * @interface VehicleDamageReport
 */
export interface VehicleDamageReport {
  /**
   *
   * @type {string}
   * @memberof VehicleDamageReport
   */
  damageDescription: string;
  /**
   *
   * @type {number}
   * @memberof VehicleDamageReport
   */
  reservationId: number;
  /**
   *
   * @type {string}
   * @memberof VehicleDamageReport
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof VehicleDamageReport
   */
  public: boolean;
  /**
   *
   * @type {string}
   * @memberof VehicleDamageReport
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface VehicleIdentifier
 */
export interface VehicleIdentifier {
  /**
   *
   * @type {string}
   * @memberof VehicleIdentifier
   */
  vin?: string;
  /**
   *
   * @type {string}
   * @memberof VehicleIdentifier
   */
  licencePlate?: string;
  /**
   *
   * @type {number}
   * @memberof VehicleIdentifier
   */
  vehicleId?: number;
}
/**
 *
 * @export
 * @interface VehicleReportBody
 */
export interface VehicleReportBody {
  /**
   *
   * @type {VehicleIdentifier}
   * @memberof VehicleReportBody
   */
  vehicleIdentifier: VehicleIdentifier;
  /**
   *
   * @type {Array<string>}
   * @memberof VehicleReportBody
   */
  incidents?: Array<VehicleReportBodyIncidentsEnum>;
  /**
   *
   * @type {string}
   * @memberof VehicleReportBody
   */
  description?: string;
  /**
   *
   * @type {Array<Base64Image>}
   * @memberof VehicleReportBody
   */
  images?: Array<Base64Image>;
  /**
   *
   * @type {GpsPosition1}
   * @memberof VehicleReportBody
   */
  position: GpsPosition1;
  /**
   *
   * @type {number}
   * @memberof VehicleReportBody
   */
  reportedDuringTourId?: number;
}

export const VehicleReportBodyIncidentsEnum = {
  Damage: 'Damage',
  BadlyParked: 'BadlyParked',
  BatteryStolen: 'BatteryStolen',
  PersonalItemsFound: 'PersonalItemsFound',
  MissingHelmet: 'MissingHelmet',
  WorkshopDocumentation: 'WorkshopDocumentation'
} as const;

export type VehicleReportBodyIncidentsEnum =
  (typeof VehicleReportBodyIncidentsEnum)[keyof typeof VehicleReportBodyIncidentsEnum];

/**
 *
 * @export
 * @interface VehicleReportResolveBody
 */
export interface VehicleReportResolveBody {
  /**
   *
   * @type {string}
   * @memberof VehicleReportResolveBody
   */
  resolutionType: VehicleReportResolveBodyResolutionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof VehicleReportResolveBody
   */
  resolvedThrough?: string;
  /**
   *
   * @type {number}
   * @memberof VehicleReportResolveBody
   */
  workshopId?: number;
}

export const VehicleReportResolveBodyResolutionTypeEnum = {
  JiraRepairTicket: 'jira-repair-ticket',
  WunderfleetDamage: 'wunderfleet-damage',
  NoActionRequired: 'no-action-required',
  BadgermoleRepairTask: 'badgermole-repair-task'
} as const;

export type VehicleReportResolveBodyResolutionTypeEnum =
  (typeof VehicleReportResolveBodyResolutionTypeEnum)[keyof typeof VehicleReportResolveBodyResolutionTypeEnum];

/**
 *
 * @export
 * @interface VehicleState
 */
export interface VehicleState {
  /**
   *
   * @type {number}
   * @memberof VehicleState
   */
  vehicleStateId: number;
  /**
   *
   * @type {string}
   * @memberof VehicleState
   */
  title: string;
}
/**
 *
 * @export
 * @interface VehicleStatistics
 */
export interface VehicleStatistics {
  /**
   *
   * @type {number}
   * @memberof VehicleStatistics
   */
  totalNumber: number;
  /**
   *
   * @type {number}
   * @memberof VehicleStatistics
   */
  cityId: number;
  /**
   *
   * @type {Array<StatusInfo>}
   * @memberof VehicleStatistics
   */
  numberPerStatus?: Array<StatusInfo>;
}
/**
 * @type VehicleStatus
 * @export
 */
export type VehicleStatus = object;

/**
 *
 * @export
 * @interface VehicleType
 */
export interface VehicleType {
  /**
   *
   * @type {number}
   * @memberof VehicleType
   */
  vehicleTypeId: number;
  /**
   *
   * @type {string}
   * @memberof VehicleType
   */
  manufacturer: string;
  /**
   *
   * @type {string}
   * @memberof VehicleType
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof VehicleType
   */
  pricingLevel: string;
}
/**
 *
 * @export
 * @interface VehicleTypeEntry
 */
export interface VehicleTypeEntry {
  /**
   *
   * @type {string}
   * @memberof VehicleTypeEntry
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof VehicleTypeEntry
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof VehicleTypeEntry
   */
  pricingLevel: VehicleTypeEntryPricingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof VehicleTypeEntry
   */
  vehicleTypeId: number;
}

export const VehicleTypeEntryPricingLevelEnum = {
  Default: 'default',
  Discounted: 'discounted',
  Overpriced: 'overpriced',
  Special: 'special',
  Unknown: 'unknown'
} as const;

export type VehicleTypeEntryPricingLevelEnum =
  (typeof VehicleTypeEntryPricingLevelEnum)[keyof typeof VehicleTypeEntryPricingLevelEnum];

/**
 *
 * @export
 * @interface VehiclesChangeStateBundle
 */
export interface VehiclesChangeStateBundle {
  /**
   *
   * @type {Array<string>}
   * @memberof VehiclesChangeStateBundle
   */
  vins?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof VehiclesChangeStateBundle
   */
  newState: string;
}
/**
 *
 * @export
 * @interface VinMissing
 */
export interface VinMissing {
  /**
   *
   * @type {string}
   * @memberof VinMissing
   */
  message: string;
}
/**
 *
 * @export
 * @interface Workshop
 */
export interface Workshop {
  /**
   *
   * @type {number}
   * @memberof Workshop
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  shortName: string;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  name: string;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof Workshop
   */
  businessLocation: BusinessLocation1;
  /**
   *
   * @type {Array<BusinessLocation1>}
   * @memberof Workshop
   */
  serviceableBusinessLocations?: Array<BusinessLocation1>;
  /**
   *
   * @type {ServiceHub}
   * @memberof Workshop
   */
  physicalLocation: ServiceHub;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  basicAccessRole: string;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  privilegedAccessRole: string;
  /**
   *
   * @type {boolean}
   * @memberof Workshop
   */
  isExternal: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Workshop
   */
  deprecated: boolean;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface WorkshopEvent
 */
export interface WorkshopEvent {
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  timestamp: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  jiraIssueKey: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  workshopGroup?: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  vin: string;
  /**
   *
   * @type {WorkshopEventType}
   * @memberof WorkshopEvent
   */
  eventType: WorkshopEventType;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  eventPayload: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  error?: string;
}
/**
 * @type WorkshopEventType
 * @export
 */
export type WorkshopEventType = object;

/**
 *
 * @export
 * @interface WorkshopTicket
 */
export interface WorkshopTicket {
  /**
   *
   * @type {JiraIssue}
   * @memberof WorkshopTicket
   */
  jiraIssue: JiraIssue;
  /**
   *
   * @type {Vehicle}
   * @memberof WorkshopTicket
   */
  vehicle: Vehicle;
  /**
   *
   * @type {Array<ProblemListTodo>}
   * @memberof WorkshopTicket
   */
  todos?: Array<ProblemListTodo>;
}
/**
 *
 * @export
 * @interface WunderBulkEditStatus
 */
export interface WunderBulkEditStatus {
  /**
   *
   * @type {string}
   * @memberof WunderBulkEditStatus
   */
  startedAt: string;
  /**
   *
   * @type {string}
   * @memberof WunderBulkEditStatus
   */
  startedBy: string;
  /**
   *
   * @type {WunderBulkEditStatusType}
   * @memberof WunderBulkEditStatus
   */
  status: WunderBulkEditStatusType;
  /**
   *
   * @type {number}
   * @memberof WunderBulkEditStatus
   */
  progress: number;
  /**
   *
   * @type {Array<BulkEditRowResult>}
   * @memberof WunderBulkEditStatus
   */
  results?: Array<BulkEditRowResult>;
}
/**
 * @type WunderBulkEditStatusType
 * @export
 */
export type WunderBulkEditStatusType = object;

/**
 *
 * @export
 * @interface WunderVehicleTag
 */
export interface WunderVehicleTag {
  /**
   *
   * @type {number}
   * @memberof WunderVehicleTag
   */
  tagId?: number;
  /**
   *
   * @type {number}
   * @memberof WunderVehicleTag
   */
  vehicleId?: number;
  /**
   *
   * @type {number}
   * @memberof WunderVehicleTag
   */
  tagTypeId?: number;
  /**
   *
   * @type {string}
   * @memberof WunderVehicleTag
   */
  taggedAt?: string;
}

export type WunderTagType = {
  tagTypeId: number;
  name: string;
  identifier: string;
  taggableModel?: Array<string>;
};
