import { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import type { TransitionAction } from '@badgermoleV2/api';

type CancelStepDialogProps = {
  cancelAction: TransitionAction;
  open: boolean;
  handleOk: (cancelAction: TransitionAction, comment: string) => void;
  handleCancel: () => void;
};

export const CancelStepDialog = ({
  cancelAction,
  handleOk,
  handleCancel,
  open
}: CancelStepDialogProps) => {
  const [t] = useTranslation();
  const [comment, setComment] = useState('');

  return (
    <Dialog
      PaperProps={{ sx: { width: '80%', height: '60%', padding: '.5rem' } }}
      open={open}
      onClose={handleCancel}
      onBackdropClick={handleCancel}
      data-testid="failActionDialog"
    >
      <DialogTitle>{t(`pickup-tour:cancelActions:${cancelAction}`)}</DialogTitle>
      <DialogContent>
        <TextField
          onChange={(e) => {
            setComment(e.target.value);
          }}
          helperText={t('pickup-tour:cancelDialog:reasonHint')}
          required={true}
          multiline={true}
          fullWidth
          rows={10}
          variant="filled"
          data-testid="failReasonInput"
        />
      </DialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography fontWeight="bold" variant="h3">
          {t('pickup-tour:cancelStepDialog:confirmMessage')}
        </Typography>
      </Box>
      <DialogActions>
        <Button
          onClick={handleCancel}
          color="secondary"
          variant="outlined"
          data-testid="cancelFailActionDialog"
        >
          {t('pickup-tour:cancelDialog:no')}
        </Button>
        <Button
          variant="contained"
          onClick={() => handleOk(cancelAction, comment)}
          color="primary"
          disabled={!comment || comment.length < 1}
          data-testid="confirmFailActionDialog"
        >
          {t(`pickup-tour:cancelActions:${cancelAction}`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
