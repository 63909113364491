import { theme } from '@/config';
import { MutationErrorHandler, QueryErrorHandler } from '@/error/ErrorHandler';
import { LocalizationUtils } from '@/utils/i18n/LocalizationUtils';
import {
  Button,
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material';
import { deDE, enUS } from '@mui/material/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import 'leaflet/dist/leaflet.css';
import { useMemo } from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/router';

const queryCache = new QueryCache({
  onError: QueryErrorHandler
});

const mutationCache = new MutationCache({
  onError: MutationErrorHandler
});

const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 9 //9hrs
    }
  }
});

export const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage });

const App = () => {
  const locale = LocalizationUtils.dateTimeLocale;
  const { i18n } = useTranslation();
  const themeWithLocales = useMemo(
    () => createTheme(theme, i18n.language === 'de' ? deDE : enUS),
    [i18n.language]
  );

  return (
    <>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeWithLocales}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={locale.get(i18n.language)}
          >
            <PersistQueryClientProvider
              client={queryClient}
              persistOptions={{
                persister: localStoragePersister,
                maxAge: 1000 * 60 * 60 * 9 //9hrs
              }}
            >
              <Toaster>
                {(t) => (
                  <ToastBar toast={t}>
                    {({ icon, message }) => (
                      <>
                        {icon}
                        {message}
                        {t.type !== 'loading' && (
                          <Button color="secondary" onClick={() => toast.dismiss(t.id)}>
                            X
                          </Button>
                        )}
                      </>
                    )}
                  </ToastBar>
                )}
              </Toaster>
              <RouterProvider router={router} />
              <ReactQueryDevtools initialIsOpen={false} />
            </PersistQueryClientProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
