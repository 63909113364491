import ErrorIcon from '@mui/icons-material/Error';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

type CriticalErrorDialogProps = {
  readonly open: boolean;
  readonly error: string;
};

export const CriticalErrorDialog = ({ open, error }: CriticalErrorDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          window.location.reload();
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>{error}</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <ErrorIcon sx={{ fontSize: 60 }} />
        <Button onClick={() => window.location.reload()}>Reload Page</Button>
      </DialogContent>
    </Dialog>
  );
};
