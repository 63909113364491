import { QUERY_KEYS } from '@/config/QueryKeys';
import type { MissionType } from '@badgermoleV2/api';
import { getOpenTour } from '@badgermoleV2/missions';
import { useQuery } from '@tanstack/react-query';

/**
 * This is the main hook for using the currently used open tour.
 * Every tour/step/job interaction, which updates the current tour is referring to this subscription!
 *
 * @param missionType Type of Mission of the tour you want to subscribe to
 * @returns An Object with the open Tour, isFetching and refetch functionality
 */
export const useOpenTour = (missionType: MissionType) => {
  const {
    data: openTour,
    isFetching,
    isLoading,
    refetch
  } = useQuery({
    queryKey: [QUERY_KEYS.tour.openTour, missionType],
    queryFn: async ({ signal }) => await getOpenTour(missionType, { signal })
  });

  return { openTour, isFetching, isLoading, refetch };
};
