import { QUERY_KEYS } from '@/config/QueryKeys';
import i18n from '@/utils/i18n';
import { LocalizationUtils } from '@/utils/i18n/LocalizationUtils';
import { DateTimeUtils } from '@badgermoleV2/DateTimeUtils';
import * as Sentry from '@sentry/react';
import type { Query, QueryKey } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { format } from 'date-fns/format';
import toast from 'react-hot-toast';

const errorCodes = [400, 401, 403, 404, 412, 500, 502, 504];

const getMessageByStatus = (code: number | undefined) => {
  if (code && errorCodes.includes(code)) return i18n.t(`error:${code}`);
  else return i18n.t('error:generic');
};

export const QueryErrorHandler = (
  error: Error,
  query: Query<unknown, unknown, unknown, QueryKey>
) => {
  const locale = LocalizationUtils.dateTimeLocale;
  const time = format(new Date(), 'PP pp', {
    locale: locale.get(i18n.language)
  });
  const axiosError = error as AxiosError;
  Sentry.captureException(axiosError);

  // This works because we know that all our calls to badgermole go through axios,
  // so we can safely cast the error to AxiosError.
  // TODO: This can easily be reworked into a global error handler function, when backend is ready.
  if (query.queryKey.includes(QUERY_KEYS.oidc.getToken))
    return toast.error(`Session could not be verified. Please log in again`);
  if (query.queryKey.includes(QUERY_KEYS.vehicle.liveData)) {
    return;
  }

  const prefix =
    `[${time}]\n` + import.meta.env.PROD || import.meta.env.mode === 'production'
      ? ''
      : query.queryKey.length > 1
        ? `[${query.queryKey.at(0)},${query.queryKey.at(1)}]`
        : query.queryKey.at(0);
  const message =
    axiosError.response &&
    axiosError.response.data &&
    typeof axiosError.response.data === 'object' &&
    'message' in axiosError.response.data
      ? (axiosError.response.data.message as string)
      : axiosError.message;

  toast.error(`${prefix}: ${message}`);
};

export const MutationErrorHandler = (error: Error) => {
  const time = DateTimeUtils.currentDateWithSeconds();
  const axiosError = error as AxiosError;
  Sentry.captureException(error);

  const message =
    axiosError.response &&
    axiosError.response.data &&
    typeof axiosError.response.data === 'object' &&
    'message' in axiosError.response.data
      ? (axiosError.response.data.message as string)
      : axiosError.message;

  toast.error(`[${time}]\n` + message);
};
