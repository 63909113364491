import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import type { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

type CheckboxListProps<T> = {
  readonly items: T[];
  readonly selectedItems: T[];
  readonly setItems: React.Dispatch<React.SetStateAction<T[]>>;
  readonly translationLocation?: string;
  readonly style?: CSSProperties;
};

export const CheckboxList = <T,>({
  items,
  selectedItems,
  setItems,
  translationLocation,
  style
}: CheckboxListProps<T>) => {
  const { t } = useTranslation();

  const handleChange = (other: T) => {
    setItems((prev) =>
      prev.includes(other) ? prev.filter((item) => item !== other) : [...prev, other]
    );
  };

  return (
    <FormGroup row sx={style}>
      {items.map((item) => (
        <FormControlLabel
          key={`Checkbox${item}`}
          data-testid={`formControl${item}`}
          labelPlacement="end"
          label={t(`${translationLocation}.${item}`)}
          control={
            <Checkbox
              data-testid={`checkbox${item}`}
              checked={selectedItems.includes(item)}
              onChange={() => handleChange(item)}
              color="primary"
            ></Checkbox>
          }
        />
      ))}
    </FormGroup>
  );
};
