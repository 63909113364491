import { GeoSearchControl } from 'leaflet-geosearch';
import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import type { ControlPosition } from 'leaflet';
import type { Provider } from 'leaflet-geosearch/dist/providers/index.js';

type SearchProps = {
  readonly provider: Provider;
  readonly position?: ControlPosition;
};

export const Search = ({ provider, position = 'topleft' }: SearchProps) => {
  const map = useMap();
  const [searchControl] = useState(
    GeoSearchControl({
      provider,
      position,
      keepResult: true,
      updateMap: true,
      style: 'bar'
    })
  );
  useEffect(() => {
    map.addControl(searchControl);
    return () => {
      map.removeControl(searchControl);
    };
  }, [map, searchControl]);

  return <></>;
};
