import {
  latLng,
  latLngBounds,
  type LatLngBoundsExpression,
  type LatLngExpression,
  type Map
} from 'leaflet';
import type { RefObject } from 'react';
import { createContext, useEffect, useRef } from 'react';
import { LayerGroup, MapContainer, Polygon, TileLayer } from 'react-leaflet';
import { useStore } from '@/store';
import { MapUtils } from '@/utils/MapUtils';
import { useTranslation } from 'react-i18next';
import { Search } from '@/components/Map/Search';
import { MapBoxProvider } from 'leaflet-geosearch';

type MapContextProps = {
  map: RefObject<Map> | null;
};

export const BusinessMapContext = createContext<MapContextProps>({ map: null });

type BusinessMapProps = {
  readonly style?: React.CSSProperties;
  readonly children?: React.ReactNode | React.ReactNode[];
  readonly center?: LatLngExpression;
  readonly zoom?: number;
  readonly withSearch?: boolean;
};

export const BusinessMap = ({ style, children, center, zoom, withSearch }: BusinessMapProps) => {
  const map = useRef<Map>(null);
  const tileLayerRef = useRef<L.TileLayer>(null);
  const { i18n } = useTranslation();

  const [cityBounds, noParkingAreas] = useStore((s) => [s.cityPolygons, s.cityNoParkingAreas]);

  useEffect(() => {
    tileLayerRef.current?.setUrl(MapUtils.getMapBoxTileLayerUrl(i18n.language));
  }, [i18n.language]);

  return (
    <MapContainer
      style={style}
      tap={false}
      bounds={
        cityBounds.length === 0
          ? latLngBounds([0, 0], [0, 0])
          : (cityBounds.flat(1) as LatLngBoundsExpression)
      }
      center={center}
      scrollWheelZoom
      zoom={zoom ?? 12}
      preferCanvas
      ref={map}
    >
      <BusinessMapContext.Provider value={{ map }}>
        <TileLayer
          maxZoom={22}
          url={MapUtils.getMapBoxTileLayerUrl(i18n.language)}
          ref={tileLayerRef}
        />
        {withSearch && (
          <Search
            provider={
              new MapBoxProvider({
                params: { access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN }
              })
            }
          />
        )}
        {cityBounds.map((vertex) => (
          <Polygon key={`CityBound${vertex}`} positions={vertex} color="red" fillOpacity={0} />
        ))}
        {noParkingAreas.map((vertex) => (
          <Polygon key={`NoParkingPolygon${vertex}`} positions={vertex} color="orange" />
        ))}
        <LayerGroup>{children}</LayerGroup>
      </BusinessMapContext.Provider>
    </MapContainer>
  );
};
