import { MutationErrorHandler } from '@/error/ErrorHandler';
import { useServiceTour } from '@/hooks/Tour/Service/useServiceTour';
import { TourUtils } from '@/utils/TourUtils';
import type {
  TransitionAction,
  Step,
  CompiledTour,
  TransitionActionField,
  TransitionActionBody
} from '@badgermoleV2/api';
import { AssignmentType, StepState, StepType } from '@badgermoleV2/api';
import { useMutation } from '@tanstack/react-query';
import { useTourInteraction } from './useTourInteraction';
import {
  completeChecksRequest,
  completeStepRequest,
  failStepRequest,
  refreshStepLocation,
  startStepRequest
} from '@badgermoleV2/steps';
import { MUTATION_KEYS } from '@/config/MutationKeys';

type TourStepBody = {
  tour: CompiledTour;
  step: Step;
};

type FailStepBody = {
  cancelAction: TransitionAction;
  failReason: string;
} & TourStepBody;

type CompleteStepBody = {
  transition?: TransitionActionField;
  resolution?: string;
} & TourStepBody;

export const useStepInteraction = () => {
  const { reloadTour, updateCurrentTourFromResponse } = useTourInteraction();

  const { startNextStepOrJob } = useServiceTour();

  const {
    mutate: startStep,
    mutateAsync: startStepAsync,
    isPending: isStartStepPending,
    isSuccess: isStartStepSuccess
  } = useMutation({
    mutationKey: [MUTATION_KEYS.step.start],
    mutationFn: async ({ tour, step }: TourStepBody) =>
      await startStepRequest(tour.tour.missionId!, tour.tour.tourId!, step.stepId!),
    onSuccess: updateCurrentTourFromResponse,
    onError: (error, { tour }) => {
      reloadTour(tour.tour.tourType);
      MutationErrorHandler(error);
    }
  });

  const {
    mutate: failStep,
    mutateAsync: failStepAsync,
    isPending: isFailStepPending
  } = useMutation({
    mutationKey: [MUTATION_KEYS.step.fail],
    mutationFn: async ({ tour, step, cancelAction, failReason }: FailStepBody) => {
      const body = {
        cancelAction,
        reason: failReason
      };
      return await failStepRequest(tour.tour.missionId!, tour.tour.tourId!, step.stepId!, body);
    },
    onSuccess: updateCurrentTourFromResponse,
    onError: (error, { tour }) => {
      reloadTour(tour.tour.tourType);
      MutationErrorHandler(error);
    }
  });

  const {
    mutate: completeStep,
    mutateAsync: completeStepAsync,
    isPending: isCompleteStepPending
  } = useMutation({
    mutationKey: [MUTATION_KEYS.step.complete],
    mutationFn: async ({ tour, step, resolution, transition }: CompleteStepBody) => {
      const body = {
        fields: transition ? [transition] : [],
        resolution
      };
      return await completeStepRequest(tour.tour.missionId!, tour.tour.tourId!, step.stepId!, body);
    },
    onSuccess: updateCurrentTourFromResponse,
    onError: (error, { tour }) => {
      reloadTour(tour.tour.tourType);
      MutationErrorHandler(error);
    }
  });

  const { mutate: reloadStepPosition, isPending: isReloadStepPositionPending } = useMutation({
    mutationKey: [MUTATION_KEYS.step.reload],
    mutationFn: ({ tour, step }: TourStepBody) =>
      refreshStepLocation(tour.tour.missionId!, tour.tour.tourId!, step.stepId!),
    onSuccess: updateCurrentTourFromResponse,
    onError: (error, { tour }) => {
      reloadTour(tour.tour.tourType);
      MutationErrorHandler(error);
    }
  });

  const { mutate: completeDriveToStep, isPending: isCompleteDriveToPending } = useMutation({
    mutationKey: [MUTATION_KEYS.step.complete + '-driveto'],
    mutationFn: async ({ tour, step }: TourStepBody) => {
      if (
        step.state === StepState.Completed &&
        !TourUtils.allAtStepsStarted(TourUtils.getJobForStep(tour.tour, step)!)
      ) {
        return reloadTour(tour.tour.tourType);
      } else {
        return await completeStepRequest(tour.tour.missionId!, tour.tour.tourId!, step.stepId!, {
          fields: []
        } satisfies TransitionActionBody);
      }
    },
    onSuccess: (data) => {
      if (data) updateCurrentTourFromResponse(data);
    },
    onError: (error, { tour }) => {
      reloadTour(tour.tour.tourType);
      MutationErrorHandler(error);
    }
  });

  const { mutateAsync: checkCompleteStepAsync, isPending: isCheckCompleteStepPending } =
    useMutation({
      mutationKey: [MUTATION_KEYS.step.check],
      mutationFn: async ({ tour, step }: TourStepBody) =>
        await completeChecksRequest(tour.tour.missionId!, tour.tour.tourId!, step.stepId!)
    });

  const { mutateAsync: completeMainStep, isPending: isCompleteMainStepPending } = useMutation({
    mutationKey: [MUTATION_KEYS.step.complete + '-main-step'],
    mutationFn: async ({ tour, step }: TourStepBody) => {
      const completeChecks = await checkCompleteStepAsync({ tour, step });
      if (!completeChecks.success) {
        return completeChecks;
      }

      const completeTourResponse = await completeStepRequest(
        tour.tour.missionId!,
        tour.tour.tourId!,
        step.stepId!,
        {
          fields: []
        }
      );
      if (completeTourResponse.success) {
        startNextStepOrJob(completeTourResponse.updatedTour);
      }
      updateCurrentTourFromResponse(completeTourResponse);
      return completeChecks;
    }
  });

  const {
    mutate: failMainStep,
    mutateAsync: failMainStepAsync,
    isPending: isFailMainStepPending
  } = useMutation({
    mutationKey: [MUTATION_KEYS.step.fail + '-main'],
    mutationFn: async ({ tour, step, cancelAction, failReason }: FailStepBody) => {
      const body = {
        cancelAction,
        reason: failReason
      };
      const tourResponse = await failStepRequest(
        tour.tour.missionId!,
        tour.tour.tourId!,
        step.stepId!,
        body
      );
      if (tourResponse.success) {
        startNextStepOrJob(tourResponse.updatedTour);
      }
      updateCurrentTourFromResponse(tourResponse);
      return tourResponse;
    },
    onError: (error, { tour }) => {
      reloadTour(tour.tour.tourType);
      MutationErrorHandler(error);
    }
  });

  const { mutateAsync: startNextConsideredStepAsync, isPending: isStartNextConsideredStepPending } =
    useMutation({
      mutationKey: [MUTATION_KEYS.step.start + '-considered-next'],
      mutationFn: async (tour?: CompiledTour) => {
        if (!tour) return;

        const nextStep = TourUtils.getUncompletedSteps(tour).at(0);
        if (!nextStep) return;

        const nextJob = TourUtils.getJobForStep(tour.tour, nextStep);
        if (!nextJob) return;

        if (
          nextJob.jobType !== AssignmentType.DriveBack &&
          nextStep.stepType !== StepType.PostStep &&
          nextStep.state === StepState.Considered
        )
          return await startStepAsync({ step: nextStep, tour });
      }
    });

  return {
    startStep,
    startStepAsync,
    startNextConsideredStepAsync,
    failStep,
    failStepAsync,
    failMainStep,
    failMainStepAsync,
    isFailMainStepPending,
    checkCompleteStepAsync,
    completeStep,
    completeStepAsync,
    completeMainStep,
    reloadStepPosition,
    completeDriveToStep,
    isCheckCompleteStepPending,
    isStartNextConsideredStepPending,
    isCompleteStepPending,
    isFailStepPending,
    isCompleteMainStepPending,
    isStartStepPending,
    isStartStepSuccess,
    isCompleteDriveToPending,
    isReloadStepPositionPending
  };
};
