import type { Step, Tour, TriggerActionBody } from '@badgermoleV2/api';
import { sendTriggerAction } from '@badgermoleV2/steps';
import { useMutation } from '@tanstack/react-query';

type SendVehicleInteractionBody = {
  missionId: Tour['missionId'];
  tourId: Tour['tourId'];
  stepId: Step['stepId'];
  action: TriggerActionBody;
};

export function useVehicleInteractionOnStep() {
  const {
    mutate: sendVehicleInteraction,
    mutateAsync: sendVehicleInteractionAsync,
    isPending
  } = useMutation({
    mutationFn: async ({ missionId, tourId, stepId, action }: SendVehicleInteractionBody) =>
      sendTriggerAction(missionId!, tourId!, stepId!, action.action)
  });

  return {
    sendVehicleInteraction,
    sendVehicleInteractionAsync,
    isVehicleInteractionPending: isPending
  };
}
