import { ROUTES } from '@/App/routes/routes';
import type { Vehicle } from '@badgermoleV2/api';
import { LaunchRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ReportButtonProps = {
  readonly vehicle: Vehicle;
};

export const ReportButton = ({ vehicle }: ReportButtonProps) => {
  const { t } = useTranslation('report-vehicle');
  return (
    <Button
      data-testid="reportButton"
      variant="text"
      color="secondary"
      size="small"
      href={
        ROUTES.REPORTS +
        '?' +
        new URLSearchParams({
          vehicle: vehicle!.licencePlate,
          disableSearch: 'true'
        })
      }
      target="_blank"
      referrerPolicy="no-referrer"
    >
      {t('title')}
      <LaunchRounded fontSize="small" sx={{ marginLeft: '.25em' }} />
    </Button>
  );
};
