import type { IconButtonProps } from '@mui/material';
import { IconButton } from '@mui/material';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';

type NavigateButtonProps = {
  readonly url: string;
  readonly iconButtonProps?: IconButtonProps;
};

export const NavigateButton = ({ url, ...iconButtonProps }: NavigateButtonProps) => {
  return (
    <IconButton
      {...iconButtonProps.iconButtonProps}
      size={iconButtonProps.iconButtonProps?.size ?? 'small'}
      color={iconButtonProps.iconButtonProps?.color ?? 'secondary'}
      onClick={
        iconButtonProps.iconButtonProps?.onClick ??
        (() => {
          window.open(url, '_blank', 'noopener noreferrer');
        })
      }
    >
      <AssistantDirectionIcon fontSize={'small'} sx={{ marginRight: '4px' }} />
    </IconButton>
  );
};
