import { QUERY_KEYS } from '@/config/QueryKeys';
import { getVehicleTypeCache } from '@badgermoleV2/cache';
import { useQuery } from '@tanstack/react-query';

export const useVehicleTypes = () => {
  const { data = [], isFetching } = useQuery({
    queryKey: [QUERY_KEYS.cache.activeVehicleTypes],
    queryFn: async ({ signal }) => await getVehicleTypeCache({ signal }),
    staleTime: 1000 * 60 * 60 // 1hr
  });

  const distinctVehicleNames = new Set(data.map((vt) => vt.model.split(' ')[0]));

  return { vehicleTypes: data, isLoading: isFetching, distinctVehicleNames };
};
