import { useStore } from '@/store';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useOidcAuthorization } from './useOidcAuthorization';
import type { OIDCToken } from '@badgermoleV2/api';
import { useNavigate } from 'react-router';
import { useCallback } from 'react';
import { ROUTES } from '@/App/routes/routes';
import { QUERY_KEYS } from '@/config/QueryKeys';
import { clearToken, fetchToken, setToken } from '@badgermoleV2/client';
import * as Sentry from '@sentry/react';

export const useGetOidcToken = (code: string | null) => {
  const [oidcCode, logout] = useStore((s) => [s.oidcCode, s.logout]);
  const { validateOidcToken } = useOidcAuthorization();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSuccess = useCallback(
    (token: OIDCToken) => {
      if (!validateOidcToken(token, oidcCode)) {
        Sentry.captureMessage(
          `token could not be validated with code ${oidcCode} and token \n${token}`
        );
        navigate(ROUTES.DEFAULT, { replace: true });
        return;
      }
      setToken(token);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.oidc.userProfile] });
    },
    [navigate, queryClient, oidcCode, validateOidcToken]
  );

  if (!code) {
    throw new Error('useOidcToken can only be used in a screen, where a login code is available');
  }

  const { isError, isSuccess } = useQuery({
    queryKey: [QUERY_KEYS.oidc.getToken, code],
    queryFn: async ({ signal }) => {
      return await fetchToken(code, import.meta.env.VITE_HOME_URL + ROUTES.LOGIN_RETURN, {
        signal
      });
    },
    retry: 1,
    select: onSuccess
  });

  if (isError) {
    logout();
    clearToken();
    navigate(ROUTES.DEFAULT, { replace: true });
  }

  return { isSuccess };
};
