import { useStore } from '@/store';
import type { OIDCToken } from '@badgermoleV2/api';
import { jwtDecode } from 'jwt-decode';
import { useCallback } from 'react';
import * as uuid from 'uuid';

export const useOidcAuthorization = () => {
  const [oidcCode, setOidcCode] = useStore((s) => [s.oidcCode, s.setOidcCode]);

  const createOidcCode = () => {
    if (!oidcCode) {
      const code = uuid.v4();
      setOidcCode(code);
      return code;
    }
    return oidcCode;
  };

  const validateOidcToken = useCallback(
    (oidcToken: OIDCToken, oidcCode: string | null): boolean => {
      if (!oidcCode) return false;
      const decoded = jwtDecode(oidcToken.idToken);
      if (typeof decoded === 'object' && decoded !== null && 'nonce' in decoded) {
        return decoded.nonce === oidcCode;
      }
      return false;
    },
    []
  );

  return { validateOidcToken, createOidcCode };
};
