import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import type { PersistedVehicleReport, Vehicle } from '@badgermoleV2/api';
import { useTranslation } from 'react-i18next';

type LastReportDialogProps = {
  readonly vehicle: Vehicle | undefined;
  readonly reports: PersistedVehicleReport[];
  readonly open: boolean;
  readonly handleOk: () => void;
};

export const LastReportsDialog = ({ vehicle, reports, handleOk, open }: LastReportDialogProps) => {
  const [t] = useTranslation();
  return (
    <Dialog
      data-testid="lastReportsDialog"
      open={open}
      onClose={handleOk}
      aria-labelledby="form-dialog-title"
      fullScreen={true}
    >
      <DialogTitle id="form-dialog-title">
        <br /> {t('report-vehicle:lastReports.title')} {vehicle?.licencePlate}
      </DialogTitle>
      <DialogContent>
        {!reports.some((report) => report.imageUrls) ? (
          <Box>{t('report-vehicle:lastReports.noReportsFound')}</Box>
        ) : (
          <></>
        )}
        <Box>
          {reports
            .flatMap((r) => r.imageUrls)
            .map((url) => (
              <img
                key={url}
                loading="lazy"
                src={url}
                alt=""
                style={{ width: '100%', height: 'auto', marginBottom: '8px' }}
              />
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button data-testid="closeButton" onClick={handleOk} color="secondary">
          {t('common:buttons.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
