import { Header } from '@/components/common/Header/Header';
import { useStore } from '@/store';
import { Outlet } from 'react-router';

export const Layout = () => {
  const [title] = useStore((s) => [s.title]);

  return (
    <>
      <Header title={title} hideMenu={false} />
      <Outlet />
    </>
  );
};
