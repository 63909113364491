import type { GpsPosition, GpsPosition1, Vehicle } from '@badgermoleV2/api';
import type { LatLng } from 'leaflet';

export class NavigatorUtils {
  static proximityCheck(pos1: LatLng, pos2: LatLng, distance: number) {
    return pos1.distanceTo(pos2) < distance;
  }
  static createMapsUrl = (coords: GpsPosition1 | GpsPosition | Vehicle | undefined) =>
    coords ? `https://www.google.de/maps/search/${coords.lat},${coords.lon}` : '';
}
