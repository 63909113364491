import { QUERY_KEYS } from '@/config/QueryKeys';
import type { GpsPosition1 } from '@badgermoleV2/api';
import { getEstimatedDrivingTime } from '@badgermoleV2/missions';
import { useQuery } from '@tanstack/react-query';

export function useEstimatedDrivingTime(
  destination: GpsPosition1 | null | undefined,
  location: { lat: number; lon: number } | undefined,
  enabled: boolean
) {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.tour.estimatedDrivingTime, destination?.lat, destination?.lon, location],
    queryFn: async ({ signal }) =>
      await getEstimatedDrivingTime(
        location!.lat,
        location!.lon,
        destination!.lat,
        destination!.lon,
        {
          signal
        }
      ),
    enabled: enabled,
    staleTime: 1000 * 20,
    retry: 1
  });

  return { estimatedDrivingTime: data, edtLoading: isFetching };
}
