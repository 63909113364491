import { ROUTES } from '@/App/routes/routes';
import { DataLoadingDialog } from '@/components/Dialogs/DataLoadingDialog';
import { TourFooter, TourHeader } from '@/components/Tour';
import { useTourInteraction } from '@/hooks/Tour/General/useTourInteraction';
import { useOpenTour } from '@/hooks/Tour/useOpenTour';
import { useStore } from '@/store';
import type { DriveBackInformationStatus } from '@/utils/TourUtils';
import { MissionType } from '@badgermoleV2/api';
import { Box, Button, Stack, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TaskActionCheckbox } from './TaskActionCheckbox/TaskActionCheckbox';

type FeedbackScreenProps = {
  readonly missionType: MissionType;
};

export const FeedbackScreen = ({ missionType }: FeedbackScreenProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { openTour: tour, isFetching } = useOpenTour(missionType);
  const setTourExtensionDeclined = useStore((s) => s.setTourExtensionDeclined);
  const vehicleMileage = tour?.tour.tourParameters.serviceVehicle.mileage;
  const [newVehicleMileage, setNewVehicleMileage] = useState<number | undefined>(vehicleMileage);
  const [
    driveBackInformationList,
    setDriveBackInformationStatus,
    setDriveBackInformationStatusList
  ] = useStore((s) => [
    s.driveBackInformationStatusList,
    s.setDriveBackInformationStatus,
    s.setDriveBackInformationStatusList
  ]);

  const { reloadTour, completeTourAsync } = useTourInteraction();

  const [mainLoading, setMainLoading] = useState(false);

  const allTasksFinished = useMemo(() => {
    if (!tour) return false;
    return (
      tour.tour.tourType === MissionType.Pickup ||
      tour.tour.tourType === MissionType.Custom ||
      !driveBackInformationList.some((task) => !task.checked && !task.failed)
    );
  }, [driveBackInformationList, tour]);

  useEffect(() => {
    if (!isFetching && !tour) {
      navigate(
        `${missionType !== MissionType.Pickup ? ROUTES.SERVICE.ROOT : ROUTES.PICKUP.ROOT}${
          ROUTES.TOUR.PREPARE
        }`
      );
    }
  }, [navigate, tour, missionType, isFetching]);

  const finishAllTasks = () => {
    const allFinished: DriveBackInformationStatus[] = driveBackInformationList.map((task) => {
      return { ...task, checked: true, failed: false } as DriveBackInformationStatus;
    });
    setDriveBackInformationStatusList(allFinished);
  };

  const resetAllTasks = () => {
    const allFinished: DriveBackInformationStatus[] = driveBackInformationList.map((task) => {
      return { ...task, checked: false, failed: false } as DriveBackInformationStatus;
    });
    setDriveBackInformationStatusList(allFinished);
  };

  const handleForceReloadTour = (): void => {
    if (!tour) return;
    if (mainLoading) return;
    reloadTour(tour.tour.tourType);
  };

  const completeTourHandler = async () => {
    if (!newVehicleMileage) return;
    setMainLoading(true);
    setTourExtensionDeclined(true);
    await completeTourAsync({
      compiledTour: tour!,
      vehicleMileage: newVehicleMileage!
    });
    resetAllTasks();
    navigate(ROUTES.OVERVIEW);
  };

  const handleMileageChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const mileageInput = parseInt(e.target.value);
    setNewVehicleMileage(isNaN(mileageInput) ? undefined : mileageInput);
  };

  if (!tour) return <div> ...loading</div>;

  return (
    <Box sx={{ margin: 1 }}>
      <TourHeader tour={tour} title={t('pickup-tour:tourComplete.header')} isTourRunning={false} />
      <Box sx={{ marginBottom: 10, textAlign: 'center' }}>
        {!(missionType !== MissionType.Pickup) ? (
          <img
            alt={'speedometer'}
            src={'/speedometer.png'}
            style={{ width: '50%', height: 'auto' }}
          />
        ) : (
          <Box sx={{ marginTop: '1rem' }} />
        )}
        <Stack direction={'column'} spacing={2}>
          <TextField
            id="old-vehicle-odometer"
            data-testid="old-vehicle-odometer"
            label={t('service-tour:feedbackScreen:oldMileage').toString()}
            value={vehicleMileage}
            onChange={handleMileageChange}
            fullWidth
            disabled={true}
          />
          <TextField
            id="set-vehicle-odometer"
            data-testid="set-vehicle-odometer"
            label={t('service-tour:feedbackScreen:newMileage').toString()}
            helperText={t('service-tour:feedbackScreen:newMileage').toString()}
            value={newVehicleMileage ?? ''}
            onChange={handleMileageChange}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            fullWidth
          />
          {missionType !== MissionType.Pickup && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Stack spacing={1} sx={{ width: '100%' }}>
                {driveBackInformationList.map((task) => (
                  <TaskActionCheckbox
                    key={`DriveBackTask${task.id}`}
                    doneAction={(checked: boolean, failed: boolean) => {
                      setDriveBackInformationStatus({
                        ...task,
                        checked: checked,
                        failed: failed
                      });
                    }}
                    task={task.task}
                    checkedStatus={task.checked}
                    failedStatus={task.failed}
                  />
                ))}
              </Stack>
              <Button
                color="secondary"
                onClick={finishAllTasks}
                data-testid={'driveBackTasksAllDoneButton'}
              >
                {t('common:buttons.allDone')}
              </Button>
            </Box>
          )}
          {mainLoading && <DataLoadingDialog open={true} message={t('common:loading:general')} />}
          <Button
            disabled={mainLoading || !newVehicleMileage || !allTasksFinished}
            aria-label={'submit'}
            variant="contained"
            color="success"
            fullWidth
            onClick={completeTourHandler}
            data-testid={'finishTour'}
          >
            {t('pickup-tour:finishTourAction')}
          </Button>
        </Stack>
      </Box>
      <TourFooter reloadAction={handleForceReloadTour} />
    </Box>
  );
};
