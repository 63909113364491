import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { BatteryLevelChip } from '@/components/Chips/BatteryLevelChip';
import { NavigateButton } from '@/components/common/NavigateButton/NavigateButton';
import { useTranslation } from 'react-i18next';
import { LayerGroup, Marker, Popup } from 'react-leaflet';
import type { Vehicle } from '@badgermoleV2/api';
import { IconUtils, MarkerState } from '@/utils/IconUtils';
import { NavigatorUtils } from '@/utils/NavigatorUtils';
import type { VehilceWithDistance } from '@/utils/MapUtils';

type DisplayAvailableVehiclesProps = {
  readonly vehicles: VehilceWithDistance[];
  readonly interact: (v: Vehicle) => void;
  readonly relocateActionLoading: boolean;
};

export const DisplayAvailableVehicles = ({
  vehicles,
  relocateActionLoading,
  interact
}: DisplayAvailableVehiclesProps) => {
  const { t } = useTranslation('relocate');
  return (
    <LayerGroup>
      {vehicles?.map(({ vehicle, distanceToUser: distance }) => (
        <Marker
          icon={IconUtils.buildIconForRelocate(MarkerState.AVAILABLE)}
          key={`DisplayVehicleFor${vehicle.vehicleId}`}
          position={[vehicle.lat, vehicle.lon]}
        >
          <Popup>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h2" fontWeight={'bold'}>
                  {vehicle.licencePlate}
                </Typography>
                <Typography variant={'subtitle2'}>{distance.toFixed(2)}km</Typography>
              </Box>
              <BatteryLevelChip size="small" batteryLevel={vehicle.batteryLevel} />
              <NavigateButton url={NavigatorUtils.createMapsUrl(vehicle)} />
            </Box>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              startIcon={relocateActionLoading && <CircularProgress size="1em" />}
              onClick={() => interact(vehicle)}
              fullWidth
              disabled={relocateActionLoading}
            >
              {t('relocate:addVehicle')}
            </Button>
          </Popup>
        </Marker>
      ))}
    </LayerGroup>
  );
};
