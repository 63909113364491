import type {
  CompiledTour,
  CompleteChecksResponse,
  Job,
  LocalizedDescription,
  Step
} from '@badgermoleV2/api';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type CompleteChecksDialogProps = {
  readonly open: boolean;
  readonly tour: CompiledTour;
  readonly job: Job;
  readonly step: Step;
  readonly allowIgnore: boolean;
  readonly completeChecksResponse: CompleteChecksResponse;
  readonly onRetry: () => void;
  readonly onClose: () => void;
  readonly onIgnore?: () => void;
};

export const CompleteChecksDialog = ({
  open,
  step,
  completeChecksResponse,
  onRetry,
  onClose,
  allowIgnore,
  onIgnore
}: CompleteChecksDialogProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      onBackdropClick={() => {}} // backdrop click will not close the dialog
    >
      <DialogTitle>{t('service-tour:completeChecks:title')}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Typography>{step.vehicle?.licencePlate}</Typography>
          {(completeChecksResponse?.errors ?? []).map((error) => (
            <Alert severity="error" sx={{ marginBottom: '16px' }} key={`Error${error['en']}`}>
              {error[i18n.language as keyof LocalizedDescription]}
            </Alert>
          ))}
          {allowIgnore && onIgnore && !completeChecksResponse.success && (
            <Stack direction="column" spacing={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  onIgnore();
                }}
                color="primary"
              >
                {t('service-tour:completeChecks:ignore')}
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="secondary">
          {t('service-tour:completeChecks:cancel')}
        </Button>
        <Button variant="outlined" onClick={onRetry} color="success">
          {t('service-tour:completeChecks:retry')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
