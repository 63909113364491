import { Stack } from '@mui/material';
import type { LocalizedDescription, Step, TransitionAction } from '@badgermoleV2/api';
import i18n from '@/utils/i18n';
import { ActionTaskItem } from '@/components/Tour/Job/CurrentJob/SwapTasks/ActionTasks/Item';

type ActionTaskListProps = {
  steps: Step[];
  onStepComplete: (_: Step) => void;
  onStepFail: (_: Step, cancelAction: TransitionAction, reason: string) => void;
};

export const ActionTaskList = ({ steps, onStepComplete, onStepFail }: ActionTaskListProps) => {
  return (
    <Stack spacing={1}>
      {steps.map((step) => (
        <ActionTaskItem
          key={`${step.stepId}${step.stepDescription}`}
          title={step.stepDescription[i18n.language as keyof LocalizedDescription]}
          step={step}
          onStepComplete={onStepComplete}
          onStepFail={onStepFail}
        />
      ))}
    </Stack>
  );
};
