import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/store';

interface Title {
  title: string;
  prefix?: string;
  suffix?: string;
  separator?: string;
}

/**
 * Updates the title of the document. (Tab name and Header name)
 *
 * [prefix][seperator][title][seperator][suffix]
 *
 * @param title \{title, prefix, suffix, seperator}
 * @returns
 */
export function useTitle({ title, prefix = '', suffix = '', separator = '' }: Title) {
  const setTitle = useStore((s) => s.setTitle);
  const [t] = useTranslation();

  useEffect(() => {
    const longTitle =
      (prefix ? prefix + separator : '') + t(title) + (suffix ? separator + suffix : '');
    document.title = longTitle;
    setTitle(longTitle);
  }, [title, t, prefix, suffix, separator, setTitle]);

  return {};
}
