import { IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import type { ExtendedBase64Image } from '@/utils/ReportUtils';

type ImagePreviewProps = {
  readonly images: ExtendedBase64Image[];
  readonly onSingleImageRemove: (_: string) => void;
};

export const ImagePreview = ({ images, onSingleImageRemove }: ImagePreviewProps) => {
  return (
    <ImageList cols={images.length === 1 ? 1 : 4}>
      {images.map((item) => (
        <ImageListItem data-testid="listItem" cols={1} key={item.identifier}>
          <img src={item.identifier} width="100" height="150" key={item.identifier} alt="" />
          <ImageListItemBar
            sx={{ background: 'transparent' }}
            position={'top'}
            actionIcon={
              <IconButton
                data-testid="remove"
                onClick={() => {
                  onSingleImageRemove(item.identifier);
                }}
                size="large"
              >
                <CloseIcon color={'primary'} />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};
