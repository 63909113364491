import { Chip } from '@mui/material';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import type { Vehicle } from '@badgermoleV2/api';
import type { CSSProperties } from 'react';

type BatteryLevelChipProps = {
  readonly batteryLevel: Vehicle['batteryLevel'];
  readonly size?: 'small' | 'medium';
  readonly style?: CSSProperties;
};

export const BatteryLevelChip = ({ batteryLevel, size, style }: BatteryLevelChipProps) => {
  return (
    <Chip
      sx={style}
      size={size}
      icon={<Battery0BarIcon fontSize={'small'} />}
      label={`${batteryLevel} %`}
    />
  );
};
