import { MutationErrorHandler } from '@/error/ErrorHandler';
import { useStore } from '@/store';
import type { CompiledTour, Job, Step, TourResponse } from '@badgermoleV2/api';
import { AssignmentState, AssignmentType, StepState } from '@badgermoleV2/api';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useTourInteraction } from '@/hooks/Tour/General/useTourInteraction';
import { startStepRequest } from '@badgermoleV2/steps';
import { batchStartStepsInJob } from '@badgermoleV2/jobs';
import { MUTATION_KEYS } from '@/config/MutationKeys';

export const useServiceTour = () => {
  const { reloadTour, updateCurrentTourFromResponse } = useTourInteraction();
  const tourExtensionDeclined = useStore((s) => s.tourExtensionDeclined);

  const getOpenJobs = (compiledTour: CompiledTour): Job[] => {
    return (compiledTour.tour.jobs ?? []).filter(
      (job) =>
        (job.state === AssignmentState.Considered || job.state === AssignmentState.Unassigned) &&
        job.jobType !== AssignmentType.DriveBack
    );
  };

  const findOpenDriveBackToHubStep = useCallback((compiledTour: CompiledTour): Step | undefined => {
    const found = compiledTour?.tour.jobs!.find(
      (job) =>
        job.jobType === AssignmentType.DriveBack &&
        (job.state === AssignmentState.Considered || job.state === AssignmentState.InProgress)
    );
    return found?.atSteps![0];
  }, []);

  const findMainServiceJob = useCallback((compiledTour: CompiledTour): Job | undefined => {
    return compiledTour?.tour.jobs!.find(
      (job) =>
        (job.jobType === AssignmentType.Swap || job.jobType === AssignmentType.Custom) &&
        (job.state === AssignmentState.InProgress ||
          job.state === AssignmentState.Considered ||
          job.state === AssignmentState.PreStepsCompleted ||
          job.state === AssignmentState.AtStepsCompleted)
    );
  }, []);

  const mainJobToStart = (currentTour: CompiledTour): Job | undefined => {
    const currentJob = (currentTour.tour.jobs ?? []).find(
      (j) => j.state === AssignmentState.PreStepsCompleted
    );

    return currentJob?.atSteps!.find((step) => step.state === StepState.Considered)
      ? currentJob
      : undefined;
  };

  const nextStepToStart = (currentTour: CompiledTour): Step | undefined => {
    const currentJob = (currentTour.tour.jobs ?? []).find(
      (job) =>
        job.state === AssignmentState.Considered ||
        job.state === AssignmentState.InProgress ||
        job.state === AssignmentState.PreStepsCompleted ||
        job.state === AssignmentState.AtStepsCompleted
    );

    if (!currentJob) {
      return undefined;
    }
    if (currentJob.jobType === AssignmentType.DriveBack) {
      if (!tourExtensionDeclined) {
        return undefined;
      }

      const stepToStart = currentJob.atSteps![0];
      if (stepToStart.state === StepState.Considered) {
        return stepToStart;
      } else {
        return undefined;
      }
    } else {
      const driveToStepToStart = currentJob.preSteps![0];
      if (driveToStepToStart.state === StepState.Considered) {
        return driveToStepToStart;
      } else {
        return undefined;
      }
    }
  };

  const {
    mutate: startNextStepOrJob,
    mutateAsync: startNextStepOrJobAsync,
    isPending: isNextStepOrJobPending
  } = useMutation({
    mutationKey: [MUTATION_KEYS.step.batchStart],
    mutationFn: async (compiledTour: CompiledTour) => {
      // Start the next step of the reloaded tour
      const stepToStart = nextStepToStart(compiledTour);
      const jobToStart = mainJobToStart(compiledTour);
      if (stepToStart) {
        return await startStepRequest(
          compiledTour.tour.missionId!,
          compiledTour.tour.tourId!,
          stepToStart.stepId!
        );
      } else if (jobToStart) {
        return await batchStartStepsInJob(
          compiledTour.tour.missionId!,
          compiledTour.tour.tourId!,
          jobToStart.jobId!
        );
      } else {
        return { success: true, updatedTour: compiledTour } as TourResponse;
      }
    },
    onSuccess: updateCurrentTourFromResponse,
    onError: (error, { tour }) => {
      reloadTour(tour.tourType);
      MutationErrorHandler(error);
    }
  });

  return {
    isNextStepOrJobPending,
    findMainServiceJob,
    findOpenDriveBackToHubStep,
    getOpenJobs,
    startNextStepOrJobAsync,
    startNextStepOrJob
  };
};
