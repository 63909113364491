import { DateTimeUtils } from '@badgermoleV2/DateTimeUtils';
import type { CompiledTour, Job, Mission, Step, Tour } from '@badgermoleV2/api';
import {
  AssignmentState,
  AssignmentType,
  MissionType,
  StepCategory,
  StepState,
  StepType
} from '@badgermoleV2/api';

// Drive Back Information states handled by frontend
// because there is currently no backend support
type DriveBackInformation =
  | 'returnVehicle'
  | 'connectBatteries'
  | 'labelBrokenBatteries'
  | 'reequipVehicles'
  | 'rechargeSmartphone';

export interface DriveBackInformationStatus {
  id: number;
  task: DriveBackInformation;
  checked: boolean;
  failed: boolean;
}

export type ActionState = 'done' | 'pending' | 'failed';
export enum AbortReason {
  OUTOFTIME = 'OutOfTime',
  TRUCKALREADYFULL = 'TruckAlreadyFull',
  OUTOFBATTERIES = 'OutOfBatteries',
  OTHER = 'Other'
}

export class TourUtils {
  static getMostCommonAbortReasons = (tourType: MissionType) => {
    return [
      AbortReason.OUTOFTIME, // default, keep as first element
      tourType === MissionType.Pickup ? AbortReason.TRUCKALREADYFULL : AbortReason.OUTOFBATTERIES,
      AbortReason.OTHER
    ];
  };

  static securityTasks = [
    'checkHelmets',
    'measureTirePressuer',
    'checkThrottle',
    'checkBrakes',
    'checkLights',
    'checkMirrors',
    'checkDamages'
  ];

  static standardTasks = ['refillHygienicCap', 'changeCloth', 'disinfectHelmets', 'Cleanup'];

  static driveBackInformation: DriveBackInformationStatus[] = [
    { id: 0, task: 'returnVehicle', checked: false, failed: false },
    { id: 1, task: 'connectBatteries', checked: false, failed: false },
    { id: 2, task: 'labelBrokenBatteries', checked: false, failed: false },
    { id: 3, task: 'reequipVehicles', checked: false, failed: false },
    { id: 4, task: 'rechargeSmartphone', checked: false, failed: false }
  ];

  static tourFeatureDisabled = (
    mission: Mission | undefined,
    openTours: CompiledTour[],
    tourType: MissionType
  ): boolean => {
    if (!mission) return true;
    if (mission.missionType === MissionType.Custom)
      return !openTours.some((tour) => tour.tour.tourType === tourType);
    return false;
  };

  static tourRunning = (openTours: CompiledTour[], tourType: MissionType): boolean => {
    return openTours.some((tour) => tour.tour.tourType === tourType);
  };

  static chooseTourTypeOutput<T>(
    type: MissionType,
    pickupOutput: T,
    serviceOutput: T,
    relocateOutput: T
  ): T {
    return type === MissionType.Pickup
      ? pickupOutput
      : type === MissionType.Custom || type === MissionType.Swap
        ? serviceOutput
        : relocateOutput;
  }

  static beforeYouGoList = [
    'noNewDamage',
    'toolsComplete',
    'CapacityTimes2Batteries',
    'labledScrewdriver',
    'someCloth',
    'hygieneCaps',
    'helmetsXLS',
    'desinfectionSpray',
    'mirros',
    'screwWrench',
    'airCompressor'
  ];

  static tourIsCancelable = (tour: CompiledTour): boolean => {
    if (tour.tour.tourType === MissionType.Pickup) {
      const openSteps = (tour.compiledSteps ?? []).filter((step) => !step.isDoneOrFailed);
      if (openSteps.length > 0) {
        const currentJob = TourUtils.getJobForStep(tour.tour, openSteps[0])!;
        if (currentJob.jobType === AssignmentType.DriveBack) {
          return false;
        } else {
          return openSteps[0].stepType !== StepType.PostStep;
        }
      } else {
        return false;
      }
    } else {
      // Always cancelable for swap tours
      // TODO: verify this is correct
      return true;
    }
  };
  static noOpenStepsLeft = (tour: CompiledTour): boolean => {
    const openSteps = (tour.compiledSteps ?? []).filter((step) => !step.isDoneOrFailed);
    return openSteps.length === 0;
  };
  static allAtStepsStarted = (job: Job): boolean => {
    const startedAtSteps = (job.atSteps ?? []).filter((s) => s.state !== StepState.Considered);
    return startedAtSteps.length === (job.atSteps ?? []).length;
  };
  static getJobForStep = (tour: Tour, step: Step): Job | undefined => {
    type JobWithPotentialStep = {
      job: Job;
      step: Step | undefined;
    };
    const potentialSteps = tour.jobs?.map((j) => {
      const allSteps = (j.preSteps ?? []).concat(j.atSteps ?? []).concat(j.postSteps ?? []);
      const potentialStep = allSteps.find((s) => s.stepId === step.stepId);
      return { job: j, step: potentialStep } as JobWithPotentialStep;
    });
    const foundJobWithStep = potentialSteps?.filter((s) => s.step !== undefined);
    return foundJobWithStep![0].job;
  };
  static atStatesOpen = (job: Job): boolean => {
    if (!job) {
      return false;
    }
    const atSteps = job.atSteps ?? [];
    return atSteps.every((s) => s.state === StepState.Considered);
  };

  static getBatteriesLeft = (compiledTour: CompiledTour): number => {
    return (
      compiledTour!.tour.tourParameters.capacity -
      (compiledTour!.tour.jobs ?? []).flatMap((job) =>
        (job.atSteps ?? []).filter(
          (step) =>
            step.stepCategory === StepCategory.BatterySwap && step.state === StepState.Completed
        )
      ).length
    );
  };

  static preStepsDone = (job: Job): boolean => {
    return (job.preSteps ?? []).every((step) => step.state === StepState.Completed);
  };

  static getFirstDriveToStep = (compiledTour: CompiledTour): Step => {
    const sortedByPosition = (compiledTour!.tour.jobs ?? []).sort((l, r) =>
      l.positionInCompiledList! < r.positionInCompiledList! ? -1 : 1
    );
    return sortedByPosition.filter(
      (j) => j.state === AssignmentState.InProgress || j.state === AssignmentState.Considered
    )[0].preSteps![0];
  };
  static getDriveBackStep = (compiledTour: CompiledTour): Step => {
    const driveBackStep = (compiledTour!.tour.jobs ?? []).filter(
      (j) => j.jobType === AssignmentType.DriveBack
    )[0].atSteps![0];
    return driveBackStep;
  };
  static getUncompletedSteps = (compiledTour: CompiledTour): Step[] => {
    return compiledTour!.compiledSteps!.filter((step) => !step.isDoneOrFailed);
  };
  static getLastUpdatedTime = (compiledTour: CompiledTour): Date | undefined => {
    const jobRawUpdateTimes: string[] = (compiledTour.tour.jobs ?? [])
      .filter((job) => job.lastUpdatedAt !== undefined && job.lastUpdatedAt !== null)
      .map((job) => job.lastUpdatedAt!);
    const jobUpdateTimes = jobRawUpdateTimes.map((rawTime) => DateTimeUtils.parseToDate(rawTime));
    const filteredJobUpdateTimes = jobUpdateTimes.sort((l, r) =>
      l.getTime() < r.getTime() ? -1 : 1
    );
    if (filteredJobUpdateTimes.length > 0) {
      return filteredJobUpdateTimes[filteredJobUpdateTimes.length - 1];
    } else {
      return undefined;
    }
  };

  static getTourFromOpenTours = (openTours: CompiledTour[], missionType: MissionType) =>
    openTours.find((tour) => tour.tour.tourType === missionType);
}
