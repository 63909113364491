import { useBusinessMapContext } from '@/context/useBusinessMapContext';
import { MapUtils } from '@/utils/MapUtils';
import type { LatLngExpression } from 'leaflet';
import { useEffect } from 'react';
import { Marker, Popup } from 'react-leaflet';

type VehicleMarkerProps = {
  position: LatLngExpression;
  description: string;
};

export const VehicleMarker = ({ position, description }: VehicleMarkerProps) => {
  const { map } = useBusinessMapContext();
  useEffect(() => {
    if (position) {
      map.current?.panTo(position);
      map.current?.setZoom(15);
    }
  }, [position, map]);
  return (
    <Marker
      icon={MapUtils.buildIcon('/marker-icons/marker-default.png')}
      position={position}
      opacity={1}
    >
      <Popup>{description}</Popup>
    </Marker>
  );
};
