import { Box, Button, Checkbox, Divider, FormControlLabel, Paper } from '@mui/material';
import type React from 'react';
import { useTranslation } from 'react-i18next';

type SwapTaskListProps = {
  readonly heading: string;
  readonly steps: string[];
  readonly selected: string[];
  readonly setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  readonly showSelectAllButton?: boolean;
};

export const SwapTaskList = ({
  heading,
  steps,
  selected,
  setSelected,
  showSelectAllButton
}: SwapTaskListProps) => {
  const [t] = useTranslation();

  const onChange = (step: string) => {
    setSelected((prev) =>
      prev.includes(step) ? prev.filter((s) => s !== step) : prev.concat([step])
    );
  };

  return (
    <Box>
      <Divider sx={{ fontSize: '1rem', fontWeight: 'bold' }}>{heading}</Divider>
      {showSelectAllButton && (
        <Button
          color="secondary"
          data-testid={`buttonSelectAll${heading.replaceAll(' ', '')}`}
          onClick={() => {
            steps.forEach((step) => {
              if (!selected.includes(step)) onChange(step);
            });
          }}
        >
          {t('service-tour:selectAll')}
        </Button>
      )}
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '.5rem 3rem .5rem',
          borderRadius: '1rem'
        }}
      >
        {steps.map((step) => (
          <FormControlLabel
            labelPlacement="end"
            key={`CheckboxFormControlStep${step}`}
            label={t(`service-tour:standardAndSecurityTasks.${step}`)}
            data-testid={`elementIn${heading.replaceAll(' ', '')}`}
            control={
              <Checkbox
                name={step}
                checked={selected.includes(step)}
                data-testid={`checkbox${step}`}
                onChange={() => {
                  onChange(step);
                }}
              />
            }
          />
        ))}
      </Paper>
    </Box>
  );
};
