import { Box, Button, Paper } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Job, CompiledTour } from '@badgermoleV2/api';
import { AssignmentType, AssignmentState, StepState, MissionType } from '@badgermoleV2/api';
import { TourUtils } from '@/utils/TourUtils';

type TourHeaderProps = {
  readonly title?: string;
  readonly isTourRunning: boolean;
  readonly cancelAction?: () => void;
  readonly tour?: CompiledTour;
};

export const TourHeader = ({ title, isTourRunning, cancelAction, tour }: TourHeaderProps) => {
  const { t } = useTranslation();
  const [, setOpenJobs] = useState<Job[]>([]);
  const [doneJobs, setDoneJobs] = useState<Job[]>([]);
  const batteriesLeft = useMemo(() => {
    return tour ? TourUtils.getBatteriesLeft(tour) : 0;
  }, [tour]);

  useEffect(() => {
    if (tour && tour.tour.tourType === MissionType.Pickup) {
      const pickupJobs = (tour.tour.jobs ?? [])
        .filter((job) => !(job.failReason || job.cancelReason))
        .filter((job) => job.jobType === AssignmentType.Pickup);

      setOpenJobs(
        pickupJobs.filter((job) => {
          const preAndAtSteps = job.preSteps!.concat(job.atSteps!); //TODO: handle!
          const openPreAndAtSteps = preAndAtSteps.filter((step) => !step.isDoneOrFailed);
          return openPreAndAtSteps.length > 0;
        })
      );

      setDoneJobs(
        pickupJobs.filter((job) => {
          const preAndAtSteps = job.preSteps!.concat(job.atSteps!); //TODO: handle!
          const donePreAndAtSteps = preAndAtSteps.filter(
            (step) => step.state === StepState.Completed
          );
          return donePreAndAtSteps.length === preAndAtSteps.length;
        })
      );
    } else if (
      tour &&
      (tour.tour.tourType === MissionType.Swap || tour.tour.tourType === MissionType.Custom)
    ) {
      const swapJobs = (tour.tour.jobs ?? []).filter(
        (job) => job.jobType === AssignmentType.Swap || job.jobType === AssignmentType.Custom
      );

      setOpenJobs(
        swapJobs.filter((job) => {
          return (
            job.state === AssignmentState.Unassigned ||
            job.state === AssignmentState.InProgress ||
            job.state === AssignmentState.Considered
          );
        })
      );

      setDoneJobs(
        swapJobs.filter((job) => {
          return (
            job.state === AssignmentState.PostStepsCompleted ||
            job.state === AssignmentState.Failed ||
            job.state === AssignmentState.Skipped
          );
        })
      );
    }
  }, [tour]);
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '2.5rem',
        paddingRight: '.5rem'
      }}
    >
      {title && (
        <Box component={'span'} sx={{ marginLeft: '1rem', fontSize: 'large', fontWeight: 'bold' }}>
          {title}
        </Box>
      )}
      {tour && isTourRunning && (
        <Box
          component="span"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            marginLeft: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start'
          }}
        >
          <Box component="span" data-testid={`serviced${doneJobs.length}`}>
            {doneJobs.length +
              ' ' +
              (tour!.tour.tourType === MissionType.Pickup ? 'picked up' : 'serviced')}
          </Box>
          {tour && tour.tour.tourType === MissionType.Swap && batteriesLeft && (
            <Box
              component="span"
              sx={{
                fontSize: '10px',
                color: 'black',
                fontWeight: 'normal',
                marginLeft: '0'
              }}
              data-testid={`batteriesLeft${batteriesLeft}`}
            >
              {`(${t('service-tour:batteriesLeft')} ${batteriesLeft})`}
            </Box>
          )}
        </Box>
      )}
      {isTourRunning && (
        <Button
          data-testid={'abortTour'}
          variant="contained"
          size="small"
          disabled={!cancelAction || !(tour && TourUtils.tourIsCancelable(tour!))}
          onClick={cancelAction}
          sx={{ fontSize: '12px' }}
          color={'error'}
        >
          {t('pickup-tour:completeActions.JiraTransitionCancelTour')}
        </Button>
      )}
    </Paper>
  );
};
