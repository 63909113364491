import type { Job, Step } from '@badgermoleV2/api';
import { AssignmentType, StepCategory } from '@badgermoleV2/api';
import { useCallback, useMemo } from 'react';

/**
 * Wrapper Hook around a job to get the main steps
 *
 * @param job
 * @returns A ordered representation of the job in
 */
export const useJob = (job: Job | undefined) => {
  const mainSteps = useMemo<Step[]>(() => {
    const unsortedSteps =
      job?.atSteps!.filter(
        (step) =>
          step.stepCategory === StepCategory.DoSomething ||
          step.stepCategory === StepCategory.BatterySwap
      ) ?? [];
    return unsortedSteps.sort(function (l, r) {
      return l.stepId! < r.stepId! ? -1 : 1;
    });
  }, [job]);

  const replaceHelmetStep = useMemo<Step | undefined>(
    () => job?.atSteps!.find((step) => step.stepCategory === StepCategory.ReplaceHelmets),
    [job]
  );

  const mainStepsDoneOrFailed = useMemo(() => {
    return mainSteps.filter((step) => step.isDoneOrFailed).length === mainSteps.length;
  }, [mainSteps]);

  const findOpenDriveToStep = useCallback((job: Job): Step | undefined => {
    return (job?.jobType === AssignmentType.Swap || job?.jobType === AssignmentType.Custom) &&
      !job?.preSteps![0].isDoneOrFailed
      ? job!.preSteps![0]
      : undefined;
  }, []);

  return {
    mainSteps,
    findOpenDriveToStep,
    mainStepsDoneOrFailed,
    replaceHelmetStep
  };
};
