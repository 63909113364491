import { CancelStepDialog } from '@/components/Dialogs/CancelStepDialog';
import type { ActionState } from '@/utils/TourUtils';
import type { Step, TransitionAction } from '@badgermoleV2/api';
import { StepState } from '@badgermoleV2/api';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const state = {
  [StepState.Failed]: 'failed',
  [StepState.Canceled]: 'failed',
  [StepState.Completed]: 'done',
  [StepState.InProgress]: 'pending',
  [StepState.Considered]: 'pending'
};

type ActionTaskItemProps = {
  readonly title: string;
  readonly step: Step;
  readonly onStepComplete: (_: Step) => void;
  readonly onStepFail: (_: Step, cancelAction: TransitionAction, reason: string) => void;
};

export const ActionTaskItem = ({
  title,
  step,
  onStepFail,
  onStepComplete
}: ActionTaskItemProps) => {
  const [t] = useTranslation();
  const executed: ActionState = useMemo(
    () => (state[step.state] as ActionState) ?? 'pending',
    [step]
  );
  const [showFailStepDialog, setShowFailStepDialog] = useState(false);

  return (
    <Paper sx={{ padding: '16px' }}>
      <Box>
        <CancelStepDialog
          open={showFailStepDialog}
          handleOk={(cancelAction, reason) => {
            setShowFailStepDialog(false);
            onStepFail(step, cancelAction, reason);
          }}
          handleCancel={() => {
            setShowFailStepDialog(false);
          }}
          cancelAction={step.cancelActions![0]}
        />
        {executed === 'pending' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography>{title}</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 1 }}>
              <Button
                disabled={false} // TODO
                size="small"
                variant="outlined"
                color="error"
                data-testid={`fail${title.replaceAll(' ', '')}`}
                onClick={() => {
                  setShowFailStepDialog(true);
                }}
                sx={{ marginRight: '8px' }}
              >
                {t(`pickup-tour:cancelActions:${step.cancelActions![0]}`)}
              </Button>
              <Button
                disabled={false} // TODO
                size="small"
                variant="outlined"
                color="success"
                data-testid={`done${title.replaceAll(' ', '')}`}
                onClick={() => {
                  onStepComplete(step);
                }}
              >
                {t(`pickup-tour:completeActions:${step.completeAction}`)}
              </Button>
            </Box>
          </Box>
        ) : executed === 'failed' ? (
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography>{title}</Typography>
            <Typography color={'red'}>{step.failReason}</Typography>
            <ErrorIcon sx={{ color: 'red' }} />
          </Stack>
        ) : (
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography>{title}</Typography>
            <CheckCircleIcon sx={{ color: 'green' }} />
          </Stack>
        )}
      </Box>
    </Paper>
  );
};
