import { CancelStepDialog } from '@/components/Dialogs/CancelStepDialog';
import type { Step, TransitionAction } from '@badgermoleV2/api';
import { Box, Button, Divider } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ProblemSolutionActionsProps = {
  readonly step: Step;
  readonly cancelActions: TransitionAction[] | undefined;
  readonly failedAction: (step: Step, cancelAction: TransitionAction, reason: string) => void;
  readonly skipDialog?: boolean;
  readonly disableAllButtons?: boolean;
};

export const ProblemSolutionActions = ({
  step,
  cancelActions,
  failedAction,
  skipDialog,
  disableAllButtons
}: ProblemSolutionActionsProps) => {
  const { t } = useTranslation();
  const [selectedCancelAction, setSelectedCancelAction] = useState<TransitionAction>();
  const [cancelTaskDialogOpen, setCancelTaskDialogOpen] = useState(false);

  if (!(cancelActions && cancelActions.length > 0)) return <></>;

  return (
    <Box>
      {selectedCancelAction && (
        <CancelStepDialog
          open={cancelTaskDialogOpen && !skipDialog}
          handleOk={(cancelAction, reason) => {
            setCancelTaskDialogOpen(false);
            failedAction(step, cancelAction, reason);
          }}
          handleCancel={() => {
            setSelectedCancelAction(undefined);
            setCancelTaskDialogOpen(false);
          }}
          cancelAction={selectedCancelAction}
        />
      )}

      <Divider
        sx={{
          fontWeight: 'bold',
          fontSize: '1em',
          marginBottom: 1
        }}
      >
        {t('pickup-tour:problemOptions')}
      </Divider>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {cancelActions?.map((action) => (
          <Button
            disabled={disableAllButtons}
            key={`CurrentStepButtonCancelAction${action}`}
            data-testid={`cancelWith${action}`}
            variant="outlined"
            color="error"
            onClick={() => {
              setSelectedCancelAction(action);
              setCancelTaskDialogOpen(true);
            }}
            size={'small'}
          >
            {t(`pickup-tour:cancelActions:${action}`)}
          </Button>
        ))}
      </Box>
    </Box>
  );
};
