import { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

type ConfirmDialogProps = {
  open: boolean;
  handleOk: (comment: string | undefined) => void;
  handleCancel: () => void;
  okTitle: string;
  okMessage?: string;
  cancelTitle: string;
  hint: string;
  placeholder: string | undefined;
  withComment: boolean;
  successAction: boolean;
  warning: string | undefined;
};

export const ConfirmDialog = ({
  handleOk,
  handleCancel,
  open,
  okTitle,
  okMessage,
  cancelTitle,
  hint,
  placeholder,
  withComment,
  successAction,
  warning
}: ConfirmDialogProps) => {
  const [comment, setComment] = useState('');
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      onBackdropClick={handleCancel}
      aria-labelledby="form-dialog-title"
      data-testid={'failActionDialog'}
    >
      <DialogTitle>{okTitle}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="h3">{hint}</Typography>
          <Typography variant="h2" fontWeight="bold" color="error">
            {warning}
          </Typography>
          {withComment && (
            <TextField
              sx={{ marginTop: '32px' }}
              onChange={(e) => {
                setComment(e.target.value as string);
              }}
              placeholder={placeholder}
              required={true}
              fullWidth
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          color="info"
          variant="outlined"
          data-testid="cancelFailActionDialog"
        >
          {cancelTitle}
        </Button>
        <Button
          disabled={withComment && comment.length === 0}
          variant="contained"
          color={successAction ? 'success' : 'error'}
          onClick={() => handleOk(comment)}
          data-testid="okActionDialog"
        >
          {okMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
