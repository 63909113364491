import { CustomDrawer } from '@/components/common/Header/Drawer';
import { QUERY_KEYS } from '@/config/QueryKeys';
import { theme } from '@/config';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Grid, IconButton, ThemeProvider, Toolbar, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

type HeaderProps = {
  readonly title: string;
  readonly hideMenu: boolean;
};

export const Header = ({ title, hideMenu }: HeaderProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const queryClient = useQueryClient();

  const toggleDrawer = () => {
    setDrawerOpen((prev) => {
      if (!prev) queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.tour.openTours] });
      return !prev;
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <AppBar position="sticky">
          <Toolbar data-testid="toolbar">
            <Grid
              container
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <Grid item xs="auto">
                {!hideMenu && (
                  <IconButton
                    sx={{ color: 'white' }}
                    data-testid="menu-icon"
                    onClick={toggleDrawer}
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs data-testid={'heading'}>
                <Typography fontWeight="bold">{title}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ThemeProvider>

      <React.Fragment key={'left'}>
        <CustomDrawer open={drawerOpen} toggle={toggleDrawer} />
      </React.Fragment>
    </>
  );
};
