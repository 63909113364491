import { AppBar, IconButton, Box } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import CachedIcon from '@mui/icons-material/Cached';

type TourFooterProps = {
  readonly reloadAction?: () => void | Promise<void>;
};

export const TourFooter = ({ reloadAction }: TourFooterProps) => {
  return (
    <AppBar sx={{ top: 'auto', bottom: 0 }}>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: 1 }}>
        {reloadAction && (
          <IconButton size="small" onClick={reloadAction} data-testid={'forceReloadTourButton'}>
            <CachedIcon sx={{ color: 'white' }} />
          </IconButton>
        )}
        <IconButton href="tel:+4930959993603" size="small" target="_self" rel="noopener noreferrer">
          <CallIcon sx={{ color: 'white' }} />
        </IconButton>
      </Box>
    </AppBar>
  );
};
