import { FormControlLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

type MapViewToggleProps = {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
};
export const MapViewToggle = ({ checked, onChange, disabled }: MapViewToggleProps) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      label={t('relocate:enableMapView')}
      sx={{ alignSelf: 'center' }}
      control={
        <FormControlLabel
          label={t('relocate:enableListView')}
          labelPlacement="start"
          control={
            <Switch
              sx={{ marginRight: '1em' }}
              checked={checked}
              disabled={disabled}
              onChange={onChange}
            />
          }
        />
      }
    />
  );
};
