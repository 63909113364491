// These are absolutely not necessary but it is nice to have named mutations in dev tool

export const MUTATION_KEYS = {
  tour: {
    request: 'request-tour',
    modify: 'adapt-tour',
    reload: 'reload-tour',
    extend: 'extend-tour',
    fail: 'fail-tour',
    complete: 'complete-tour',
    forceFail: 'force-fail-tour'
  },
  step: {
    batchStart: 'batch-start-steps',
    start: 'start-step',
    fail: 'fail-step',
    complete: 'complete-step',
    reload: 'reload-position-step',
    check: 'check-complete-step'
  },
  onstreet: {
    startTour: 'start-onstreet-tour',
    endTour: 'end-onstreet-tour',
    addTask: 'add-repair-task-to-onstreet-tour',
    removeTask: 'remove-repair-task-from-onstreet-tour',
    getOrCreate: 'get-or-create-tour'
  },
  alarm: {
    enable: 'enable-vehicle-alarm',
    disable: 'disable-vehicle-alarm'
  },
  repair: {
    updateState: 'update-repair-state',
    updateDescription: 'update-repair-description',
    removeProblem: 'remove-problem',
    addComment: 'add-repair-comment',
    updateProblemComment: 'update-problem-comment',
    updateProblemState: 'update-problem-state',
    addProblem: 'add-problem',
    updateReason: 'update-reason',
    updateAssignee: 'update-assignee'
  },
  tasks: {
    updateState: 'update-task-state',
    updatePriority: 'update-task-priority',
    reschedule: 'reschedule-task'
  },
  vehicle: {
    unlockHelmetBox: 'unlock-helmetbox',
    lockHelmetBox: 'lock-helmetbox',
    disableVehicleAlarm: 'disable-vehicle-alarm',
    enableVehicleAlarm: 'enable-vehicle-alarm',
    mobilizeVehicle: 'mobilize-vehicle',
    immobilizeVehicle: 'immobilize-vehicle',
    unlockSaddle: 'unlock-saddle',
    createAssetComment: 'create-asset-comment'
  }
};
