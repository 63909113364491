import { ROUTES } from '@/App/routes/routes';
import { DataLoadingDialog } from '@/components/Dialogs/DataLoadingDialog';
import { BusinessMap } from '@/components/Map/BusinessMap';
import { DisplayActiveVehicles } from '@/components/Map/DisplayActiveVehicles';
import { DisplayAvailableVehicles } from '@/components/Map/DisplayAvailableVehicles';
import { DisplayUserPosition } from '@/components/Map/DisplayUserPosition';
import { TourFooter } from '@/components/Tour';
import { useTourInteraction } from '@/hooks/Tour/General/useTourInteraction';
import { useRelocateTour } from '@/hooks/Tour/Relocate/useRelocateTour';
import { useTitle } from '@/hooks/Utility/useTitle';
import { AvailableRelocateVehicles } from '@/screens/TourScreens/Relocate/AvailableRelocateVehicles';
import { useStore } from '@/store';
import { MarkerState } from '@/utils/IconUtils';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { MapViewToggle } from '../MapViewToggle';
import { ActiveRelocateVehicles } from './ActiveRelocateVehicles';

export const RelocateScreen = () => {
  useTitle({ title: 'relocate:title' });
  const [t] = useTranslation('relocate');
  const navigate = useNavigate();
  const {
    relocateTour,
    activeVehicles,
    availableVehicles,
    failedVehicles,
    finishedVehicles,
    completedVehicles,
    relocateActionLoading,
    isLoadingMissions,
    adaptTour,
    isFetchingRelocateVehicles,
    disableAlarm,
    removeVehicleFromTour,
    enableAlarm
  } = useRelocateTour();
  const [openFinishedList, setOpenFinishedList] = useState(false);

  const [mapViewEnabled, setMapViewEnabled] = useStore((s) => [
    s.relocateMapViewEnabled,
    s.setRelocateMapViewEnabled
  ]);

  const { completeTourAsync, isCompleteTourPending } = useTourInteraction();

  const handleComplete = async () => {
    enableAlarm();
    await completeTourAsync({
      compiledTour: relocateTour!,
      vehicleMileage: relocateTour!.tour.tourParameters.serviceVehicle.mileage
    });
    navigate(ROUTES.OVERVIEW);
  };

  if (!relocateTour || isLoadingMissions || isCompleteTourPending)
    return <DataLoadingDialog open message={t('common:loading:tourGenerate')} />;

  if (mapViewEnabled)
    return (
      <Box sx={{ margin: '.25rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            sx={{ width: 'fit-content' }}
            onClick={handleComplete}
            data-testid={'endRelocateButton'}
          >
            {t('relocate:endRelocateMode')}
          </Button>
          <Button
            disabled={activeVehicles.length < 1}
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => disableAlarm()}
          >
            <NotificationsOffIcon fontSize="small" sx={{ marginRight: 1 }} />
            {t('alarmOffShort')}
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <MapViewToggle
            checked={mapViewEnabled}
            onChange={() => setMapViewEnabled(!mapViewEnabled)}
            disabled={isFetchingRelocateVehicles}
          />
        </Box>
        <BusinessMap style={{ width: '100%', height: '70vh' }}>
          <DisplayUserPosition />
          <DisplayAvailableVehicles
            interact={(vehicle) => adaptTour([vehicle])}
            vehicles={availableVehicles}
            relocateActionLoading={relocateActionLoading}
          />
          <DisplayActiveVehicles
            state={MarkerState.ACTIVE}
            compiledTour={relocateTour}
            jobs={activeVehicles}
            removeVehicleFromTour={removeVehicleFromTour}
          />
          <DisplayActiveVehicles
            state={MarkerState.FAILED}
            compiledTour={relocateTour}
            jobs={failedVehicles}
            removeVehicleFromTour={removeVehicleFromTour}
          />
          <DisplayActiveVehicles
            state={MarkerState.COMPLETED}
            compiledTour={relocateTour}
            jobs={completedVehicles}
            removeVehicleFromTour={removeVehicleFromTour}
          />
        </BusinessMap>
        <TourFooter
          reloadAction={() => {
            window.location.reload();
          }}
        />
      </Box>
    );

  return (
    <Stack sx={{ margin: '.25rem', paddingBottom: '3rem', gap: 1 }}>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          sx={{ alignSelf: 'center', width: 'fit-content' }}
          onClick={handleComplete}
          data-testid={'endRelocateButton'}
        >
          {t('relocate:endRelocateMode')}
        </Button>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          disabled={activeVehicles.length < 1}
          onClick={() => disableAlarm()}
        >
          <NotificationsOffIcon fontSize="small" sx={{ marginRight: 1 }} />
          {t('alarmOffShort')}
        </Button>
      </Stack>
      <MapViewToggle
        checked={mapViewEnabled}
        onChange={() => setMapViewEnabled(!mapViewEnabled)}
        disabled={isFetchingRelocateVehicles}
      />
      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: 'x-large',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: grey[200],
          marginBottom: 1,
          borderRadius: 1
        }}
      >
        {t('relocate:currentRelocateVehicle')}
        {activeVehicles.length !== 0 ? (
          <ActiveRelocateVehicles
            vehicles={activeVehicles}
            open
            compiledTour={relocateTour}
            removeVehicleFromTour={removeVehicleFromTour}
          />
        ) : (
          <Alert severity={'warning'} sx={{ margin: 1 }}>
            {t('relocate:noRelocateVehiclesSelected')}
          </Alert>
        )}{' '}
      </Box>
      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: 'x-large',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: grey[200],
          borderRadius: 1
        }}
      >
        <Typography
          onClick={() => setOpenFinishedList((prev) => !prev)}
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: 'x-large' }}
        >
          {t('relocate:relocatedVehicles')}
          {openFinishedList ? <ArrowDropDown /> : <ArrowRight />}
        </Typography>
        {finishedVehicles.length !== 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: grey[200] }}>
            <ActiveRelocateVehicles
              compiledTour={relocateTour}
              open={openFinishedList}
              vehicles={finishedVehicles}
              removeVehicleFromTour={removeVehicleFromTour}
            />
          </Box>
        ) : (
          <Alert severity={'warning'} sx={{ margin: 1 }}>
            {t('relocate:noRelocateVehiclesFinished')}
          </Alert>
        )}
      </Box>
      <Typography sx={{ fontWeight: 'bold', fontSize: 'x-large' }}>
        {t('relocate:availableRelocateVehicles')}
      </Typography>
      <AvailableRelocateVehicles
        vehicles={availableVehicles}
        adaptTour={(vehicles) => {
          adaptTour(vehicles);
        }}
        adaptTourLoading={relocateActionLoading}
        fetchRelocateVehiclesLoading={isFetchingRelocateVehicles}
      />
      <TourFooter
        reloadAction={() => {
          window.location.reload();
        }}
      />
    </Stack>
  );
};
