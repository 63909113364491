import { ROUTES } from '@/App/routes/routes';
import { CancelTourDialog } from '@/components/Dialogs/CancelTourDialog';
import { DataLoadingDialog } from '@/components/Dialogs/DataLoadingDialog';
import { TourFooter, TourHeader } from '@/components/Tour';
import { CurrentJob } from '@/components/Tour/Job/CurrentJob';
import { JobPreviewList } from '@/components/Tour/Job/JobPreviewList/JobPreviewList';
import { CurrentStep } from '@/components/Tour/Step';
import { QUERY_KEYS } from '@/config/QueryKeys';
import { useStepInteraction } from '@/hooks/Tour/General/useStepInteraction';
import { useTourInteraction } from '@/hooks/Tour/General/useTourInteraction';
import { useJob } from '@/hooks/Tour/Service/useJob';
import { useServiceTour } from '@/hooks/Tour/Service/useServiceTour';
import { useOpenTour } from '@/hooks/Tour/useOpenTour';
import { useTaskComments } from '@/hooks/Tour/useTaskComments';
import { useTitle } from '@/hooks/Utility/useTitle';
import { useStore } from '@/store';
import type { CompleteChecksResponse, Job, Step } from '@badgermoleV2/api';
import { MissionType, StepCategory, TourState } from '@badgermoleV2/api';
import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type ServiceTourScreenProps = {
  missionType: typeof MissionType.Swap | typeof MissionType.Custom;
};

export const ServiceTourScreen = ({ missionType }: ServiceTourScreenProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [mainServiceJob, setMainServiceJob] = useState<Job>();
  const [driveToStep, setDriveToStep] = useState<Step>();
  const [driveBackStep, setDriveBackStep] = useState<Step>();
  const tourExtensionDeclined = useStore((s) => s.tourExtensionDeclined);

  const {
    openTour: compiledTour,
    isFetching: isFetchingTour,
    isLoading: isLoadingOpenTour
  } = useOpenTour(missionType);

  const {
    failMainStep,
    failStepAsync,
    completeStep,
    reloadStepPosition,
    completeDriveToStep,
    completeMainStep,
    isCompleteStepPending,
    isFailStepPending,
    isFailMainStepPending,
    isCompleteMainStepPending,
    isCompleteDriveToPending,
    isReloadStepPositionPending,
    isStartStepPending
  } = useStepInteraction();
  const { cancelTour, reloadTour, isCancelTourPending } = useTourInteraction();

  const [showTelematicChecksDialog, setShowTelematicChecksDialog] = useState(false);
  const [telematicChecksResponse, setTelematicChecksResponse] = useState<CompleteChecksResponse>();

  const { mainSteps, findOpenDriveToStep, mainStepsDoneOrFailed, replaceHelmetStep } =
    useJob(mainServiceJob);

  const { findMainServiceJob, startNextStepOrJob, findOpenDriveBackToHubStep, getOpenJobs } =
    useServiceTour();

  const jobsPreview = !compiledTour ? [] : getOpenJobs(compiledTour);

  const { taskComments } = useTaskComments(mainServiceJob?.createdByServiceTask);

  useTitle({
    title: 'service-tour:title',
    suffix: `#${compiledTour?.tour?.tourId}`,
    separator: ' '
  });

  const handleLastStepComplete = async (step: Step) => {
    setShowTelematicChecksDialog(false);
    setTelematicChecksResponse(undefined);
    const completeChecks = await completeMainStep({
      tour: compiledTour!,
      step
    });
    if (!completeChecks.success) {
      setShowTelematicChecksDialog(true);
      setTelematicChecksResponse(completeChecks);
    }
  };

  useEffect(() => {
    setMainServiceJob(undefined);
    setDriveToStep(undefined);
    setDriveBackStep(undefined);
    if (!compiledTour || compiledTour.tour.state !== TourState.InProgress) {
      window.scrollTo(0, 0);
      navigate(ROUTES.OVERVIEW);
      return;
    }

    const mainServiceJob = findMainServiceJob(compiledTour);
    if (!mainServiceJob) {
      window.scrollTo(0, 0);
      const openDriveBackToHubStep = findOpenDriveBackToHubStep(compiledTour);
      const root =
        compiledTour.tour.tourType === MissionType.Swap ? ROUTES.SERVICE.ROOT : ROUTES.CUSTOM.ROOT;
      if (!tourExtensionDeclined) {
        window.scrollTo(0, 0);
        navigate(root + ROUTES.TOUR.EXTEND);
        return;
      } else if (openDriveBackToHubStep) {
        setDriveBackStep(openDriveBackToHubStep);
        return;
      } else {
        window.scroll(0, 0);
        navigate(root + ROUTES.TOUR.FEEDBACK);
      }
    }

    setMainServiceJob(mainServiceJob);
    const openDriveToStep = findOpenDriveToStep(mainServiceJob!);
    if (openDriveToStep) {
      setDriveToStep(openDriveToStep);
      return;
    }
  }, [
    compiledTour,
    findMainServiceJob,
    findOpenDriveBackToHubStep,
    findOpenDriveToStep,
    navigate,
    tourExtensionDeclined
  ]);

  if (!compiledTour || isLoadingOpenTour)
    return <DataLoadingDialog open={true} message={t('common:loading:general')} />;

  const isTourInteractionLoading =
    isCancelTourPending ||
    isCompleteMainStepPending ||
    isCompleteStepPending ||
    isFailStepPending ||
    isStartStepPending ||
    isReloadStepPositionPending ||
    isFetchingTour ||
    isFailMainStepPending ||
    isCompleteDriveToPending;

  return (
    <>
      <CancelTourDialog
        open={cancelDialogOpen}
        tour={compiledTour!.tour}
        handleApprove={(reason) => {
          setCancelDialogOpen(false);
          cancelTour({
            compiledTour: compiledTour!,
            reason
          });
        }}
        handleCancel={() => {
          setCancelDialogOpen(false);
        }}
      />
      <TourHeader
        tour={compiledTour}
        isTourRunning={true}
        cancelAction={driveBackStep ? undefined : () => setCancelDialogOpen(true)}
      />
      <DataLoadingDialog open={isTourInteractionLoading} message={t('common:loading:general')} />
      <>
        {driveToStep && (
          <CurrentStep
            key={`DriveToVehicle${driveToStep.stepId}`}
            step={driveToStep}
            tour={compiledTour!.tour}
            specialTasksInJob={mainServiceJob?.atSteps
              ?.filter((step) => step.stepCategory === StepCategory.DoSomething)
              .map((step) => step.stepDescription)
              .filter((task) => !task['en'].toLowerCase().includes('battery swap'))}
            doneAction={(step) => {
              completeDriveToStep({ tour: compiledTour, step });
              queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.tour.estimatedDrivingTime] });
            }}
            failedAction={(step, cancelAction, reason) => {
              failStepAsync({ tour: compiledTour, step, cancelAction, failReason: reason }).then(
                (response) => {
                  if (response.success) {
                    startNextStepOrJob(response.updatedTour);
                    queryClient.invalidateQueries({
                      queryKey: [QUERY_KEYS.tour.estimatedDrivingTime]
                    });
                  }
                }
              );
            }}
            refreshAction={(step) => reloadStepPosition({ tour: compiledTour, step })}
            comments={taskComments}
          />
        )}

        {!driveToStep && mainServiceJob && (
          <CurrentJob
            missionType={missionType}
            job={mainServiceJob}
            mainSteps={mainSteps}
            replaceHelmetStep={replaceHelmetStep}
            loading={isTourInteractionLoading}
            completeActionDisabled={!mainStepsDoneOrFailed}
            onStepComplete={(step) => completeStep({ tour: compiledTour, step })}
            onStepCompleteWithResolution={(step, resolution) =>
              completeStep({ tour: compiledTour, step, resolution })
            }
            onStepFail={(step, cancelAction, reason) =>
              failStepAsync({ tour: compiledTour, step, cancelAction, failReason: reason })
            }
            onLastStepComplete={handleLastStepComplete}
            onLastStepFail={(step, cancelAction, reason) =>
              failMainStep({ tour: compiledTour, step, cancelAction, failReason: reason })
            }
            showTelematicCheckDialog={showTelematicChecksDialog}
            telematicCheckResponse={telematicChecksResponse}
            onCloseTelematicDialog={() => {
              setShowTelematicChecksDialog(false);
              setTelematicChecksResponse(undefined);
            }}
            handleStepRefresh={(step) => reloadStepPosition({ tour: compiledTour, step })}
          />
        )}

        {driveBackStep && (
          <CurrentStep
            key={`PostStep${driveBackStep.stepId}`}
            step={driveBackStep}
            tour={compiledTour!.tour}
            doneAction={(step) => completeStep({ tour: compiledTour, step })}
            failedAction={() => {}}
          />
        )}

        <Box sx={{ marginBottom: 10 }}>
          <JobPreviewList jobs={jobsPreview} />
        </Box>
      </>
      <TourFooter reloadAction={async () => await reloadTour(missionType)} />
    </>
  );
};
