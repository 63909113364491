import { ROUTES } from '@/App/routes/routes';
import { MUTATION_KEYS } from '@/config/MutationKeys';
import { QUERY_KEYS } from '@/config/QueryKeys';
import type {
  CompiledTour,
  MissionType,
  TourParametersBody,
  TourResponse
} from '@badgermoleV2/api';
import { requestTour } from '@badgermoleV2/missions';
import { completeTour as sendCompleteTour, failTour, extendTourByOne } from '@badgermoleV2/tours';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type CompleteTourBody = { compiledTour: CompiledTour; vehicleMileage: number };

type CancelTourBody = { compiledTour: CompiledTour; reason: string };

type ExtendTourBody = {
  compiledTour: CompiledTour;
  location: GeolocationPosition;
};

export const useTourInteraction = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateCurrentTour = (data: CompiledTour | undefined) =>
    queryClient.setQueryData([QUERY_KEYS.tour.openTour, data?.tour.tourType], data);

  const updateCurrentTourFromResponse = (data: TourResponse) => {
    if (!data.success) {
      window.scrollTo(0, 0);
      toast.error(t(data.error!));
    }
    updateCurrentTour(data.updatedTour);
  };

  const {
    mutate: startTour,
    mutateAsync: startTourAsync,
    isPending: isStartTourPending,
    isError: isStartTourError
  } = useMutation({
    mutationKey: [MUTATION_KEYS.tour.request],
    mutationFn: async (body: TourParametersBody) => await requestTour(body),
    onSuccess: updateCurrentTour
  });

  const { mutate: cancelTour, isPending: isCancelTourPending } = useMutation({
    mutationKey: [MUTATION_KEYS.tour.fail],
    mutationFn: async ({ compiledTour, reason }: CancelTourBody) =>
      await failTour(compiledTour.tour.missionId!, compiledTour.tour.tourId!, reason),
    onSuccess: updateCurrentTourFromResponse
  });

  const {
    mutate: completeTour,
    mutateAsync: completeTourAsync,
    isPending: isCompleteTourPending
  } = useMutation({
    mutationKey: [MUTATION_KEYS.tour.complete],
    mutationFn: async ({ compiledTour, vehicleMileage }: CompleteTourBody) =>
      await sendCompleteTour(
        compiledTour.tour.missionId!,
        compiledTour.tour.tourId!,
        vehicleMileage
      ),
    onSuccess: (data) => {
      updateCurrentTourFromResponse(data);
      navigate(ROUTES.OVERVIEW);
    }
  });

  const { mutateAsync: extendTour, isPending: isExtendTourPending } = useMutation({
    mutationKey: [MUTATION_KEYS.tour.extend],
    mutationFn: async ({ compiledTour, location }: ExtendTourBody) =>
      await extendTourByOne(
        compiledTour!.tour.missionId!,
        compiledTour.tour.tourId!,
        location.coords.latitude,
        location.coords.longitude
      ),
    onSuccess: updateCurrentTour
  });

  const { mutateAsync: reloadTour, isPending: isReloadTourPending } = useMutation({
    mutationKey: [MUTATION_KEYS.tour.reload],
    mutationFn: async (missionType: MissionType) =>
      await queryClient.refetchQueries({ queryKey: [QUERY_KEYS.tour.openTour, missionType] })
  });
  return {
    startTour,
    startTourAsync,
    extendTour,
    isExtendTourPending,
    isStartTourPending,
    isStartTourError,
    isReloadTourPending,
    cancelTour,
    isCancelTourPending,
    completeTour,
    completeTourAsync,
    isCompleteTourPending,
    updateCurrentTour,
    updateCurrentTourFromResponse,
    reloadTour
  };
};
