import type { Coordinate, Vehicle } from '@badgermoleV2/api';
import type { LatLngExpression } from 'leaflet';
import L from 'leaflet';

export type VehilceWithDistance = {
  vehicle: Vehicle;
  distanceToUser: number;
};

const deg2rad = (deg: number) => deg * (Math.PI / 180); // internal helper function
const R = 6371; // Radius of Earth

export class MapUtils {
  static mapBoxStyle = new Map<string, string>([
    ['de', 'cldim8qo5001701nohq8unyg9'],
    ['en', 'cks7kbxz3cvo918nx52fpglwy']
  ]);
  static getMapBoxTileLayerUrl = (lang: string) =>
    `https://api.mapbox.com/styles/v1/jonas-jaszkowic/${this.mapBoxStyle.get(
      lang
    )}/tiles/256/{z}/{x}/{y}@2x?access_token=${import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}`;

  static buildIcon = (iconUrl: string): L.Icon => {
    return L.icon({
      iconUrl: iconUrl,
      iconSize: [26, 38], // size of the icon
      iconAnchor: [13, 38], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -38] // point from which the popup should open relative to the iconAnchor
    });
  };

  static generatePolylinePositions(coordinates: Coordinate[]): LatLngExpression[] {
    const positions: LatLngExpression[] = [];
    coordinates.forEach((coordinate) => {
      positions.push([coordinate.lat, coordinate.lon]);
    });
    return positions;
  }

  static getCenter(geofencePolygon: number[]): number[] {
    if (geofencePolygon.length === 0) return [0, 0];

    const lats = geofencePolygon.filter((_, index) => {
      return index % 2 === 0;
    });
    if (lats.length === 0) return [0, 0];
    const latsSum = lats.reduce((accumulator, currentValue) => accumulator + currentValue);
    const latsAvg = latsSum / lats.length;

    const lons = geofencePolygon.filter((element, index) => {
      return index % 2 === 1;
    });
    if (lons.length === 0) return [0, 0];
    const lonsSum = lons.reduce((accumulator, currentValue) => accumulator + currentValue);
    const lonsAvg = lonsSum / lons.length;

    return [latsAvg, lonsAvg];
  }

  /**
   * calculate distance between two coordinates/points in km
   */
  static distanceBetweenTwoPoints(lat1: number, lon1: number, lat2: number, lon2: number) {
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.pow(Math.sin(dLon / 2), 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance;
  }

  static sortByDistance(
    vehicles: Vehicle[],
    userPosition: GeolocationPosition
  ): VehilceWithDistance[] {
    const vehiclesWithDistance = vehicles.map((vehicle) => {
      const distance = this.distanceBetweenTwoPoints(
        userPosition.coords.latitude,
        userPosition.coords.longitude,
        vehicle.lat,
        vehicle.lon
      );

      return { vehicle, distanceToUser: distance };
    });

    vehiclesWithDistance.sort((a, b) => a.distanceToUser - b.distanceToUser);

    return vehiclesWithDistance;
  }
}
