import { QUERY_KEYS } from '@/config/QueryKeys';
import { useOpenTour } from '@/hooks/Tour/useOpenTour';
import {
  StepState,
  StepType,
  type MissionType,
  type TransitionActionField
} from '@badgermoleV2/api';
import { getCompleteActionOptions, startStepRequest } from '@badgermoleV2/steps';
import {
  useMutation,
  useQueries,
  useQueryClient,
  type UseQueryOptions
} from '@tanstack/react-query';
import { useMemo } from 'react';

type StepWithCompleteActions = {
  stepId: number;
  availableCompleteActionOptions: TransitionActionField[];
};

const createStepWithCompleteAction = (
  stepId: number,
  transitionActionFields: TransitionActionField[]
) => {
  return {
    stepId,
    availableCompleteActionOptions: transitionActionFields
  } satisfies StepWithCompleteActions;
};

export const usePostSteps = (missionType: MissionType) => {
  const { openTour } = useOpenTour(missionType);
  const postSteps =
    openTour?.compiledSteps
      ?.filter((s) => s.stepType === StepType.PostStep)
      .filter((step) => step.state !== StepState.Failed && step.state !== StepState.Canceled) ?? [];
  const queryClient = useQueryClient();
  const missionId = openTour?.tour.missionId;
  const tourId = openTour?.tour.tourId;

  const stepCompleteActionsQueries = useQueries({
    queries: postSteps!.map((step) => {
      return {
        queryKey: [QUERY_KEYS.step.completeActions, step.stepId, missionId, tourId],
        queryFn: async ({ signal }) =>
          getCompleteActionOptions(missionId!, tourId!, step.stepId!, { signal }),
        enabled: missionId !== undefined && tourId !== undefined,
        select: (data) => createStepWithCompleteAction(step.stepId!, data)
      } satisfies UseQueryOptions<TransitionActionField[], Error, StepWithCompleteActions>;
    })
  });

  const {
    mutate: startSteps,
    isSuccess: isStartStepsSuccess,
    isPending: isStartStepsPending
  } = useMutation({
    mutationFn: async () =>
      await Promise.all(
        postSteps
          .filter((step) => step.state === StepState.Considered)
          .map(
            async (step) =>
              await startStepRequest(
                openTour!.tour.missionId!,
                openTour!.tour.tourId!,
                step.stepId!
              )
          )
      ),
    onSuccess: () =>
      queryClient.refetchQueries({
        queryKey: [QUERY_KEYS.tour.openTour, missionType]
      })
  });

  const isCompleteActionsLoading = stepCompleteActionsQueries.some((result) => result.isFetching);

  const stepsWithCompleteActions = useMemo(
    () => stepCompleteActionsQueries.filter((s) => s.isSuccess).map((step) => step.data!),
    [stepCompleteActionsQueries]
  );

  return {
    postSteps,
    stepsWithCompleteActions,
    startSteps,
    isCompleteActionsLoading,
    isStartStepsSuccess,
    isStartStepsPending
  };
};
