import { BusinessMapContext } from '@/components/Map/BusinessMap';
import { useContext } from 'react';

export const useBusinessMapContext = () => {
  const { map } = useContext(BusinessMapContext);

  if (!map) {
    throw new Error('useBusinessMapContext can only be used in a child component of BusinessMap');
  }

  return { map };
};
